import { ModeDeviceParametersView } from 'constants/Constants';
import { IGaugeParameter, DeviceParameterActions } from 'constants/DeviceConstants';

export interface IDeviceParameters {
    parameters: IGaugeParameter[];
    deviceId: number;
    message: string;
    mode: ModeDeviceParametersView;
}

const initialState: IDeviceParameters = { parameters: [], message: null, deviceId: null, mode: "Expand" };

export const reducer = (state: IDeviceParameters, action: any): IDeviceParameters => {
    state = state || initialState;
    switch (action.type) {
        case DeviceParameterActions.FETCH_DATA:
            return { ...state, deviceId: action.payload.deviceId, message: "loading", mode: action.payload.mode };
        case DeviceParameterActions.FETCH_DATA_SUCCESS:
            return { ...state, parameters: action.payload, message: null };
        case DeviceParameterActions.FETCH_DATA_FAILED:
            return { ...state, parameters: [], message: action.payload};
        case DeviceParameterActions.NO_DATA:
            return { ...state, parameters: [], message: null };
        default:
            return state;
    }
}