import { DeviceDataActions, IDeviceDataRecordKey, IDeviceDataInfo } from 'constants/DeviceConstants';

const initialState: IDeviceData = {
    data: null,
    message: null,
    dateStart: null,
    dateEnd: null
}

export interface IDeviceData {
    data: IDeviceDataInfo;
    message: string;
    dateStart: Date;
    dateEnd: Date;
}

export const reducer = (state: IDeviceData, action: any): IDeviceData => {
    state = state || initialState;
    switch (action.type) {
        case "@@router/LOCATION_CHANGE":
            return { ...state, dateStart: null, dateEnd: null };

        case DeviceDataActions.FETCH_NO_DATA:
            return { ...state, data: null, message: null };

        case DeviceDataActions.UPDATE_DEVICE_DATA:
            return { ...state, message: null };

        case DeviceDataActions.ENTRY_REMOVING:
            return { ...state, message: "Loading" };

        case DeviceDataActions.ENTRY_REMOVE_FAILED:
            return { ...state, message: action.payload as string };

        case DeviceDataActions.ENTRY_REMOVED:
            if (action.payload && action.payload as IDeviceDataRecordKey) {
                const deleteRecord = action.payload as IDeviceDataRecordKey;
                const records = state.data.records.filter(r => !(r.date === deleteRecord.date));
                const data = { ...state.data, records: [...records], totalRecordCount: state.data.totalRecordCount - 1 };
                return { ...state, data, message: null };
            } else {
                return { ...state, message: action.payload as string };
            }
        case DeviceDataActions.CLEANING_DATA:
            return { ...initialState, message: "Loading" };

        case DeviceDataActions.CLEANING_DATA_FAILED:
            return { ...state, message: action.payload as string };

        case DeviceDataActions.FETCH_DATA:
            return { ...state, message: "Loading" };

        case DeviceDataActions.FETCH_DATA_SUCCESS:
            const payloadData = action.payload as IDeviceDataInfo;
            let dateStart: Date = state.dateStart;
            let dateEnd: Date = state.dateEnd;

            if (payloadData && payloadData.records && payloadData.records.length > 0) {
                dateStart = state.dateStart ? state.dateStart : roundDownDate(payloadData.records[payloadData.records.length - 1].date);
                dateEnd = state.dateEnd ? state.dateEnd : roundUpDate(payloadData.records[0].date);
            }
            return {
                ...state,
                data: payloadData,
                message: null,
                dateStart,
                dateEnd
            };

        case DeviceDataActions.FETCH_DATA_FAILED:
            return { ...state, data: null, message: action.payload as string };

        case DeviceDataActions.DATE_CHANGE:
            return { ...state, message: null, dateStart: action.payload.dateStart, dateEnd: action.payload.dateEnd };

        case DeviceDataActions.CLEAN:
            return { ...initialState }
        default:
            return state;
    }
}

function roundUpDate(sdate: any) {
    const date = roundDownDate(sdate);
    date.setDate(date.getDate());
    return date;
}

function roundDownDate(sdate: any) {
    const date = new Date(sdate);
    if (typeof date !== "object" || !date.getUTCMilliseconds) {
        throw Error("Arg must be a Date object.");
    }
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    const oneDayMs = 24 * 60 * 60 * 1000;
    return new Date(Math.floor((date.getTime() - offsetMs) / oneDayMs) * oneDayMs + offsetMs);
}
