import "c3/c3.min.css";
import * as moment from 'moment';
import * as React from "react";
import C3Chart from "react-c3js";
import { useIntl } from "react-intl";
import { DateTimeSecFormat } from "constants/Constants";
import { IDataRecord } from "constants/DeviceConstants";
import "../../less/DeviceData.css";
import "../../less/Chart.css";
import DeviceNoData from "./DeviceNoData";

interface IChart {
    dates: string[];
    values: number[];
    parameterName: string;
}

interface IDeviceChartProps {
    records: IDataRecord[];
    thresholdMax?: number;
    thresholdMin?: number;
    parameterId: number;
    parameterMaxValue: number;
    parameterMinValue: number;
    customName: string;
    unit: string;
}

const DeviceC3Chart = (props: IDeviceChartProps) => {
    const intl = useIntl();
    const axis: any = {
        x: {
            type: 'timeseries',
            tick: {
                format: '%Y-%m-%d %H:%M',
                count: 4,
                rotate: 0
            },
            height: 60
        },
        y: {
            tick: {
                format: (d: number) => d.toFixed(1)
            }
        }
    };
    const zoom: any = {
        enabled: true,
        extent: [1, 2000]
    };
    const size: any = { height: "480" };
    const legend: any = { show: false };

    const getParameterName = (): string => {
        return (props.customName
                ? props.customName
                : intl.formatMessage({ id: `device.chart.parameter_${props.parameterId}` }))
             + ` [${
                    props.unit 
                        ? props.unit
                        : intl.formatMessage({ id: `device.chart.parameter.unit_${props.parameterId}` })
                }]`
    }

    const getChartSettings = (): IChart => {
        const dates = props.records.map(r => moment.utc(r.date).local().format(DateTimeSecFormat));
        const values = props.records.map(r => r.value);
        return {
            dates,
            values,
            parameterName: getParameterName()
        }
    }

    const settings = getChartSettings();

    const data = {
        x: 'x',
        xFormat: "%Y-%m-%d %H:%M:%S",
        columns: [
            (['x'] as any).concat(settings.dates),
            ([settings.parameterName] as any).concat(settings.values)
        ],
        types: {
            [settings.parameterName]: 'line'
        }
    };

    const point = { show: true };
    const grid = {
        y: {
            show: true,
            lines: ([] as any[])
        },
        x: {
            show: true
        }
    };

    const regions = [] as any[];

    if (props.thresholdMax !== null ) {
        regions.push({ axis: 'y', start: props.thresholdMax, class: 'data-range-max' });
        grid.y.lines.push({ value: props.thresholdMax, text: intl.formatMessage({ id: "device.edit.thresholdMax" }), class: 'threshold-max' });
    }
    
    if (props.thresholdMin !== null ) {
        regions.push({ axis: 'y', end: props.thresholdMin, class: 'data-range-min' });
        grid.y.lines.push({ value: props.thresholdMin, text: intl.formatMessage({ id: "device.edit.thresholdMin" }), class: 'threshold-min' });
    }

    return (
        <div className="chart">
            <div className="title">{settings.parameterName}</div>
            {settings.values && settings.values.length > 0 ?
                <div className="chart-content">
                    <C3Chart
                        data={data}
                        axis={axis}
                        zoom={zoom}
                        grid={grid}
                        legend={legend}
                        point={point}
                        size={size}
                        regions={regions}
                    />
                </div> : <DeviceNoData />}
        </div>
    );
}

export default DeviceC3Chart;