import * as React from "react";
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const Threats = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">
                    <h3>INFORMACJA O SZCZEGÓLNYCH ZAGROŻENIACH ZWIĄZANYCH Z KORZYSTANIEM PRZEZ UŻYTKOWNIKÓW Z USŁUG ŚWIADCZONYCH DROGĄ ELEKTRONICZNĄ  W ZWIĄZKU Z OBSŁUGĄ SERWISU IPOMIAR</h3>
                    Niniejszym wykonując obowiązek nałożony przepisami  ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. Nr 144, poz. 1204 z późn. zm.) -  art. 6 pkt 1, informuje o szczególnych zagrożeniach związanych z korzystaniem przez użytkowników z usług świadczonych drogą elektroniczną.
                    Informacja dotyczy zagrożeń, które potencjalnie mogą wystąpić ze względu na specyfikę sieci Internet i usług świadczonych drogą elektroniczną, ale które powinny być brane pod uwagę, mimo podejmowania przez nas działań mających na celu zabezpieczenie infrastruktury  elektronicznej i technicznej przed nieuprawnionym działaniem osób trzecich:
        <ol>
                        <li>możliwość otrzymania spamu, czyli niezamówionej i niechcianej informacji reklamowej (handlowej) przekazywanej drogą elektroniczną;</li>
                        <li>możliwość działania szkodliwego oprogramowania (oprogramowanie malware, robaki internetowe);</li>
                        <li>możliwość wyłudzenia poufnych informacji osobistych  i haseł (phishing) przez podszywanie się pod godną zaufania osobę lub instytucję np. bank i w konsekwencji pozyskanie osobistych i poufnych informacji;</li>
                        <li>możliwość łamania zabezpieczeń w celu pozyskania osobistych i poufnych informacji w celu kradzieży tożsamości, poprzez wysyłanie fałszywych wiadomości elektronicznych przypominających wiadomości autentyczne;</li>
                        <li>możliwość włamania do systemu teleinformatycznego użytkownika z użyciem m.in. takich narzędzi hackerskich jak exploit i rootkit;</li>
                        <li>możliwość niedozwolonego podsłuchu polegającego na wykorzystaniu programu komputerowego, który będzie przechwytywał i analizował dane  przepływające w sieci (spyware);</li>
                        <li>naruszanie autorskich praw majątkowych poprzez ich nieuprawnione kopiowanie i wykorzystanie bez zgody i wiedzy osoby uprawnionej.</li>
                    </ol>
                    Użytkownik, aby uniknąć powyższych zagrożeń, powinien:
        <ol>
                        <li>zaopatrzyć  komputer w program antywirusowy;</li>
                        <li>na bieżąco aktualizować program antywirusowy i oprogramowanie;</li>
                        <li>używać oryginalnego systemu i aplikacji;</li>
                        <li>posiadać stale włączoną zaporę sieciową (firewall);</li>
                        <li>nie otwierać  załączników poczty elektronicznej niewiadomego pochodzenia;</li>
                        <li>zawsze czytać okna instalacyjne aplikacji i ich licencje;</li>
                        <li>w plikach MS Office nieznanego pochodzenia wyłączać makra;</li>
                        <li>przeprowadzać regularny całościowy skan systemu programem antywirusowym i antymalware,</li>
                        <li>szyfrować transmisje danych;</li>
                        <li>posiadać zainstalowane programy prewencyjne (do wykrywania i zapobiegania włamaniom).</li>
                    </ol>
                </div>
            </ScrollArea>
        </div>
    );
export default Threats;