import * as React from "react";
import "../../less/Contact.css";
import UserContext, {IUserContext} from "../UserContext";

const Contact = () => {
    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        <div className="dashboard-contact">
            <div className="modal-content">
                     <address>
                        <i className="material-icons">phone</i>
                        <span title="Phone"> +48 34 39 30 015 </span>
                        <br />
                        <i className="material-icons">smartphone</i>
                        <span title="Phone"> +48 601 913 288  </span>
                        <br />
                        <i className="material-icons">work</i>
                        <span title="NIP">  NIP: 5751801022 </span>
                        <br />
                        <i className="material-icons">work</i>
                        <span title="REGON"> REGON: 362191270 </span>
                        <br />
                        <i className="material-icons">email</i>
                        <span title="Email"> <a href={`mailto:${userContext.serviceEmail}`}>{userContext.serviceEmail}</a> </span>
                        <br />
                        <i className="material-icons">shopping_cart</i>
                        <span title="Shop"> <a href="https://termoregulatory.pl/">Sklep</a></span>
                </address>
            </div>
        </div>
    );
}

export default Contact;