import { IDeviceSmogData, SmogActions } from '../constants/SmogConstants';
const initialState: IDeviceSmog = { data: null, message: null };

export interface IDeviceSmog {
    data: IDeviceSmogData;
    message: string;
}

export const reducer = (state: IDeviceSmog, action: any): IDeviceSmog => {
    state = state || initialState;
    switch (action.type) {
        case SmogActions.FETCH_DATA:
            return { ...state, data: null, message: "Loading" };
        case SmogActions.FETCH_DATA_SUCCESS:
            return { ...state, data: action.payload, message: null };
        case SmogActions.FETCH_DATA_FAILED:
            return { ...state, data: null, message: action.payload };
        default:
            return state;
    }
};