import { fetch } from 'whatwg-fetch';
import { IRegisterDeviceForm, jsonHeader, MeasurableParameter } from "constants/Constants";
import { IDeviceDataRecordKey, IEditedDevice } from 'constants/DeviceConstants';

export class DeviceDataAPI {

    public static getDeviceDataByDateLink(deviceId: number, parameter: MeasurableParameter, from: Date, to: Date) {
        let f = from;
        let t = to;
        
        if(f != null) {
            f = new Date(from);
            f.setMinutes(f.getMinutes() - f.getTimezoneOffset());
        }
        
        if(t != null) {
            t = new Date(to);
            t.setMinutes(t.getMinutes() - t.getTimezoneOffset());
        }
        return `/api/Device/${deviceId}/Parameter/${parameter}/DataByDate?from=${f ? f.toISOString() : ""}&to=${t ? t.toISOString() : ""}`;
    }

    public fetchDashboard(): Promise<Response> {
        return fetch(`/api/Dashboard/Tiles`);
    }

    public storeDashboardMode(mode: string) {
        return fetch(`/api/Dashboard/Mode`,
            {
                headers: jsonHeader,
                method: "PUT",
                body: JSON.stringify(mode)
            });
    }

    public storeDashboardDevicesOrder(deviceIds: number[]) {
        return fetch(`/api/Dashboard/DevicesOrder`,
            {
                headers: jsonHeader,
                method: "PUT",
                body: JSON.stringify(deviceIds)
            });
    }

    public fetchMapMarkers(): Promise<Response> {
        return fetch(`/api/Map`);
    }

    public fetchMapMarkerData(source: string, deviceId: number, parameter: MeasurableParameter): Promise<Response> {
        return fetch(`/api/map/source/${encodeURIComponent(source)}/device/${deviceId}/parameters/${parameter}/data`);
    }

    public fetchData(deviceId: number, parameter: MeasurableParameter, take: number, skip: number): Promise<Response> {
        return fetch(`/api/Device/${deviceId}/Parameter/${parameter}/Data?take=${take}&skip=${skip}`);
    }

    public fetchDataByDate(deviceId: number, parameter: MeasurableParameter, from: Date, to: Date): Promise<Response> {
        return fetch(DeviceDataAPI.getDeviceDataByDateLink(deviceId,parameter,from,to));
    }

    public fetchDevices(): Promise<Response> {
        return fetch(`/api/Devices`);
    }

    public fetchSingleDevice(deviceId: number): Promise<Response> {
        return fetch(`/api/Device/${deviceId}/Details`);
    }

    public fetchDeviceParameters(deviceId: number): Promise<Response> {
        return fetch(`/api/Device/${deviceId}/Parameters`);
    }

    public editDevice(model: IEditedDevice): Promise<Response> {
        return fetch(`/api/Device`,
            {
                headers: jsonHeader,
                method: "POST",
                body: JSON.stringify(model)
        });
    }

    public deleteDeviceData(deviceId: number): Promise<Response> {
        return fetch(`/api/Device/${deviceId}/Data`,
            {
                method: "DELETE"
            });
    }

    public deleteDeviceDataEntry(model: IDeviceDataRecordKey): Promise<Response> {
        return fetch(`/api/Device/Data`,{
            headers: jsonHeader,
            method: "DELETE",
            body: JSON.stringify(model)
        });
    }

    public deleteDevice(deviceId: number): Promise<Response> {
        return fetch(`/api/Device/${deviceId}`, {
            method: "DELETE",
            headers: jsonHeader,
        });
    }

    public saveRegisterDevice(model: IRegisterDeviceForm): Promise<Response> {
        return fetch(`/api/Devices/Register`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }
}
export default new DeviceDataAPI;