import React from "react";
import "../../less/AccessControl/DeviceUsersList.css"
import {DeviceUsersModel} from "../../constants/DeviceAccessControl/DeviceUsersModel";
import {Spinner} from "react-bootstrap";
import {FormattedMessage} from "react-intl";


type UserDeviceListProps = {
    deviceUsers: DeviceUsersModel[],
    isLoaded: boolean,
    removeUser?: (deviceId: number, email: string) => void;
}

export const DeviceUsersList = (props: UserDeviceListProps) => {
    return (
        <div className="device-user-list">
            {
                props.isLoaded
                    ?
                    props.deviceUsers.length == 0
                        ? <div style={{width: "100%", margin: "5px", textAlign: "center", fontSize:"20px"}}>
                            <FormattedMessage id="page.access-control.role.list.empty"/>
                        </div>
                        :
                        props.deviceUsers.map(du => (
                            <div className="device-panel" key={du.deviceId}>
                                <div className={"device-name"}><h6>{du.deviceName}</h6></div>
                                {du.emails.map(email => (
                                    <span className="item"
                                          key={`${du.deviceId}-${email}`}>
                                <span>{email}</span>
                                <i className="material-icons"
                                   style={{cursor: "pointer"}}
                                   onClick={() => props.removeUser(du.deviceId, email)}> delete_forever </i>
                            </span>))
                                }
                            </div>
                        ))
                    : (<div style={{width: "100%", textAlign: "center"}}><Spinner animation="grow"/></div>)
            }
        </div>);
}