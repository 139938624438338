import "c3/c3.min.css";
import * as React from "react";
import { useIntl } from "react-intl";
import CSS from 'csstype';
import {DeviceTankCommon} from "../../constants/DeviceTankCommon";

interface IDeviceTankGaugeProps {
    value: number;
    max: number;
    min: number;
    thresholdMax?: number;
    thresholdMin? : number;
    parameterId: number;
    parameterName?: string;
    unit: string;
    color: string;
    size: "Expand" | "Collapse";
    onClick?: (e: any) => void;
    style?: any;
}

const DeviceTankGauge = (props: IDeviceTankGaugeProps): JSX.Element => {
    const onClickEvent = (e: any) => props.onClick ? props.onClick(e) : null;
    const intl = useIntl();
    const name = props.parameterName != null ? props.parameterName : intl.formatMessage({ id: `device.chart.parameter_${props.parameterId}` });
    const data = {
        columns: [
            [name, props.value]
        ],
        type: 'gauge'
    };

    return (
        <div
            className="gaugeChart"
            onClick={onClickEvent}
            style={props.style}>
            
            <div className="title">{name}</div> 
            <Tank 
                level={props.value}
                tankSize={Math.abs(props.max-props.min)}
                thresholdMin={props.thresholdMin}
                thresholdMax={props.thresholdMax}
                unit={props.unit}/>
        </div>
    );
}

interface ITankProps {
    level: number,
    tankSize: number;
    thresholdMax?: number,
    thresholdMin?: number,
    unit?: string
}

const Tank = (props: ITankProps) => {
    function color() : string {
        if(props.thresholdMax !== null && props.level > props.thresholdMax)
            return "red";
        else if(props.thresholdMin !== null && props.level < props.thresholdMin)
            return "blue";
        return "black";
    }

    DeviceTankCommon.TankHeight = 160;
    const m = DeviceTankCommon.TankHeight / props.tankSize;
    DeviceTankCommon.Level = m * (props.level > props.tankSize ? props.tankSize : props.level);
    DeviceTankCommon.LabelColor = color();

    const maxThreshold = m * props.thresholdMax;
    const minThreshold = m * props.thresholdMin;

    const thresholdMaxStyle : CSS.Properties = { 
        ... DeviceTankCommon.ThresholdStyle,
        top: `${DeviceTankCommon.TankHeight - maxThreshold}px`,
        color: "red",
    };

    const thresholdMinStyle : CSS.Properties = {
        ... DeviceTankCommon.ThresholdStyle,
        top: `${DeviceTankCommon.TankHeight - minThreshold}px`,
        color: "blue",
    };
    
    return (
        <div style={DeviceTankCommon.TankStyle}> 
            <div style={DeviceTankCommon.FluidStyle()}></div>
            <div style={DeviceTankCommon.TankSizeStyle}> {props.tankSize}{props.unit} </div> 
            {maxThreshold ? <div style={thresholdMaxStyle}></div> : null }
            {minThreshold ? <div style={thresholdMinStyle}></div> : null }
            <div style={DeviceTankCommon.LevelLabel()}>{props.level.toFixed()}{props.unit}</div>
        </div>);
}


export default DeviceTankGauge;