import * as React from "react";

export interface IOnlineOfflineProps {
    isOnline?: boolean;
    onlineText?: string;
    offlineText?: string
}

const OnlineOffline = (props: IOnlineOfflineProps) => {
    return (
        <div className="online" title={props.isOnline 
            ? (props.onlineText ? props.onlineText : "Online") 
            : props.isOnline === false 
                ? (props.offlineText ? props.offlineText : "Offline") 
                : null}>
        {
            props.isOnline
                    ? <span><i className="material-icons">cloud_done</i></span>
                    : (props.isOnline === false
                        ? <span><i className="material-icons">cloud_off</i></span>
                        : null)
        }
        </div>
    );
}
export default OnlineOffline;