import {IDeviceTile} from "../../constants/Constants";
import {DragDropContext, Draggable, DraggableProvidedDragHandleProps, Droppable} from "react-beautiful-dnd";
import DraggableListItem, {DashboardListHeader} from "./DashboardListItem";
import NotificationToasts from "../NotificationToasts";
import * as React from "react";
import {useEffect} from "react";


const reorder = (list : IDeviceTile[], startIndex : number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

interface IDashboardListProps {
    tiles: IDeviceTile[];
    storeDashboardDeviceOrder: (deviceIds: number[], successFunction?: () => void) => void;
    fetchDashboard?: () => void;
}

const Item = ({index, draggableId, item, dragItemStyle = {}, children}: any) => (
    <Draggable index={index} draggableId={draggableId}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{
                    ...provided.draggableProps.style,
                    ...(snapshot.isDragging ? dragItemStyle : {}),
                }}
            >
                {children(item, provided.dragHandleProps)}
            </div>
        )}
    </Draggable>
);

const List = ({tiles, onDragEnd, dragListStyle = {}, ...props}: any) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                               overflow: "scroll",
                               height: "calc(100vh - 100px)",
                            ...(snapshot.isDraggingOver ? dragListStyle : {}),
                        }}
                    >
                        {(tiles ?? []).map((item: IDeviceTile, index: number) => (
                            <Item
                                key={item.deviceId}
                                draggableId={item.deviceId.toString()}
                                index={index}
                                item={item}
                                {...props}/>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>);
};

const DashboardList = (props: IDashboardListProps) => {
    const [tiles, setTiles] = React.useState([]);

    useEffect(()=> {
        setTiles(props.tiles);
    }, [props.tiles]);

    const handleDragEnd = ({destination, source}: any) => {
        if (!destination) return;
        const orderedTiles = reorder(tiles, source.index, destination.index);
        setTiles(orderedTiles);
        props.storeDashboardDeviceOrder(
            orderedTiles.map(t=> t.deviceId),
            () => props.fetchDashboard())
    };

    return (
        <>
            <DashboardListHeader/>
            <List tiles={tiles} onDragEnd={handleDragEnd}>
                {(item: IDeviceTile, dragHandleProps: DraggableProvidedDragHandleProps) => (
                    <DraggableListItem data={item} dragHandleProps={dragHandleProps}/>
                )}
            </List>
            <NotificationToasts/>
        </>
    );
}

export default DashboardList;