import * as React from "react";
import '../less/Spinner.css';
import UserContext, { IUserContext } from "./UserContext";

const rects = [1, 2, 3, 4, 5];
const Spinner = () => {
    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        <div className={"spinner " + (userContext === null ? "standard" : userContext.style)}>
            {rects.map(r => (
                <div className={"rect" + r} key={r} />
            ))}
        </div>
    );
}

export default Spinner;