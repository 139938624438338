import * as React from "react";
import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserActionCreators } from "../../actions/UserActionCreators";
import { IDeleteAccountViewModel } from "../../constants/UserManage";
import "../../less/Manage.css";
import { IAppStore } from "../../store/configureStore";
import { IUserContextStore } from "../../store/UserInfoStore";
import UserAccountAPI from "../../api/UserAccountAPI";
import { UserContextConsumer } from "../UserContext";
import SubmitButton from "../SubmitButton";
import SubmitForm from "../SubmitForm";


interface IUserContextSiteProps extends IUserContextStore {
    fetchUserInfo: () => void;
}

const DeleteAccountForm = (props: IUserContextSiteProps) => {
    const intl = useIntl();
    const [model, setModel] = useState({} as IDeleteAccountViewModel);
    const [errorMessage, setErrorMessage] = useState("");

    const submitForm = () => {
        UserAccountAPI.deleteAccount(model)
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.json())
            .then((changePasswordResult: boolean) => {
                if (changePasswordResult) {
                    props.fetchUserInfo();
                } else {
                    const message = intl.formatMessage({
                        id: "page.manage.deleteAccount.error"
                    });
                    setErrorMessage(message);
                }
            })
            .catch((r: Response) => r.json())
            .then((r: string) => { if (r) { setErrorMessage(r); } });
    };

    return (
        <UserContextConsumer>
            {userContext =>
                userContext && (
                    <>
                        <h5><FormattedMessage id="page.manage.deleteAccount" /></h5>
                        <SubmitForm>
                            {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
                            <Form.Group controlId="password">

                                {userContext.isExternalAccount
                                    ? (<>
                                        <Form.Label><FormattedMessage id="page.manage.confrim.login" /></Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(event: React.FormEvent<any>) => setModel({ ...model, login: (event.target as any).value })} />
                                    </>)
                                    : (<>
                                        <Form.Label><FormattedMessage id="page.manage.password" /></Form.Label>
                                        <Form.Control
                                            type="password"
                                            onChange={(event: React.FormEvent<any>) => setModel({ ...model, password: (event.target as any).value })} />
                                    </>)
                                }

                                <Form.Text>
                                    <FormattedMessage id="page.manage.deleteAccount.description" />
                                </Form.Text>
                            </Form.Group>
                            <SubmitButton disabled={!(model.password || model.login)} onClick={submitForm}>
                                <FormattedMessage id="page.manage.deleteAccount" />
                            </SubmitButton>
                        </SubmitForm>
                    </>)}
        </UserContextConsumer>
    );
}

export default connect(
    (state: IAppStore) => state.userInfo,
    (dispatch: any) => bindActionCreators(UserActionCreators, dispatch)
)(DeleteAccountForm);