import CSS from "csstype";

export class DeviceTankCommon{

    public static TankHeight : number = 160;
    public static Level : number = 0;
    public static LabelColor: string = "";
    
    public static TankStyle: CSS.Properties = {
        display: "block",
        position: "relative",
        border: "solid #ddd",
        borderWidth: "0 1px 1px 1px",
        backgroundColor: "#f7f8fa",
        margin: "auto",
        height: `${DeviceTankCommon.TankHeight}px`,
        width: "160px",
        padding: "0",
    };

    public static FluidStyle = () : CSS.Properties => {
        return {
            display: "block",
            background: "linear-gradient(lightblue, skyblue)",
            top: `${DeviceTankCommon.TankHeight - DeviceTankCommon.Level}px`,
            height: `${DeviceTankCommon.Level}px`,
            width: "159px",
            left: "0px",
            position: "absolute",
            border: "solid #ddd",
            borderWidth: "0 0 1px 0"
        };
    }

    public static ThresholdStyle : CSS.Properties = {
        display: "block",
        zIndex: 1,
        position: "absolute",
        width: "159px",
        left: "0",
        borderBottom: "dashed 1px",
        height: "0px",
        borderWidth: "2px",
        fontSize: "12px"
    }

    public static LevelLabel = () : CSS.Properties => {
        return {
            position: "absolute",
            top: "60px",
            width: "80px",
            left: "calc(50% - 40px)",
            margin: "auto",
            textAlign: "center",
            fontSize: "20px",
            border: "1px solid white",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "4px",
            color: DeviceTankCommon.LabelColor
        }
    };

    public static TankSizeStyle: CSS.Properties = {
        position: "absolute",
        top: "60px",
        left: "-48px",
        fontSize: "16px",
        rotate: "-90deg"
    };
}