import { RegisterDeviceAction } from "../constants/Constants";

let initialState: IRegisterDevice;
initialState = {
    deviceKey: "",
    deviceKeyValidationError: null,
    deviceName: "",
    deviceNameValidationError: null,
    isSubmitted: false
};

export interface IRegisterDevice {
    deviceKey: string,
    deviceKeyValidationError: string,
    deviceName: string,
    deviceNameValidationError: string,
    isSubmitted: boolean,
}

export const reducer = (state: IRegisterDevice, action: any): IRegisterDevice => {
    state = state || initialState;
    switch (action.type) {
        case RegisterDeviceAction.SUBMIT_SUCCESS:
            return {
                ...state,
                isSubmitted: true,
                deviceKeyValidationError: action.payload.keyError,
                deviceNameValidationError: action.payload.nameError
            };
        case RegisterDeviceAction.INVALID_KEY:
            return { ...state, deviceKeyValidationError: action.payload, isSubmitted: false };
        case RegisterDeviceAction.INVALID_NAME:
            return { ...state, deviceNameValidationError: action.payload, isSubmitted: false  };
        case RegisterDeviceAction.EDIT_KEY:
            return { ...state, deviceKey: action.payload, isSubmitted: false };
        case RegisterDeviceAction.EDIT_NAME:
            return { ...state, deviceName: action.payload, isSubmitted: false };
        case RegisterDeviceAction.CLEAR:
            return { ...initialState };
        default:
            return state;
    }
}