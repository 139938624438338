import * as moment from "moment";
import * as React from "react";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeviceGridRow from "./DeviceGridRow";
import { connect } from "react-redux";
import ScrollArea from "react-scrollbar";
import { bindActionCreators } from "redux";
import { DeviceActionCreators } from "actions/DeviceActionCreators";
import { DateTimeSecFormat } from "constants/Constants";

import "../../../less/DeviceData.css";
import { IDataRecord, IDeviceDataRecordKey } from "constants/DeviceConstants";
import SubmitButton from "../../SubmitButton";
import UserContext, {IUserContext} from "../../UserContext";

interface IDeviceGridProps {
    records: IDataRecord[];
    thresholdMin?: number;
    parameterMaxValue?: number;
    parameterMinValue?: number;
    thresholdMax?: number;
    parameterId: number;
    parameterUnit: string;
    deviceId: number;
    dateStart?: Date;
    dateEnd?: Date;
    removable: boolean;
    deleteDeviceDataEntry: (model: IDeviceDataRecordKey) => void;
}

const DeviceGrid = (props: IDeviceGridProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const style = userContext && userContext.style ? userContext.style : "standard";

    const [selectedRecord, selectRecordEvent] = useState(null as IDataRecord);

    const deleteRecordEvent = () => {
        props.deleteDeviceDataEntry({
            date: selectedRecord.date,
            deviceId: props.deviceId,
            parameterId: props.parameterId
        });
        selectRecordEvent(null);
    };

    return (
        <div className="device-grid">
            <Table responsive className="table-header">
                <thead className="thead-inverse">
                    <tr>
                        <th />
                        <th className="grid-row-date"> <FormattedMessage id="device.excel.date" /></th>
                        <th> <FormattedMessage id="device.grid.value" />
                            &nbsp;
                            [{props.parameterUnit
                                ? <span>{props.parameterUnit}</span>
                                : <FormattedMessage id={`device.chart.parameter.unit_${props.parameterId}`}/>
                            }]
                        </th>
                        <th />
                    </tr>
                </thead>
            </Table>
            <ScrollArea className="scrollarea">
                <Table responsive >
                    <tbody>
                        {
                            [].concat(props.records)
                                .map((r, index) =>
                                    <DeviceGridRow
                                        key={`key_${r.date}`}
                                        thresholdMax={props.thresholdMax}
                                        thresholdMin={props.thresholdMin}
                                        parameterMaxValue={props.parameterMaxValue}
                                        parameterMinValue={props.parameterMinValue}
                                        dataRecord={r}
                                        deleteButtonEnabled={props.removable && index !== 0}
                                        onDeleteClick={(sr: IDataRecord) => selectRecordEvent(sr)} />)
                        }
                    </tbody>
                </Table>
            </ScrollArea>
            {selectedRecord ?
                <Modal show={true} onHide={() => selectRecordEvent(null)} className={style}>
                    <Modal.Header>
                        <Modal.Title><FormattedMessage id="common.box.confirm" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="device.edit.remove.entry.question" values={{
                            date: moment.utc(selectedRecord.date).local().format(DateTimeSecFormat),
                            value: selectedRecord.value.toFixed(1)
                        }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => selectRecordEvent(null)}>
                            <FormattedMessage id="device.edit.cancel" />
                        </Button>
                        <SubmitButton onClick={deleteRecordEvent}>
                            <FormattedMessage id="device.edit.remove.btn" />
                        </SubmitButton>
                    </Modal.Footer>
                </Modal>
                : null}
        </div>
    );
}


export default connect(
    null,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(DeviceGrid);
