import "c3/c3.min.css";
import * as React from "react";
import C3Chart from "react-c3js";
import { useIntl } from "react-intl";

interface IDeviceGaugeChartProps {
    value: number;
    max: number;
    min: number;
    parameterId: number;
    parameterName?: string;
    unit: string;
    color: string;
    size: "Expand" | "Collapse";
    onClick?: (e: any) => void;
    style?: any;
}

const DeviceGaugeChart = (props: IDeviceGaugeChartProps): JSX.Element => {
    const onClickEvent = (e: any) => props.onClick ? props.onClick(e) : null;
    const intl = useIntl();
    const name = props.parameterName != null ? props.parameterName : intl.formatMessage({ id: `device.chart.parameter_${props.parameterId}` });
    const data = {
        columns: [
            [name, props.value]
        ],
        type: 'gauge'
    };

    const gauge = {
        label: {
            format: (value: number, ratio: number) => `${value.toFixed(1)}${props.unit}`,
            show: props.size === "Expand"
        },
        min: props.min,
        max: props.max
    };

    const legend = {
        show: false
    };

    const color = {
        pattern: [props.color]
    };

    const transition = {
        duration: 0
    };
    return (
        <div
            className="gaugeChart"
            onClick={onClickEvent}
            style={props.style}
        >
            <div className="title">{name}</div>
            <C3Chart
                data={data}
                gauge={gauge}
                legend={legend}
                color={color}
                transition={transition}
            />
        </div>
    );
}

export default DeviceGaugeChart;