import { EditDeviceStatus, ModeDeviceDetailsView, OperatingMode, Sampling } from 'constants/Constants';
import { DeviceActions, IDeviceDetails, IDeviceParameter, IEditedDevice } from 'constants/DeviceConstants';

export interface IDeviceDetailsStore {
    device: IDeviceDetails;
    message: string;
    editDeviceStatus: EditDeviceStatus;
    editedDevice: IEditedDevice;
    mode: ModeDeviceDetailsView;
    showClearDataFormDialog: boolean;
    showUnregisterDeviceFormDialog: boolean;
}

const initialState: IDeviceDetailsStore = {
    device: null,
    message: null,
    editDeviceStatus: null,
    editedDevice: null,
    mode: "View",
    showClearDataFormDialog: false,
    showUnregisterDeviceFormDialog: false
}

export const reducer = (state: IDeviceDetailsStore, action: any): IDeviceDetailsStore => {
    state = state || initialState;
    switch (action.type) {
        case DeviceActions.FETCH_DEVICE_DETAILS:
            return { ...state, device: null, message: "loading", editDeviceStatus: null };

        case DeviceActions.FETCH_DEVICE_DETAILS_SUCCESS:
            return { ...state, device: action.payload, mode: initialState.mode, message: null, editDeviceStatus: null };

        case DeviceActions.FETCH_DEVICE_DETAILS_FAILED:
            return { ...state, device: null, message: action.payload, editDeviceStatus: null };

        case DeviceActions.EDITED_FORM:
            return { ...state, editedDevice: action.payload, mode: "Edit" };

        case DeviceActions.EDIT_DEVICE:
            return { ...state, editDeviceStatus: "saving", editedDevice: action.payload as IEditedDevice };

        case DeviceActions.MODE_VIEW:
            return { ...state, mode: action.payload };

        case DeviceActions.EDIT_DEVICE_SUCCESS:
            const status: "saved" | "failed" = action.payload ? "saved" : "failed";
            const device: IDeviceDetails = { ...state.device };
            if (status === "saved") {
                device.name = state.editedDevice.name;
                device.disconnectedAlert = state.editedDevice.disconnectedAlert;
                device.publicData = state.editedDevice.publicData;
                device.latitude = Number(state.editedDevice.latitude);
                device.longitude = Number(state.editedDevice.longitude);
                updateSampling(device, state.editedDevice.sampling);
                updateOperatingMode(device, state.editedDevice.operatingMode);
                const parameters: IDeviceParameter[] = [];
                device.parameters.forEach(p => {
                    const settings = state.editedDevice.parameterSettings.filter(t => t.parameterId === p.parameterId);
                    if (settings) {
                        const s = settings[0];
                        parameters.push(
                            {
                                ...p,
                                thresholdMax: s.thresholdMax,
                                thresholdMin: s.thresholdMin,
                                minValue: s.minValue,
                                maxValue: s.maxValue,
                                customName: s.customName,
                                offset: s.offset != null ? s.offset : p.offset,
                                converter: s.converter != null ? s.converter : p.converter,
                                shifter: s.shifter != null ? s.shifter : p.shifter,
                                customUnit: s.customUnit,
                                customMaxValue: s.customMaxValue,
                                customMinValue: s.customMinValue,
                                showInDashboardTile: s.showInDashboardTile
                            } as IDeviceParameter);
                    }
                });
                device.parameters = parameters;
            }
            return { ...state, device, mode: (status === "saved" ? initialState.mode : state.mode), message: state.message, editDeviceStatus: status, editedDevice: null };

        case DeviceActions.EDIT_DEVICE_FAILED:
            return { ...state, device: state.device, message: state.message, editDeviceStatus: "failed" };
        case DeviceActions.CANCEL_EDIT_MODE:
            return { ...state, editedDevice: null, mode: initialState.mode };
        case DeviceActions.SHOW_HIDE_DELETE_DATA_DIALOG:
            return { ...state, showClearDataFormDialog: action.payload };
        case DeviceActions.SHOW_HIDE_DELETE_DEVICE_DIALOG:
            return { ...state, showUnregisterDeviceFormDialog: action.payload };
        default:
            return state;
    }
}

function updateOperatingMode(device: IDeviceDetails, newOperatingMode: OperatingMode) {
    if (device.operatingMode !== Number(newOperatingMode) && device.previousOperatingMode == null) {
        device.previousOperatingMode = Number(device.operatingMode);
    } else if (device.previousOperatingMode === Number(newOperatingMode)) {
        device.previousOperatingMode = null;
    }
    device.operatingMode = Number(newOperatingMode);
}

function updateSampling(device: IDeviceDetails, newSampling: Sampling) {
    if (device.sampling !== Number(newSampling) && device.previousSampling == null) {
        device.previousSampling = Number(device.sampling);
    } else if (device.previousSampling === Number(newSampling)) {
        device.previousSampling = null;
    }
    device.sampling = Number(newSampling);
}