import { MeasurableParameter } from "./Constants";

export interface IDeviceMarker {
    deviceId: number;
    publicToken: string;
    name: string;
    values: IMapParameterValue[];
    latitude: number;
    longitude: number;
    offNormal?: boolean;
    markerType: MarkerType;
    accessType: AccessType;
    readingDate: Date;
    qualityIndex?: number;
    source: string;
    isMine: boolean;
}

export interface IDeviceMarkerData {
    date: Date;
    value: number;
}

export enum MarkerType {
    Standard = "Standard",
    Smog = "Smog",
}

export enum AccessType {
    Private = "Private",
    Public = "Public",
}

export interface IMapParameterValue {
    parameterId: MeasurableParameter;
    value?: number;
}

export class MapActions {
    public static readonly FETCH_MARKERS_REQUEST = "FETCH_MARKERS_REQUEST";
    public static readonly FETCH_MARKERS_SUCCESS = "FETCH_MARKERS_SUCCESS";
    public static readonly FETCH_MARKERS_FAILED = "FETCH_MARKERS_FAILED";
}