import * as React from "react";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import ScrollArea from "react-scrollbar";
import { bindActionCreators } from "redux";
import { DashboardActionCreators } from "actions/DashboardActionCreators";
import "../../less/Dashboard.css";
import { IAppStore } from "store/configureStore";
import { IDashboard } from 'store/DashboardStore';
import Spinner from "../Spinner";
import UserContext, { IUserContext } from "../UserContext";
import DashboardTileWrapper from "./DashboardTileWrapper";
import Welcome from "./Welcome";
import { DashboardMode, IDeviceTile } from "constants/Constants";
import DashboardGridItem from "./DashboardGridItem";
import NotificationToasts from "../NotificationToasts";
import DashboardList from "./DashboardList";

interface IDashboardProps extends IDashboard {
    fetchDashboard?: () => void;
    storeDashboardMode?: (mode: string) => void;
    storeDashboardDeviceOrder?: (deviceIds: number[], successFunction?: () => void) => Promise<void>;
    linkTo?: (location: string) => void;
}

const Dashboard = (props: IDashboardProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    if (!userContext.isAuthenticated)
        return (<Navigate to="/portal" />);

    useEffect(() => {
        props.fetchDashboard();
        const intervalId = setInterval(props.fetchDashboard, 120000);
        return () => clearInterval(intervalId);
    }, []);

    const changeMode = (mode: DashboardMode) => {
        props.storeDashboardMode(mode);
    };

    const dashboard = () => {
        const tiles = props.tiles;
        if ((tiles || []).length === 0) { return (<Welcome />); }

        return (
            <div className="main-dashboard-container">
                <DashboardMenu mode={props.mode} onChangeMode={changeMode} />
                {
                    props.mode === "tiles"
                        ? <TilesDashboard tiles={props.tiles} />
                        : props.mode === "list"
                            ? <DashboardList tiles={props.tiles}
                                             storeDashboardDeviceOrder={props.storeDashboardDeviceOrder}
                                             fetchDashboard={props.fetchDashboard}/>
                            : props.mode === "grid"
                                ? <GridDashboard tiles={props.tiles} />
                                : <Spinner />
                }
            </div>
        );
    }

    return props.message === "Loading" && (!props.tiles || props.tiles.length === 0)
        ? <Spinner />
        : dashboard();
}

function mapStateToProps(state: IAppStore): IDashboardProps {
    return {
        tiles: state.dashboard.tiles,
        message: state.dashboard.message,
        mode: state.dashboard.mode
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DashboardActionCreators, dispatch)
)(Dashboard);

const TilesDashboard = (props: { tiles: IDeviceTile[] }) => (
    <>
        <ScrollArea className="scrollarea-right container-fluid">
            <div className="row no-margin">
                {props.tiles.map(tile => {
                    return (
                        <LinkContainer to={`/device/${tile.deviceId}`} key={tile.deviceId}>
                            <div> <DashboardTileWrapper data={tile} /></div>
                        </LinkContainer>
                    )
                })}
            </div>
        </ScrollArea>
        <NotificationToasts />
    </>
)

const GridDashboard = (props: { tiles: IDeviceTile[] }) => {
    useEffect(() => {
        window.scrollTo(0, 1);
    }, []);
    return (
        <ScrollArea className="scrollarea-right container-fluid">
            <div className="row no-margin">
                {props.tiles.map(tile => {
                    return (
                        <LinkContainer to={`/device/${tile.deviceId}`} key={tile.deviceId}>
                            <div> <DashboardGridItem data={tile} /> </div>
                        </LinkContainer>
                    )
                })}
            </div>
        </ScrollArea>
    );
}

const DashboardMenu = (props: { mode: DashboardMode, onChangeMode: (mode: DashboardMode) => void }) => (
    <div className="view-switch">
        <span className={props.mode === "tiles" ? "active" : null}>
            <i className="material-icons" onClick={() => props.onChangeMode("tiles")}>view_module</i>
        </span>
        <span className={props.mode === "grid" ? "active" : null}>
            <i className="material-icons" onClick={() => props.onChangeMode("grid")}>grid_on</i>
        </span>
        <span className={props.mode === "list" ? "active" : null}>
            <i className="material-icons" onClick={() => props.onChangeMode("list")}>view_headline</i>
        </span>
    </div>
);