import * as React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../less/QualityIndex.css";

const LegendRow = (props: { index: number, pm10: string, pm25: string, selected?: boolean }) => (
    <React.Fragment>
        <div className="grid-item">
            <div className={`qindex index-${props.index}`} >
                <div>{props.index}</div>
            </div>
        </div>
        <div className={"grid-item" + (props.selected ? " selected" : "")}><FormattedMessage id={`smog.index_${props.index}`} /></div>
        <div className="grid-item">{props.pm10}</div>
        <div className="grid-item">{props.pm25}</div>
    </React.Fragment>
);

const LegendHeaderRow = () => (
    <React.Fragment>
        <div className="grid-item-header"><FormattedMessage id="smog.quality-index" /></div>
        <div className="grid-item"><FormattedMessage id="device.chart.parameter_6" /></div>
        <div className="grid-item"><FormattedMessage id="device.chart.parameter_5" /></div>
    </React.Fragment>
);

const renderTooltip = (props: any, index: number) => {
    return (
        <div
            {...props}
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                padding: '2px 10px',
                color: 'white',
                width: '500px',
                borderRadius: 3,
                ...props.style,
            }}
        >
            <div className="quality-index-legend">
                <LegendHeaderRow />
                <LegendRow selected={index === 1} index={1} pm10="0 - 20" pm25="0 - 12" />
                <LegendRow selected={index === 2} index={2} pm10="21 - 60" pm25="13 - 36" />
                <LegendRow selected={index === 3} index={3} pm10="61 - 100" pm25="37 - 60" />
                <LegendRow selected={index === 4} index={4} pm10="101 - 140" pm25="61 - 84" />
                <LegendRow selected={index === 5} index={5} pm10="141 - 200" pm25="85 - 120" />
                <LegendRow selected={index === 6} index={6} pm10="> 200" pm25="> 120" />
            </div>
        </div>
    );
};

const QualityIndex = (props: { value?: number }) => {
    const index = props.value ? props.value : 0;
    return (
        <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={(p: any) => renderTooltip(p, index)}
        >
            <div className={`quality-index index-${index}`} >
                <div>{index !== 0 ? index : "?"}</div>
            </div>
        </OverlayTrigger>
    );
}

export default QualityIndex;