import * as React from "react";
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const TosRenouncement = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">
                    <h3>WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY</h3>
                    (formularz ten należy wypełnić i odesłać wyłącznie w przypadku chęci odstąpienia od umowy)
                    <pre>{`
	                                                    	 		                                                           ………………………………………(miejscowość i data)

                    Jakuba Szczerba „Termipol”
                    ul. Powstańców Śl. 64
                    42-700 Lubliniec



                    Ja…………………………………………niniejszym informuję o moim odstąpieniu od umowy świadczenia usług produktu:
                    Nazwa	    Ilość szt	    Cena netto	    Cena netto





                    Data złożenia zamówienia		………………………………………………
                    Data opłacenia Usługi			………………………………………………
                    Imię i nazwisko konsumenta 		………………………………………………
                    Adres konsumenta			………………………………………………
                    Podpis konsumenta  (tylko jeżeli formularz jest przesyłany w wersji papierowej)
                    ……………………………………………………………………………………………………………………………


                    Data………………………
                    `}</pre>
                </div>
            </ScrollArea>
        </div>
    );

export default TosRenouncement;