import { INotificationPhoneNumber } from "constants/SmsNotificationConstants";
import * as React from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IAppStore } from "store/configureStore";
import {SmsNotificationActionCreators} from "actions/SmsNotificationActionCreators";
import "../../less/Notification.css";
import { ISmsNotification } from "store/SmsNotificationStore";
import UserContext, { IUserContext } from "../UserContext";
import SubmitButton from "../SubmitButton";


interface IPhoneRegistrationDialogProps extends ISmsNotification {
    savePhoneNumber: (model: INotificationPhoneNumber) => void;
    closePhoneDialog: () => void;
    setPhoneNumber: (phoneNumber: string) => void;
    displayRegulation: (show: boolean) => void;
}

const PhoneRegistrationDialog = (props: IPhoneRegistrationDialogProps) => {
    const PHONE_LENGTH_MAX = 12;
    const PHONE_LENGTH_MIN = 10;

    const sanitizeNumber = (num: string): string => (num ? num.replace("(", "").replace(")", "").replace(new RegExp(' ', 'g'), "") : num);
    
    const validatePhoneNumber = (num:string): boolean => {
        const n = sanitizeNumber(num);
        if(!validatePhoneNumberPattern(n))
            return false;
        return  props.phoneNumberList.filter(p=>p.phoneNumber === n).length == 0;
    }
    
    const validatePhoneNumberPattern = (num: string): boolean => {
        return (num && !isNaN(Number(num)) && num.length <= PHONE_LENGTH_MAX && num.length >= PHONE_LENGTH_MIN);
    }

    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        props.setPhoneNumber(value ? value.toUpperCase() : value);
    }

    const handleSavingPhoneNumber = () => { props.savePhoneNumber({ phoneNumber: sanitizeNumber(props.editingPhoneNumber) }); }
    const displayRegulation = () => { props.displayRegulation(!props.showRegulation); }
    const userContext = React.useContext<IUserContext>(UserContext);

    return (
        <Modal centered show={true} className={"register-phone-dialog " + userContext.style}>
            <Modal.Body>
                <div className="row">
                    <div className="col-sm-12">
                        <Form noValidate validated={validatePhoneNumber(props.editingPhoneNumber)}>
                            <div className="title-label" ><FormattedMessage id="notifications.phone.send.number" /></div>
                            <div className="phoneNumber">
                                +<FormControl
                                    className="form-control-inline"
                                    placeholder="(48)111 111 111"
                                    type="text"
                                    name="phone-number"
                                    value={props.editingPhoneNumber !== null ? props.editingPhoneNumber : ""}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Form>
                        {props.message ?
                            <div className="error-message">
                                    <FormattedMessage id={props.message} />
                              </div>
                            :
                            <div className="comment">
                                <FormattedMessage id="notifications.phone.send.number.note" />
                            </div>}

                        <div className="regulations">
                            <div className={(props.showRegulation ? "" : "collapse") + " rodo"} onClick={displayRegulation}>
                                <ol>
                                    <li>Administratorem danych osobowych jest Jakub Szczerba prowadzący działalność gospodarczą pod firmą Termipol, z siedzibą w Lublińcu (42-700), ul. Powstańców Śl. 64, NIP 575-180-10-22, REGON 362191270</li>
                                    <li>Dane osobowe przetwarzane będą w celu realizacji zamówienia (art. 6 ust. 1 lit. b rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych). Dane osobowe będą przetwarzane aż do ewentualnego zgłoszenia sprzeciwu przez osobę, której dane dotyczą, a po tym okresie dla celów i przez okres oraz w zakresie wymaganym przez przepisy prawa lub dla zabezpieczenia ewentualnych roszczeń</li>
                                    <li>Dane osobowe udostępniane będą firmie kurierskiej i podmiotowi za pomocą którego realizowana jest płatność, z którymi współpracuje administrator danych osobowych</li>
                                    <li>Osobie, której dane dotyczą, przysługuje prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec ich przetwarzania, a także  prawo wniesienia skargi do organu nadzorczego</li>
                                    <li>Podanie danych jest dobrowolne, lecz niezbędne do realizacji zamówienia</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a className="title" onClick={displayRegulation}>
                    <i className="material-icons">list_alt</i><FormattedMessage id="notifications.phone.regulations" />
                </a>
                <Button onClick={props.closePhoneDialog}>
                    <FormattedMessage id="notifications.cancel" />
                </Button>
                    <SubmitButton  disabled={!validatePhoneNumber(props.editingPhoneNumber)}
                                   onClick={handleSavingPhoneNumber}>
                        <FormattedMessage id="notifications.phone.register" />
                    </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default connect(
    (state: IAppStore) => state.smsNotification,
    (dispatch: any) => bindActionCreators(SmsNotificationActionCreators, dispatch)
)(PhoneRegistrationDialog);