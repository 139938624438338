import * as React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { UserActionCreators } from "actions/UserActionCreators";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IRegistrationModel, IRegistrationResult } from "constants/RegistrationConstants";
import "../../less/Login.css";
import { IAppStore } from "store/configureStore";
import { IUserContextStore } from "store/UserInfoStore";
import InfoMenu from '../InfoMenu';
import Spinner from "../Spinner";
import RodoStatemant from "./Rodo";
import UserContext, { IUserContext } from "../UserContext";
import FooterLinks from "./FooterLinks";

interface IRegistrationForm extends IRegistrationModel, IRegistrationResult { }

interface IUserContextSiteProps extends IUserContextStore {
    fetchUserInfo: () => void;
}

const Registration = (props: IUserContextSiteProps) => {
    const [model, setRegistrationModel] = React.useState({ email: "", password: "", confirmPassword: "", reCaptchaResponse: "", termOfServiceAcceptance: false } as IRegistrationForm);
    const intl = useIntl();
    const userContext = React.useContext<IUserContext>(UserContext);

    const submitForm = () => {
        setRegistrationModel({ ...model, message: "loading" });
        UserAccountAPI.registerUser(model)
            .then((response: Response) => response.json())
            .then((loginResult: IRegistrationResult) => {
                setRegistrationModel({ ...model, isSuccessed: loginResult.isSuccessed, message: loginResult.message });
            });
    };

    if (model.isSuccessed)
        return (
            <div className={"register-success  " + props.style}>
                <InfoMenu />
                <div className="wrapper fadeInDown">
                    <div className={"logo-box " + props.applicationName} />
                    <ConfirmationEmailSent />
                </div>
            </div>
        );

    return (
        <div className={"register " + props.style}>
            <InfoMenu />
            <div className="wrapper fadeInDown">
                <div className={"logo-box " + props.applicationName} />
                <Container>
                    <Row>
                        <Col className="formContent" xs={12} md={6}>
                            {(model.message === "loading"
                                ? <Spinner />
                                :
                                <>
                                    <Form>
                                        <h3><FormattedMessage id="page.registration.title" /></h3>
                                        <Form.Label><FormattedMessage id="page.registration.create" /></Form.Label>
                                        <Form.Control.Feedback type="invalid" className="login-message" hidden={model.message == null}>
                                            {(model.message != null ? <FormattedMessage id={model.message} /> : null)}
                                        </Form.Control.Feedback>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                value={model.email}
                                                onInput={(e: any) => setRegistrationModel({ ...model, email: e.target.value })} />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Control
                                                type="password"
                                                placeholder={intl.formatMessage({ id: "page.registration.password" })}
                                                value={model.password}
                                                onInput={(e: any) => setRegistrationModel({ ...model, password: e.target.value })}
                                                onKeyPress={(e: any) => {
                                                    if (e.key === "Enter")
                                                        submitForm();
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPasswordConfirmation">
                                            <Form.Control
                                                type="password"
                                                placeholder={intl.formatMessage({ id: "page.registration.confirmPassword" })}
                                                value={model.confirmPassword}
                                                onInput={(e: any) => setRegistrationModel({ ...model, confirmPassword: e.target.value })}
                                                onKeyPress={(e: any) => {
                                                    if (e.key === "Enter")
                                                        submitForm();
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formTermOfService">
                                            <Form.Check
                                                type="checkbox"
                                                label={
                                                    <div style={{ marginTop: "-5px" }}>
                                                        <Link to="/tos" style={{ color: "white" }}>
                                                            <FormattedMessage id="termOfService.acceptance" />
                                                            <i className="material-icons" style={{ position: "relative", left: "2px", top: "5px" }}>assignment_turned_in</i>
                                                        </Link>
                                                    </div>}
                                                checked={model.termOfServiceAcceptance}
                                                onChange={() => setRegistrationModel({ ...model, termOfServiceAcceptance: !model.termOfServiceAcceptance })} />

                                        </Form.Group>
                                        <div className="recaptcha-box">
                                            <ReCAPTCHA
                                                sitekey="6Ld2z0EUAAAAAIw7-1rosgSDCmIsNKPR0MyDoqRq"
                                                onChange={(value: any) => setRegistrationModel({ ...model, reCaptchaResponse: value })}
                                            />
                                        </div>
                                        <Button variant="success" type="button" onClick={submitForm}>
                                            <FormattedMessage id="page.registration.button" />
                                        </Button>
                                    </Form>
                                    {userContext && userContext.externalAuthenticationEnabled && props.externalLogins ? <>
                                        <div className="or"> <label><FormattedMessage id="page.login.or" /></label> </div>
                                        <form action="/Account/ExternalLogins" method="post" >
                                            {(props.externalLogins || []).map(p => (
                                                <div>
                                                    <input type="hidden" name="loginProvider" value={p.name} required />
                                                    <input type="hidden" name="returnUrl" value={props.redirectUrl} required />
                                                    <input className={p.name.toLowerCase() + "-provider"} type="submit" value=""
                                                        title={intl.formatMessage({ id: "page.login.useAccount" }, { "0": p.displayName })}
                                                    />
                                                </div>)
                                            )}
                                        </form>
                                    </> : null}
                                </>
                            )}
                        </Col>
                        <Col className="statement" xs={12} md={6}>
                            <RodoStatemant />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

const ConfirmationEmailSent = () => (
    <>
        <div className="formContent">
            <Form>
                <Form.Label><FormattedMessage id="page.registration.create" /></Form.Label>
                <Form.Control.Feedback type="valid" className="login-message">
                    <FormattedMessage id="email.confirmed.check" />
                </Form.Control.Feedback>
            </Form>
        </div>
       <FooterLinks/>
    </>
);

export default connect(
    (state: IAppStore) => state.userInfo,
    (dispatch: any) => bindActionCreators(UserActionCreators, dispatch)
)(Registration);

