import {FormattedMessage, useIntl} from "react-intl";
import * as React from "react";
import {Col, Row, FormControl, FormCheck} from "react-bootstrap";
import "../../../less/DeviceParametersSettings.css";
import {IParameterSettings} from "constants/Constants";
import ThresholdRange from "components/Device/DeviceDetails/ThresholdRange";
import {IEditedDevice} from "constants/DeviceConstants";
import ParameterConverterModal from "components/Device/DeviceParameters/ParameterConverterModal";
import Switch from "../../../controles/Switch";
import {GetValueFromControl, IsNullOrWhiteSpace, ValidateNumber} from "../../../constants/Common";
import {ValidateParameterConverter} from "./ConverterForm";

export const ValidateParameterSettings = (editForm: IEditedDevice): boolean => ValidOffsets(editForm) && ValidateNames(editForm) && ValidateParameterConverter(editForm);

const ValidOffsets = (editForm: IEditedDevice): boolean => {
    let result = true;
    editForm.parameterSettings.forEach((parameter: IParameterSettings) => { result = result && ValidateNumber(parameter.offset); });
    return result;
}

const ValidateNames = (form: IEditedDevice): boolean => form.parameterSettings.filter(p => !ValidateName(p)).length === 0;

const ValidateName = (parameterSettings: IParameterSettings): boolean => {
    const maxLengthName = 32;
    const value = parameterSettings.customName;
    return value === null || value === undefined
        || (value.length <= maxLengthName
            && !IsNullOrWhiteSpace(value));
}

const ParameterSettings = (
    props: {
        parameter: IParameterSettings,
        handleSensorChange: (parameter: IParameterSettings) => void,
    }
) =>{

    const handleVisibilityCheckChange = () => {
        const showInDashboardTile = props.parameter.showInDashboardTile;
        props.handleSensorChange({ ... props.parameter, showInDashboardTile: !showInDashboardTile });
    }

    const handleNameChange = (event: React.FormEvent<any>) => {
        const name = GetValueFromControl(event);
        props.handleSensorChange({ ... props.parameter, customName: name as string });
    }

    const handleOffsetChange = (event: React.FormEvent<any>) => {
        const offset = GetValueFromControl(event);
        props.handleSensorChange({ ... props.parameter, offset: offset as number });
    }
    const LabelInputRange = (value: unknown): string => (`${value}${props.parameter.unit !== null 
        ? props.parameter.unit 
        : intl.formatMessage({id: "device.chart.parameter.unit_" + props.parameter.parameterId })}`);

    const handleThresholdChange = (value: any) => {
        props.handleSensorChange({ ... props.parameter, thresholdMin: value.min, thresholdMax: value.max });
    }

    const intl = useIntl();
    return  ( <Row style={{height:"60px"}}>
        <Col xs={1} className="parameter-name-label">
            <FormattedMessage id={`device.chart.parameter_${props.parameter.parameterId}`} />
        </Col>
        <Col xs={3}>
            <ParameterConverterModal parameter={props.parameter} handleSensorChange={props.handleSensorChange} />
            <FormControl type="text"
                     className="parameter-name-text-box"
                     isInvalid={!ValidateName(props.parameter)}
                     placeholder={
                         intl.formatMessage({
                             id: `device.chart.parameter_${props.parameter.parameterId}`
                         })
                     }
                     name={props.parameter.parameterId.toString()}
                     defaultValue={props.parameter.customName}
                     onChange={handleNameChange}
        /> </Col>
        <Col xs={1} style={{minWidth:"70px"}} className="parameter-check">
            <Switch
                onChange={handleVisibilityCheckChange}
                checked={props.parameter.showInDashboardTile}
                />

        </Col>
        <Col xs={1} style={{minWidth:"70px"}}>
            <FormControl type="text"
                         style={{width:"80px", margin:"auto"}}
                         key={`offset_${props.parameter.parameterId}`}
                         name={`offset_${props.parameter.parameterId}`}
                         defaultValue={props.parameter.offset}
                         onChange={handleOffsetChange}
            />
        </Col>
        <Col xs={6}>
            <ThresholdRange
                    maxValue={props.parameter.customMaxValue ?? props.parameter.maxValue}
                    minValue={props.parameter.customMinValue ?? props.parameter.minValue}
                    formatLabel={LabelInputRange}
                    thresholdMax={props.parameter.thresholdMax}
                    thresholdMin={props.parameter.thresholdMin}
                    onChange={handleThresholdChange} />
        </Col>
        </Row>);
}

export default ParameterSettings;