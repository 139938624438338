import * as React from "react";
import {createContext, useState} from "react";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "../../less/SpacePlanner.css";
import {PlannerTab} from "./PlannerTab";
import {useSpacePlannerDevices} from "../../hooks/useSpacePlannerDevices";

import {useSpacePlannerTabs} from "../../hooks/useSpacePlannerTabs";
import {TabHeader, ViewMode} from "./TabHeader";
import {IPlannerDevice, PlannerDeviceLimits} from "../../constants/PlannerDeviceConstants";
import {FormattedMessage} from "react-intl";

export const SpacePlannerContext = createContext<[
    IPlannerDevice[],
    (devices: IPlannerDevice[]) => void,
    (deviceId: number) => void]>
([null, null, null]);

const SpacePlanner = () => {
    const [devices, addDevices, removeDevice] = useSpacePlannerDevices();
    const [tabsState, addTab, removeTab, renameTab, setActiveTab] = useSpacePlannerTabs();

    return (
        <SpacePlannerContext.Provider value={[devices, addDevices, removeDevice]}>
            <Tabs
                id="controlled-tab-example"
                activeKey={tabsState.activeTabId}
                onSelect={(planId) => setActiveTab(planId)}
                style={{marginTop: "6px"}}
            >
                {tabsState.tabs.map(tab => (
                    <Tab eventKey={tab.planId}
                         title={<TabHeader title={tab.name} defaultMode={ViewMode.Default} onChange={(title: string) => renameTab(tab.planId, title)}/>}
                         key={tab.planId}>
                        <PlannerTab planId={tab.planId}
                                    name={tab.name}
                                    onDelete={() => removeTab(tab.planId)}
                        />
                    </Tab>
                ))}
                {tabsState.tabs.length < PlannerDeviceLimits.FloorNumberLimit ?
                    <Tab title={<TabHeader defaultMode={ViewMode.New} onChange={(title: string) => addTab(title)}/>}>
                    </Tab>
                    : null}
            </Tabs>
            {tabsState.tabs.length == 0 ?
                <div className="space-planner-desc">
                    <FormattedMessage id="space-planner.description" values={{icon: <span className="material-icons">control_point</span>}}/>
                </div>
                : null}
        </SpacePlannerContext.Provider>
    );
}


export default SpacePlanner;