import { IDeviceTile } from "constants/Constants";
import "../../less/DashboardTile.css";
import DashboardSmogTile from "./DashboardSmogTile";
import DashboardTile from "./DashboardTile";
import * as React from "react";

import {IsSmogParameter} from "constants/Common";

interface IDashboardTileProps {
    data: IDeviceTile;
}

const isSmogDevice = (data: IDeviceTile) => data.deviceParameters.filter(d => IsSmogParameter(d)).length > 0;

const DashboardTileWrapper = (props: IDashboardTileProps) => {
    const data = props.data
    if (isSmogDevice(data))
        return (<DashboardSmogTile data={data} />)
    return (<DashboardTile data={data} />)
}

export default DashboardTileWrapper;