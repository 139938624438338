import * as React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import '../less/App.css';

const SiteLeftPanelSmall = () => {
    return (
        <Nav>
            <LinkContainer to="/main" key="dashboard">
                <Nav.Item>
                    <i className="material-icons">dashboard</i>
                </Nav.Item>
            </LinkContainer>
        </Nav>
    )
}

export default SiteLeftPanelSmall;