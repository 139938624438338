import * as React from "react";
import { useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserActionCreators } from "../../actions/UserActionCreators";
import UserAccountAPI from "../../api/UserAccountAPI";
import "../../less/Manage.css";
import { IAppStore } from "../../store/configureStore";
import { IUserContextStore } from "../../store/UserInfoStore";
import SubmitButton from "../SubmitButton";


interface IUserContextSiteProps extends IUserContextStore {
    fetchUserInfo: () => void;
}

const LanguageForm = (props: IUserContextSiteProps) => {
    const intl = useIntl();
    const [language, setLanguage] = useState(props.language);
    const [enableButton, setEnableButton] = useState(false);

    const submitForm = () => {
        UserAccountAPI.setLanguage({ interfaceLanguage: language })
            .then((response: Response) => {
                if (!response.ok) {
                    setEnableButton(true);
                    setLanguage(props.language);
                    return Promise.reject(response.statusText);
                }
                return response;
            })
            .then((response: Response) => response.json())
            .then((setLanguageResult: boolean) => {
                if (setLanguageResult) {
                    props.fetchUserInfo();
                }
            });
    };

    return (
        <>
            <h5><FormattedMessage id="page.manage.language" /></h5>
            <DropdownButton
                id="dropdown-language-button"
                variant="secondary"
                className="language-dropdown"
                title={intl.formatMessage({ id: `language.${language}` })}
                onSelect={(language: string | null)=>{
                    setLanguage(language);
                    setEnableButton(props.language !== language);
                }}
            >
                <Dropdown.Item eventKey={"pl"}><FormattedMessage id="language.pl" /></Dropdown.Item>
                <Dropdown.Item eventKey={"en"}><FormattedMessage id="language.en" /></Dropdown.Item>
            </DropdownButton>
            <br />
            <SubmitButton disabled={!enableButton} onClick={submitForm}>
                <FormattedMessage id="page.manage.changeLanguage" />
            </SubmitButton>
        </>
    );
}

export default connect(
    (state: IAppStore) => state.userInfo,
    (dispatch: any) => bindActionCreators(UserActionCreators, dispatch)
)(LanguageForm);