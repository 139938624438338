export class EmailNotificationActions {
    public static readonly SAVE_EMAIL = "SAVE_EMAIL";
    public static readonly SAVE_EMAIL_SUCCESS = "SAVE_EMAIL_SUCCESS";
    public static readonly SAVE_EMAIL_FAILED = "SAVE_EMAIL_FAILED";

    public static readonly SAVE_EMAIL_ACTIVITY = "SAVE_EMAIL_ACTIVITY";
    public static readonly SAVE_EMAIL_ACTIVITY_SUCCESS = "SAVE_EMAIL_ACTIVITY_SUCCESS";
    public static readonly SAVE_EMAIL_ACTIVITY_FAILED = "SAVE_EMAIL_ACTIVITY_FAILED";

    public static readonly CHANGE_EMAIL_ACTIVITY = "CHANGE_EMAIL_ACTIVITY";

    public static readonly SET_EMAIL = "SET_EMAIL";
    public static readonly ACTIVE_EMAIL = "ACTIVE_EMAIL";
    public static readonly DELETE_EMAIL = "DELETE_EMAIL";

    public static readonly SHOW_EMAIL_DIALOG = "SHOW_EMAIL_DIALOG";
    public static readonly CLOSE_EMAIL_DIALOG = "CLOSE_EMAIL_DIALOG";
    
    public static readonly GET_REGISTERED_EMAILS = "GET_REGISTERED_EMAILS";
    public static readonly GET_REGISTERED_EMAILS_SUCCESS = "GET_REGISTERED_EMAILS_SUCCESS";
    public static readonly GET_REGISTERED_EMAIL_FAILED = "GET_REGISTERED_EMAIL_FAILED";
}

export interface IRegisteredEmailForm {
    email: string;
    isActive: boolean;
    isReportActive: boolean;
    toDelete: boolean;
}

export interface IRegisteredEmail extends IRegisteredEmailForm {
    isConfirmed: boolean;
}

export interface INotificationEmailAddress {
    email: string;
}