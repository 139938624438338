import * as React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router-dom";
import "../../less/Manage.css";
import { UserContextConsumer } from "../UserContext";
import ChangePasswordForm from "./ChangePasswordForm";
import DeleteAccountForm from "./DeleteAccountForm";
import LanguageForm from "./LanguageForm";


const Manage = () => {
    const [selectedItem, setSelectedItem] = useState(0);
    return (
        <UserContextConsumer>
            {userContext =>
                userContext && (
                    userContext.isAuthenticated
                        ? <div className="manage">
                            <h3>
                                <i className="material-icons">account_box</i> {" "}
                                <FormattedMessage id="page.manage.title" />
                            </h3>
                            <h6 style={{paddingLeft:"34px"}}> {userContext.userName} </h6>
                            <Container>
                                <Row>
                                    <Col md={4} sm={4} lg={2} className="left">
                                        <Row>
                                            <MenuItem
                                                onClick={() => setSelectedItem(0)}
                                                active={selectedItem === 0}
                                                textId="page.registration.password" />
                                            <MenuItem
                                                onClick={() => setSelectedItem(1)}
                                                active={selectedItem === 1}
                                                textId="page.manage.language" />
                                            <MenuItem
                                                onClick={() => setSelectedItem(2)}
                                                active={selectedItem === 2}
                                                textId="page.manage.deleteAccount" />
                                        </Row>
                                    </Col>
                                    <Col md={8} sm={8} lg={10} className="right">
                                        <div className="content">
                                            {
                                                selectedItem === 0 ? <ChangePasswordForm />
                                                    : selectedItem === 1 ? <LanguageForm />
                                                        : selectedItem === 2 ? <DeleteAccountForm />
                                                            : null
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        : <Navigate to="/portal" />
                )
            }
        </UserContextConsumer>
    )
};

const MenuItem = (props: { active: boolean, textId: string, onClick: () => void }) => (
    <div className={"menu-item" + (props.active ? " active" : "")} onClick={props.onClick}>
        <FormattedMessage id={props.textId} />
    </div>
)

export default Manage;
