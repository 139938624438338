import * as React from "react";
import { useEffect } from "react";
import "react-input-range/lib/css/index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceActionCreators } from "actions/DeviceActionCreators";
import { EditDeviceStatus, ModeDeviceDetailsView } from "constants/Constants";
import "../../../less/DeviceDetails.css";
import { IAppStore } from "store/configureStore";
import DeviceDetailsEdit from "./DeviceDetailsEdit";
import DeviceDetailsView from "./DeviceDetailsView";
import { IEditedDevice, IDeviceDetails } from "constants/DeviceConstants";
import DeviceContext, {IDeviceContext} from "../../DeviceContext";

interface IDeviceDetailsProps {
    message: string;
    editForm: IEditedDevice;
    device: IDeviceDetails;
    mode: ModeDeviceDetailsView;
    editDataStatus: EditDeviceStatus;
    editDeviceForm: (editedForm: IEditedDevice) => void;
    changeModeView: (mode: ModeDeviceDetailsView) => void;
    saveDeviceDetails: (model: IEditedDevice) => void;
    cancelEditing: () => void;
    fetchSingleDevice: (deviceId: number) => void;
}

const DeviceDetails = (props: IDeviceDetailsProps) => {
    const deviceContext = React.useContext<IDeviceContext>(DeviceContext);

    const edit = () => {
        return (<DeviceDetailsEdit
            device={props.device}
            editForm={props.editForm}
            editDeviceForm={props.editDeviceForm}
            cancelEditing={props.cancelEditing}
            saveDeviceDetails={props.saveDeviceDetails}
        />);
    }
   
    const view = () => {
        return (<DeviceDetailsView device={props.device} />);
    }


    useEffect(() => {
        props.fetchSingleDevice(deviceContext.deviceId);
    }, [deviceContext.deviceId]);
    
    return props.editDataStatus === "saving" || props.message === "loading"
        ? null
        : props.mode === "Edit"
            ? edit()
            : (props.device ? view() : null)
}

function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        message: state.deviceDetails.message,
        editForm: state.deviceDetails.editedDevice,
        device: state.deviceDetails.device,
        editDataStatus: state.deviceDetails.editDeviceStatus,
        mode: state.deviceDetails.mode,
        deviceId: ownProps.deviceId
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(DeviceDetails);