import DeviceDataAPI from "../api/DeviceDataAPI";
import { DeviceActions, IDevice } from "constants/DeviceConstants";
import promiseDispatch from "./promiseDispatch";

export const SiteActionCreators = {
    fetchDevices: () => async (dispatch: any, getState: any) => {
        promiseDispatch<IDevice[]>(dispatch,
            DeviceDataAPI.fetchDevices,
            {
                request: DeviceActions.FETCH_DEVICES,
                success: DeviceActions.FETCH_DEVICES_SUCCESS,
                failure: DeviceActions.FETCH_DEVICES_FAILED
            });
    },
};
