import * as React from "react";
import {Form} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import UserAccountAPI from "../../api/UserAccountAPI";
import {IEmailResult} from "../../constants/Constants";
import "../../less/Login.css";
import InfoMenu from '../InfoMenu';
import Spinner from "../Spinner";
import UserContext, {IUserContext} from "../UserContext";
import {useSearchParams, Link} from "react-router-dom";

const UnSubscribeNotificationEmail = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let [code, setCode] = React.useState(
        searchParams.get("code")
    );
    const userContext = React.useContext<IUserContext>(UserContext);
    const [model, setModel] = React.useState({isSuccessed: false, email: null, message: null} as IEmailResult);
    React.useEffect(() => {
        setModel({...model, message: "loading"});
        UserAccountAPI.unsubscribeEmail({code})
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.status != 204 ? response.json() : null)
            .then((unsubscribedEmail: string) => {
                setModel({
                    ...model,
                    isSuccessed: unsubscribedEmail != null,
                    email: unsubscribedEmail,
                    message: unsubscribedEmail != null ? "email.confirmed.text" : "email.unsubscribe.error"
                });
            })
            .catch((r: Response) => r != null ? r.json() : null)
            .then((r: string) => {
                if (r) {
                    setModel({...model, isSuccessed: false, message: "email.confirmed.error"});
                }
            });

    }, []);
    return (
        <div className={"confirm-email " + userContext.style}>
            <InfoMenu/>
            <div className="wrapper fadeInDown">
                <div className={"logo-box " + userContext.applicationName}/>
                <div className="formContent">
                    {(model.message === "loading"
                            ? <Spinner/>
                            : <Form>
                                <Form.Control.Feedback type={model.isSuccessed ? "valid" : "invalid"} className="login-message" hidden={model.message == null}>
                                    {(model.isSuccessed ?
                                        <FormattedMessage id="email.notification.unsubscribe.confirm" values={{email: model.email}}/> :
                                        <FormattedMessage id={model.message ?? "email.confirmed.error"}/>)}
                                </Form.Control.Feedback>
                            </Form>
                    )}
                </div>
                <div className="formFooter">
                    <div className="link-container">
                        <Link className="underlineHover" to="/registration"><FormattedMessage id="page.login.gotoRegister"/></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnSubscribeNotificationEmail;

