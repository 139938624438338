import {IRegisteredEmail, EmailNotificationActions} from "../constants/EmailNotificationConstants";

export interface IEmailNotification {
    showEmailRegistration: boolean;
    isSaved: boolean;
    message?: string;
    emailEnabled: boolean;
    loading: boolean;
    editingEmail: string;
    editingEmailCode: string;
    emailList: IRegisteredEmail[];
}

const initialState: IEmailNotification = {
    message: null,
    loading: false,
    showEmailRegistration: false,
    emailEnabled: false,
    isSaved: true,
    editingEmail: null,
    editingEmailCode: null,
    emailList: []
};

export const reducer = (state: IEmailNotification, action: any): IEmailNotification => {
    state = state || initialState;
    switch (action.type) {
        case EmailNotificationActions.SHOW_EMAIL_DIALOG:
            return {...state, showEmailRegistration: true};
        case EmailNotificationActions.CLOSE_EMAIL_DIALOG:
            return {
                ...state,
                showEmailRegistration: false,
                message: null
            };
        case EmailNotificationActions.SET_EMAIL:
            return {
                ...state,
                editingEmail: action.payload
            };
        case EmailNotificationActions.CHANGE_EMAIL_ACTIVITY:
            return {
                ...state,
                isSaved: false,
                emailList:
                    state.emailList.map(p => {
                        if (p.email === action.payload.email)
                            return {
                                email: p.email,
                                isActive: action.payload.isActive,
                                isReportActive: action.payload.isReportActive,
                                isConfirmed: p.isConfirmed,
                                toDelete: false
                            }
                        return p;
                    })
            };
        case EmailNotificationActions.DELETE_EMAIL:
            return {
                ...state,
                isSaved: false,
                emailList:
                    state.emailList.map(p => {
                        if (p.email === action.payload.email)
                            return {
                                email: p.email,
                                isActive: false,
                                isReportActive: false,
                                isConfirmed: p.isConfirmed,
                                toDelete: action.payload.toDelete
                            }
                        return p;
                    })
            };
        case EmailNotificationActions.SAVE_EMAIL:
            return {
                ...state,
                isSaved: false,
                loading: true
            };
        case EmailNotificationActions.SAVE_EMAIL_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                isSaved: true,
                emailList: state.emailList.filter(obj => obj.toDelete != true)
            };
        case EmailNotificationActions.SAVE_EMAIL_SUCCESS:
            return {
                ...state,
                emailEnabled: false,
                showEmailRegistration: false,
                loading: false,
                emailList:
                    [...state.emailList, {
                        email: state.editingEmail,
                        toDelete: false,
                        isActive: false,
                        isReportActive: false,
                        isConfirmed: false
                    }],
                editingEmail: null
            };
        case EmailNotificationActions.SAVE_EMAIL_FAILED:
            return {
                ...state,
                message: "notifications.email.cant.be.saved"
            }
        case EmailNotificationActions.GET_REGISTERED_EMAILS:
            return {
                ...state,
                loading: true,
            };
        case EmailNotificationActions.GET_REGISTERED_EMAIL_FAILED:
            return {
                ...state,
                loading: false,
                message: "ERROR"
            };
        case EmailNotificationActions.GET_REGISTERED_EMAILS_SUCCESS:
            const payload = action.payload as IRegisteredEmail[];
            return {
                ...state,
                loading: false,
                emailList: payload.slice()
            };
        default:
            return state;
    }
}