import {FormattedMessage, useIntl} from "react-intl";
import {Dropdown, DropdownButton} from "react-bootstrap";
import * as React from "react";

const LanguageDropDown = (props: {
    handleLanguageChange: (eventKey: string) => void,
    isDisabled: boolean,
    language: string
}) => {
    const intl = useIntl();

    return (
        <div className="row content">
            <div className="col-sm-12 flex-box">
                <div className="input-group center-element">
                    <span><FormattedMessage id="notifications.language"/>: &nbsp;</span>
                    <DropdownButton
                        disabled={props.isDisabled}
                        title={intl.formatMessage({id: `language.${props.language || "en"}`, defaultMessage: "-"})}
                        id="dropdown-language"
                        key="dropdown-language"
                        onSelect={props.handleLanguageChange}>
                        <Dropdown.Item eventKey="en" active={props.language === "en"}>
                            <FormattedMessage id="language.en" defaultMessage="en"/>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="pl" active={props.language === "pl"}>
                            <FormattedMessage id="language.pl" defaultMessage="pl"/>
                        </Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>
        </div>);
}

export default LanguageDropDown;