import DeviceDataAPI from "../api/DeviceDataAPI";
import { IDeviceMarker, MapActions } from "constants/MapConstants";
import promiseDispatch from "./promiseDispatch";

export const MapActionCreators = {
    fetchMapMarkers: () => async (dispatch: any, getState: any) => {
        promiseDispatch<IDeviceMarker[]>(dispatch,
            DeviceDataAPI.fetchMapMarkers,
            {
                request: MapActions.FETCH_MARKERS_REQUEST,
                success: MapActions.FETCH_MARKERS_SUCCESS,
                failure: MapActions.FETCH_MARKERS_FAILED
            });
    }
};