import * as React from "react";
import {IParameterSettings} from "../../../constants/Constants";
import {Container, Row, Col, FormControl} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {useEffect} from "react";
import {GetValueFromControl, ToString, ValidateNumber} from "../../../constants/Common";

const ParameterLimits = (
    props: {
        parameter: IParameterSettings,
        handleSensorChange: (parameter: IParameterSettings) => void,
    }
) => {
    const [minValueInValidation, checkMinValueInValidation] = React.useState(false);
    const [maxValueInValidation, checkMaxValueInValidation] = React.useState(false);

    const [minValueStr, setMinValueStr] = React.useState(ToString(props.parameter.customMinValue));
    const [maxValueStr, setMaxValueStr] = React.useState(ToString(props.parameter.customMaxValue));

    useEffect(() => {
        if(!minValueInValidation)
            setMinValueStr(ToString(props.parameter.customMinValue));
    }, [props.parameter.customMinValue]);

    useEffect(() => {
        if(!maxValueInValidation)
            setMaxValueStr(ToString(props.parameter.customMaxValue));
    }, [props.parameter.customMaxValue]);


    const handleMinValueChange = (event: React.FormEvent<any>) => {
        const value = GetValueFromControl(event);
        const isValid = ValidateNumber(value);
        checkMinValueInValidation(!isValid);
        setMinValueStr(ToString(value));
        if(!isValid)
            return;

        const customMinValue = value !== null ? Number(value) : null;
        const thresholdMin = props.parameter.thresholdMin < customMinValue !== null ? customMinValue : props.parameter.thresholdMin;

        props.handleSensorChange({
            ...props.parameter,
            thresholdMin: thresholdMin,
            customMinValue: customMinValue });
    }

    const handleMaxValueChange = (event: React.FormEvent<any>) => {
        const value = GetValueFromControl(event);
        const isValid = ValidateNumber(value);
        checkMaxValueInValidation(!isValid);
        setMaxValueStr(ToString(value));
        if(!isValid)
            return;
        const customMaxValue = value !== null ? Number(value) : null;
        const thresholdMax = props.parameter.thresholdMax > customMaxValue !== null ? customMaxValue : props.parameter.thresholdMax;

        props.handleSensorChange({
            ...props.parameter, 
            customMaxValue: customMaxValue,
            thresholdMax: thresholdMax
        });
    }

    return (
        <Container>
            <Row style={{height: "50px"}}><Col><FormattedMessage id="device.edit.converter.limits.desc" /></Col></Row>
            <Row style={{height: "50px"}}>
                <Col xs={6}> <FormattedMessage id="device.edit.converter.min-value"/> </Col>
                <Col xs={6}>
                    <FormControl type="text"
                                 placeholder={`${props.parameter.minValue}`}
                                 key={`min_value_${props.parameter.parameterId}`}
                                 name={`min_value_${props.parameter.parameterId}`}
                                 value={minValueStr}
                                 onChange={handleMinValueChange}
                                 isInvalid={minValueInValidation}/>
                </Col>
            </Row>
            <Row style={{height: "50px"}}>
                <Col xs={6}> <FormattedMessage id="device.edit.converter.max-value"/> </Col>
                <Col xs={6}>
                    <FormControl type="text"
                                 placeholder={`${props.parameter.maxValue}`}
                                 key={`max_value_${props.parameter.parameterId}`}
                                 name={`max_value_${props.parameter.parameterId}`}
                                 value={maxValueStr}
                                 onChange={handleMaxValueChange}
                                 isInvalid={maxValueInValidation}/>
                </Col>
            </Row>
        </Container>
    );
}

export default ParameterLimits;