import Switch from "../../controles/Switch";
import * as React from "react";
import {Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

interface IPhoneNumberRecordProps {
    phoneNumber: string;
    isActive: boolean;
    isConfirm: boolean;
    toDelete: boolean;
    deleteNumber: (number: string) => void;
    changeActivityStatus: (number: string) => void;
    showConfirmDialog: (number: string) => void;
}

const PhoneNumberRecord = (props: IPhoneNumberRecordProps) => {

    const handleStatusChange = () => props.changeActivityStatus(props.phoneNumber);
    const handleDeleteEvent = () => {
        if(!props.toDelete)
            props.deleteNumber(props.phoneNumber);
    }
    const handleShowConfirmDialog = () => props.showConfirmDialog(props.phoneNumber);
    
    const activeTrash = {cursor: "pointer"};
    const disabledTrash = {cursor: "default", color: "gray"};
    return (<tr>
        <td>{props.phoneNumber}</td>
        <td className="action">
            {props.toDelete
                ? <i style={{color:"gray"}}><FormattedMessage id="notifications.phone.delete"/></i>
                : (props.isConfirm 
                    ? <Switch checked={props.isActive} onChange={handleStatusChange}/>
                    : <Button onClick={handleShowConfirmDialog}>
                        <FormattedMessage id="notifications.phone.show.confirm"/> 
                      </Button>)
            }
            </td>
        <td>
            <div className="remove" onClick={handleDeleteEvent}>
                <i className="material-icons" style={props.toDelete ? disabledTrash : activeTrash}> delete_forever </i>
            </div>
        </td>
    </tr>);
}

export default PhoneNumberRecord