import { IDeviceMarker, MapActions } from 'constants/MapConstants';

export interface IDeviceMarkupsList {
    devices: IDeviceMarker[];
    mapCenter: [number, number];
    bounds: [number, number][];
    message: string;
}

const initialState: IDeviceMarkupsList = { devices: [], message: null, mapCenter: null, bounds: null };

export const reducer = (state: IDeviceMarkupsList, action: any): IDeviceMarkupsList => {
    state = state || initialState;
    switch (action.type) {
        case MapActions.FETCH_MARKERS_REQUEST:
            return { ...state, devices: state.devices, message: "Loading" };
        case MapActions.FETCH_MARKERS_SUCCESS:
            const payload = action.payload as IDeviceMarker[];
            const mapCenter = calculateMapCenter(payload);
            const bounds = calculateBounds(payload);
            return { ...state, devices: payload, mapCenter, bounds, message: null, };
        case MapActions.FETCH_MARKERS_FAILED:
            return { ...state, devices: [], message: action.payload };
        default:
            return state;
    }
}

function calculateMapCenter(devices: IDeviceMarker[]): [number, number] {
    const array = (devices || []);

    if (array.length === 0)
        return null;

    const result = array.reduce((accu, curr, idx, arr) => {
        accu.lat += curr.latitude;
        accu.lng += curr.longitude;
        return accu;
    }, { lat: 0, lng: 0, name: null, values: null });
    return [result.lat / array.length, result.lng / array.length]
}

function calculateBounds(devices: IDeviceMarker[]): [number, number][] {
    if (devices && devices.length > 0) {
        let latMin = Math.min.apply(Math, devices.map(m => (m.latitude)));
        let latMax = Math.max.apply(Math, devices.map(m => (m.latitude)));
        let lngMin = Math.min.apply(Math, devices.map(m => (m.longitude)));
        let lngMax = Math.max.apply(Math, devices.map(m => (m.longitude)));

        if (latMin === latMax) {
            latMin = latMin - 0.01;
            latMax = latMax + 0.01;
        }
        if (lngMin === lngMax) {
            lngMin = lngMin - 0.01;
            lngMax = lngMax + 0.01;
        }
        return [[latMin, lngMin], [latMax, lngMax]];
    }
    return [[-45, -180], [45, 180]];
}