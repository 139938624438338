import * as moment from 'moment';
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DateTimeFormat, IDeviceTile } from "constants/Constants";
import { getParameterIcon } from "constants/Common";
import { SubscriptionType } from "constants/SubscriptionConstants";
import "../../less/DashboardTile.css";
import OnlineOffline from "../OnlineOffline";
import BatteryIcon, { BatteryPosition } from "./BatteryIcon";

interface IDashboardTileProps {
    data: IDeviceTile;
}

const DashboardTile = (props: IDashboardTileProps) => {
    const tile = props.data
    const intl = useIntl();

    return (<div className="tile normal">
        <div className="sensor-list">
            {(tile.deviceParameterNames || []).map(dpn => <span
                className={getParameterIcon(dpn.parameterId)}
                title={ intl.formatMessage({ id: `device.chart.parameter_${dpn.parameterId}` })}
                key={`sensor_${dpn.parameterId}`} />)}
        </div>
        <div className="online">
            <OnlineOffline isOnline={tile.isOnline} />
        </div>
        <BatteryIcon
            batteryLevel={tile.batteryLevel}
            title={intl.formatMessage({ id: "device.edit.battery" })}
            position={BatteryPosition.Horizontal} />
        <div className="name"> {tile.name} </div>
        <div className={"value" + ((tile.values || []).length > 1 ? "-list" : "")}>
            {(tile.values || []).length > 0 ?
                tile.values.slice(0,3).map((val) => {
                    const parameter = (tile.deviceParameterNames || []).filter(dpn=>dpn.parameterId === val.parameterId)[0];
                    const parameterName = parameter ? parameter.parameterName : null;
                    const parameterUnit = parameter ? parameter.unit : null;
                    return (<span className={(val.alert > 0 ? "above" : val.alert < 0 ? "below" : "normal")} key={`values_${val.parameterId}`}
                          title={parameterName ? parameterName : intl.formatMessage({id: `device.chart.parameter_${val.parameterId}`})}>
                        {val.value.toFixed(1)}
                        {parameterUnit ? <span> {parameterUnit} </span> : <FormattedMessage id={`device.chart.parameter.unit_${val.parameterId}`}/>}
                    </span>);
                })
                : <i className="material-icons">remove</i>}
        </div>
        <div className="lastUpdate"> {tile.lastUpdate ? moment.utc(tile.lastUpdate).local().format(DateTimeFormat) : null} </div>
      
    </div>)
}

export default DashboardTile;