import {fetch} from 'whatwg-fetch';
import {jsonHeader} from "../constants/Constants";
import {IPlannerDevice, ISpacePlannerData} from "../constants/PlannerDeviceConstants";

export class FloorPlannerAPI {

    public savePlan(plan: ISpacePlannerData): Promise<Response> {
        return fetch(`/api/floor-plans`, {
            method: "PUT",
            body: JSON.stringify(plan),
            headers: jsonHeader,
        });
    }

    public deletePlan(planId: string): Promise<Response> {
        return fetch(`/api/floor-plans/${planId}`, {
            method: "DELETE"
        });
    }
    
    public getPlansHeaders(): Promise<Response> {
        return fetch(`/api/floor-plans`);
    }

    public getDevices(): Promise<Response> {
        return fetch(`/api/floor-plans/devices`);
    }

    public getPlan(planId: string): Promise<Response> {
        return fetch(`/api/floor-plans/${planId}`);
    }
}

export default new FloorPlannerAPI;