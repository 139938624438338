import {CSSProperties, useContext, useEffect, useReducer} from "react";
import {initialState, ISpacePlanner, reducer} from "../../store/SpacePlannerStore";
import {Menu} from "./Menu";
import * as React from "react";
import {SpacePlannerContext} from "./SpacePlanner";
import {PlannerCanvas} from "./PlannerCanvas";
import promiseDispatch from "../../actions/promiseDispatch";
import {IBackgroundImage, IPlannerDevice, ISpacePlannerData, ISpacePlannerViewModel} from "../../constants/PlannerDeviceConstants";
import FloorPlannerAPI from "../../api/FloorPlannerAPI";
import {Alert, Button, Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

export interface IPlannerTabProps {
    planId: string,
    name: string,
    onDelete: () => void
}

export const PlannerTab = (props: IPlannerTabProps) => {
    const [devices, addDevices, removeDevice] = useContext(SpacePlannerContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    let timeoutId: NodeJS.Timeout = null;
    const handleRemoveDevice = (device: IPlannerDevice) => {
        addDevices([device]);
        dispatch({type: 'remove_device', deviceId: device.deviceId});
    }

    useEffect(() => {
        promiseDispatch<ISpacePlannerViewModel>(
            dispatch,
            () => FloorPlannerAPI.getPlan(props.planId),
            {
                request: 'loading',
                success: 'loaded',
                failure: 'loading_error'
            });

        return () => {
            clearTimeout(timeoutId);
        }
    }, [props.planId]);

    useEffect(() => {
        if (props.name !== state.name)
            dispatch({type: 'change_name', name: props.name});

    }, [props.name]);

    useEffect(() => {
        if (state.message == "saved" || "error") {
            timeoutId = setTimeout(function () {
                dispatch({type: 'clear_message'});
            }, 2000);
        }
    }, [state.message]);

    const alertStyle: CSSProperties = {width: "auto", position: "absolute", zIndex: "999", left: "40%", top: "60px"};
    const Alerts = () => {
        if (state.message == "saved")
            return (
                <Alert variant="success" style={alertStyle}>
                    <FormattedMessage id="space-planner.saved.desc" values={{name: <b>{props.name}</b>}}/>
                </Alert>
            );
        else if (state.message == "saving")
            return (
                <Alert variant="success" style={alertStyle}>
                    <FormattedMessage id="space-planner.saving.desc" values={{name: <b>{props.name}</b>}}/>
                </Alert>
            );
        else if( state.message == "error")
            return (
                <Alert variant="danger" style={alertStyle}>
                    <FormattedMessage id="space-planner.error.desc" values={{name: <b>{props.name}</b>}}/>
                </Alert>
            );
        else
            return null;
    }
    const handleClose = () => dispatch({type: 'clear_message'});
    const handleDeleteTab = () => {
        addDevices(state.devices);
        dispatch({type: 'remove_all_devices'});
        props.onDelete();
    }
    const ConfirmDialog = () => {
        return (
            <Modal show={state.message == "confirm_delete"} onHide={handleClose}>
                <Modal.Body><FormattedMessage id="space-planner.delete-confirm"/></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <FormattedMessage id="space-planner.cancel"/>
                    </Button>
                    <Button variant="danger" onClick={handleDeleteTab}>
                        <FormattedMessage id="space-planner.delete"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    
    return (
        <>
            <Alerts/>
            <ConfirmDialog/>
            <Menu
                devices={devices}
                saveEnabled={state.wasChanged}
                onUploadBackground={(backgroundImage: IBackgroundImage) => {
                    addDevices(state.devices);
                    dispatch({type: 'add_background', backgroundImage});
                }}
                OnDeleteBackground={() => {
                    addDevices(state.devices);
                    dispatch({type: 'delete_background'});
                }}
                onSelectDevice={(eventKey: string) => {
                    let device = devices.filter(d => d.deviceId == Number(eventKey)).pop();
                    if (device) {
                        removeDevice(device.deviceId)
                        dispatch({type: 'add_device', device});
                    }
                }}
                onSave={() => {
                    const payload = {
                        planId: props.planId,
                        name: state.name,
                        backgroundImage: state.backgroundImage,
                        devices: state.devices
                    } as ISpacePlannerData;

                    promiseDispatch<boolean>(
                        dispatch,
                        () => FloorPlannerAPI.savePlan(payload),
                        {
                            request: 'start_saving',
                            success: 'saved',
                            failure: 'saving_error'
                        });
                }}
                onDelete={() => {
                    dispatch({type: "confirm_dialog"});
                }}/>
            {state.loadStatus != "loading" ?
                <PlannerCanvas
                    devices={state.devices}
                    backgroundImage={state.backgroundImage}
                    removeDevice={handleRemoveDevice}
                    moveDevice={(deviceId, x, y) => {
                        const data = {deviceId, x, y};
                        dispatch({type: 'move_device', data});
                    }}/> : null}
        </>)
}
