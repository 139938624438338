import "c3/c3.min.css";
import * as React from "react";
import { useEffect } from "react";
import C3Chart from "react-c3js";
import "../../less/MapTrendChart.css";
import DeviceDataAPI from "../../api/DeviceDataAPI";
import { MeasurableParameter, DateTimeSecFormat } from "constants/Constants";
import { IDeviceMarkerData } from "constants/MapConstants";
import * as moment from 'moment';
import { useIntl } from "react-intl";
import { useState } from "react";

const axis = {
    x: {
        type: 'timeseries',
        tick: {
            count: 3,
            format: '%H:%M'
        },
        show: true
    },
    y: {
        tick: {
            count: 5,
            format: (x: number) => x.toFixed(0),
        },
        show: true,
    }
};

const grid = {
    x: {
        show: false
    },
    y: {
        show: true
    }
};

const legend = {
    show: false
};

interface IMapTrendChartProps {
    deviceId: number,
    source: string,
    parameter: MeasurableParameter
}

const MapTrendChart = (props: IMapTrendChartProps) => {
    const intl = useIntl();
    const initState = {
        x: 'x',
        xFormat: "%Y-%m-%d %H:%M:%S",
        types: {
            data: 'area-spline'
        },
        columns: [] as (string | number)[][],
        names: {
            data: intl.formatMessage({ id: `device.chart.parameter_${props.parameter}` }),
        },
        empty: {
            label: { text: intl.formatMessage({ id: "device.nodata" }) }
        }
    };

    const [data, setData] = useState({ ...initState });

    const loadData = () => {
        if (props.deviceId && props.parameter && props.source)
            DeviceDataAPI.fetchMapMarkerData(props.source, props.deviceId, props.parameter)
                .then((response: Response) => {
                    if (!response.ok)
                        return Promise.reject(response.statusText);
                    return response;
                })
                .then((response: Response) => response.json())
                .then((result: IDeviceMarkerData[]) => {
                    setData({
                        ...data,
                        columns: [
                            ['x'].concat(result.map(r => moment.utc(r.date).local().format(DateTimeSecFormat))),
                            ['data' as any].concat(result.map(r => r.value))
                        ],
                        names: {
                            data: intl.formatMessage({ id: `device.chart.parameter_${props.parameter}` }),
                        },
                    });
                });
    }

    useEffect(() => {
            loadData();
    }, [props.deviceId, props.parameter]);

    if (props.deviceId && props.parameter)
        return (
            <div className="map-trend-chart">
                <C3Chart data={data} axis={axis} grid={grid} legend={legend} />
            </div>
        );
    else
        return null;
};

export default MapTrendChart;
