import promiseDispatch from "./promiseDispatch";
import NotificationAPI from "../api/NotificationAPI";
import {INotificationPhoneConfirmation, INotificationPhoneNumber, 
    IRegisteredPhoneNumber, IRegisteredPhoneNumberForm, SmsNotificationActions} 
    from "../constants/SmsNotificationConstants";

export const SmsNotificationActionCreators = {
    savePhoneNumber: (model: INotificationPhoneNumber) => async (dispatch: any, getState: any) => {
        promiseDispatch<string>(
            dispatch,
            () => NotificationAPI.savePhoneNumber(model),
            {
                request: SmsNotificationActions.SAVE_PHONE,
                success: SmsNotificationActions.SAVE_PHONE_SUCCESS,
                failure: SmsNotificationActions.SAVE_PHONE_FAILED
            });
    },

    savePhoneNumberCode: (model: INotificationPhoneConfirmation) => async (dispatch: any, getState: any) => {
        promiseDispatch<object>(
            dispatch,
            () => NotificationAPI.savePhoneNumberCode(model),
            {
                request: SmsNotificationActions.SAVE_PHONE_CODE,
                success: SmsNotificationActions.SAVE_PHONE_CODE_SUCCESS,
                failure: SmsNotificationActions.SAVE_PHONE_CODE_FAILED
            });
    },

    loadPhoneNumbers: () => async (dispatch: any, getState: any) => {
        promiseDispatch<IRegisteredPhoneNumber[]>(
            dispatch,
            NotificationAPI.getRegisteredPhoneNumbers,
            {
                request: SmsNotificationActions.GET_REGISTERED_PHONE_NUMBERS,
                success: SmsNotificationActions.GET_REGISTERED_PHONE_NUMBERS_SUCCESS,
                failure: SmsNotificationActions.GET_REGISTERED_PHONE_NUMBERS_FAILED
            });
    },
    
    savePhoneNumbersActivity: (model: IRegisteredPhoneNumberForm[]) => async (dispatch: any, getState: any) => {
        promiseDispatch<object>(
            dispatch,
            () => NotificationAPI.savePhoneNumbersActivity(model),
            {
                request: SmsNotificationActions.SAVE_PHONE_NUMBERS_ACTIVITY,
                success: SmsNotificationActions.SAVE_PHONE_NUMBERS_ACTIVITY_SUCCESS,
                failure: SmsNotificationActions.SAVE_PHONE_NUMBERS_ACTIVITY_FAILED
            });
    },

    setPhoneNumber: (phoneNumber:string) => ({ type: SmsNotificationActions.SET_PHONE_NUMBER, payload: phoneNumber }),
    activePhoneNumber: (phoneNumber:string) => ({ type: SmsNotificationActions.ACTIVE_PHONE_NUMBER, payload: phoneNumber }),
    deletePhoneNumber: (phoneNumber:string) => ({ type: SmsNotificationActions.DELETE_PHONE_NUMBER, payload: phoneNumber }),
    setPhoneNumberCode: (code: string) => ({ type: SmsNotificationActions.SET_PHONE_NUMBER_CODE, payload: code }),
    displayRegulation: (show: boolean) => ({ type: SmsNotificationActions.DISPLAY_REGULATION, payload: show }),
    showPhoneDialog: () => ({ type: SmsNotificationActions.SHOW_PHONE_DIALOG }),
    showConfirmDialog: (phoneNumber:string) => ({type: SmsNotificationActions.SHOW_CONFIRM_DIALOG, payload: phoneNumber}),
    closePhoneDialog: () => ({ type: SmsNotificationActions.CLOSE_PHONE_DIALOG }),
}