import * as React from "react";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import "../../../less/DeviceDetailsLocation.css";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import UserContext, { IUserContext } from "../../UserContext";
import {useMemo, useRef} from "react";

interface IPosition {
    lat: number;
    lng: number;
}

interface IMapState {
    center: IPosition;
    marker: IPosition;
    zoom: number;
}

interface IMapDialogProps {
    latitude: string,
    longitude: string,
    handleClose: () => void,
    handleChange: (lat: number, lng: number) => void,
    show: boolean
}

const DefaultLocation: IPosition = { lat: 50.6437151, lng: 18.6382557 };

const GetPosition = (props: IMapDialogProps): IPosition => {
    let result = { ...DefaultLocation };
    if (Number(props.latitude) && Number(props.longitude))
        result = {
            lat: Number(props.latitude),
            lng: Number(props.longitude)
        };
    else if (navigator.geolocation)
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
            result = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
        });

    return result;
}

const MapDialog = (props: IMapDialogProps) => {
    const position = GetPosition(props);

    const [mapState, setMapState] = React.useState({
        center: {
            lat: position.lat,
            lng: position.lng,
        },
        marker: {
            lat: position.lat,
            lng: position.lng,
        },
        zoom: 13,
    } as IMapState);

    const refMarker = useRef(null);

    const updatePosition = () => {
        const marker = refMarker.current
        if (marker != null)
            setMapState({
                ...mapState,
                marker: marker.getLatLng()
            });
    }

    const handleButtonSave = () => {
        props.handleChange(mapState.marker.lat, mapState.marker.lng);
        props.handleClose();
    }

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                updatePosition();
            },
        }),
        [],
    )

    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        <Modal centered show={props.show} onHide={props.handleClose} className={"location-map " + userContext.style}>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id="device.edit.move-marker" /> <Button onClick={handleButtonSave}> <FormattedMessage id="device.edit.save" /> </Button> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MapContainer center={[mapState.center.lat, mapState.center.lng]} zoom={mapState.zoom} >
                    <TileLayer
                        attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                        draggable={true}
                        eventHandlers={eventHandlers}
                        position={[mapState.marker.lat, mapState.marker.lng]}
                        ref={refMarker}>
                        <Popup minWidth={90}>
                            <FormattedMessage id="device.edit.drag-drop" />
                        </Popup>
                    </Marker>
                </MapContainer>
            </Modal.Body>
        </Modal>
    );
};

export default MapDialog;