import {fetch} from "whatwg-fetch";
import {jsonHeader} from "../constants/Constants";
import {UserDeviceAccessForm} from "../constants/DeviceAccessControl/UserDeviceAccessForm";
import {DeviceModel} from "../constants/DeviceAccessControl/DeviceModel";
import {DeviceUsersModel} from "../constants/DeviceAccessControl/DeviceUsersModel";
import {UserDeviceRemoveForm} from "../constants/DeviceAccessControl/UserDeviceRemoveForm";
import {IActivationCode} from "../constants/ActivationCodeConstants";

export class DeviceAccessControlAPI {
    public static addAccessForDevices(model: UserDeviceAccessForm): Promise<Response> {
        return fetch("/api/device-access/", {
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader,
        });
    }

    public static removeUser(model: UserDeviceRemoveForm): Promise<Response> {
        return fetch(`/api/device-access/${model.deviceId}/users?email=${model.email}`, {
            method: "DELETE"
        });
    }
    public static async fetchDeviceUsers(): Promise<DeviceUsersModel[]> {
        let r = await (fetch("/api/device-access") as Promise<Response>);
        return await r.json() as DeviceUsersModel[];
    }
    public static async fetchDevices(): Promise<DeviceModel[]> {
        let r = await (fetch("/api/device-access/devices") as Promise<Response>);
        return await r.json() as DeviceModel[];
    }
}