import {IEditedDevice} from "constants/DeviceConstants";
import {FormattedMessage, useIntl} from "react-intl";
import * as React from "react";
import {Container, Button, Col, Row, Modal} from "react-bootstrap";
import UserContext, {IUserContext} from "components/UserContext";
import {IParameterSettings} from "constants/Constants";
import ParameterSettings, {ValidateParameterSettings} from "components/Device/DeviceParameters/ParameterSettings";
import "../../../less/DeviceParametersSettings.css";
import "../../../less/DeviceDetailsThresholds.css";
import "../../../less/DeviceDetailsOffsets.css";

export const ValidateDeviceParametersSettings = (editForm: IEditedDevice): boolean => ValidateParameterSettings(editForm);

const DeviceParametersSettings = (
    props: {
        editForm: IEditedDevice,
        editFormEvent: (editedForm: IEditedDevice) => void,
    }
) => {
    const intl = useIntl();
    const [dialog, showDialog] = React.useState(false);

    const handleClose = () => {
        if(ValidateDeviceParametersSettings(props.editForm))
            showDialog(false);
    }

    const handleShow = () => showDialog(true);
    const handleSensorChange = (parameter: IParameterSettings) => {
        const idx = props.editForm.parameterSettings.findIndex(p=> p.parameterId === parameter.parameterId);
        props.editForm.parameterSettings[idx] = parameter;
        const newState = { ...props.editForm, parameterSettings: [ ... props.editForm.parameterSettings] };
        props.editFormEvent(newState);
    }

    const userContext = React.useContext<IUserContext>(UserContext);

    return (
        <span className={"device-parameter-settings " + userContext.style}>
             <button className="btn sensors-button" onClick={handleShow}>
                <span className="material-icons">wysiwyg</span> <FormattedMessage id="device.edit.sensors" />
            </button>
            {dialog
                ?  <Modal centered show={dialog} onHide={handleClose} className={"device-parameter-settings " + userContext.style} dialogClassName="sensor-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="device.edit.sensors" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{height:"40px", textAlign:"center"}} >
                            <Col xs={1}> &nbsp; </Col>
                            <Col xs={3}> <FormattedMessage id="device.edit.parameter.name" />  </Col>
                            <Col xs={1}>
                                <span title={intl.formatMessage({id: "device.edit.parameter.visibility.description"})} style={{whiteSpace:"nowrap"}}>
                                    <FormattedMessage id="device.edit.parameter.visibility" />
                                    <i className={"info-mark " + userContext.style + " material-icons"}> info </i>
                                </span>
                            </Col>
                            <Col xs={1} >
                                 <span title={intl.formatMessage({id: "device.edit.parameter.offsets.description"})} style={{whiteSpace:"nowrap"}}>
                                    <FormattedMessage id="device.edit.parameter.offsets" />
                                    <i className={"info-mark " + userContext.style + " material-icons"}> info </i>
                                </span>
                            </Col>
                            <Col xs={6}> <FormattedMessage id="device.edit.parameter.thresholds" /> </Col>
                        </Row>
                        {(props.editForm.parameterSettings || []).map(p =>
                            <ParameterSettings key={`sensor_${p.parameterId}`} parameter={p} handleSensorChange={handleSensorChange}/>
                        )}
                        <Button onClick={handleClose}> <FormattedMessage id="device.edit.close" /> </Button>
                    </Modal.Body>
                </Modal>
                : null}
        </span>
    );
}

export default DeviceParametersSettings;