import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {Navigate, useSearchParams} from "react-router-dom";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IResetPasswordModel } from "../../constants/LoginConstants";
import { IResult } from "../../constants/Constants";
import UserContext, { IUserContext } from "../UserContext";

import InfoMenu from '../InfoMenu';
import Spinner from "../Spinner";
import FooterLinks from "./FooterLinks";

import "../../less/Login.css";
interface IResetPasswordForm extends IResetPasswordModel, IResult { }

const ResetPassword = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let [code, setCode] = React.useState(
        searchParams.get("code")
    );
    let [userId, setUserId] = React.useState(
        searchParams.get("userId")
    );

    const userContext = React.useContext<IUserContext>(UserContext);
    const [model, setModel] = React.useState({ userId, code, email: "", password: "", confirmPassword: "", isSuccessed: false, message: null } as IResetPasswordForm);

    const submitForm = () => {
        setModel({ ...model, message: "loading" });
        UserAccountAPI.resetPassword(model)
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.json())
            .then((result: IResult) => {
                setModel({ ...model, isSuccessed: result.isSuccessed, message: result.message });
            })
            .catch((r: Response) => r.json())
            .then((r: string) => { if (r) { setModel({ ...model, isSuccessed: false, message: "page.resetpassword.error" }); } });
    };

    return (
        <div className={"forgot-password " + userContext.style}>
            <InfoMenu />
            <div className="wrapper fadeInDown">
                <div className={"logo-box " + userContext.applicationName} />
                <div className="formContent">
                    {(model.message === "loading"
                        ? <Spinner />
                        : <Form>
                            <Form.Label><FormattedMessage id="email.reset.account.title" /></Form.Label>
                            <Form.Control.Feedback type={model.isSuccessed ? "valid" : "invalid"} className="login-message" hidden={model.message == null}>
                                {(model.message != null ? <FormattedMessage id={model.message} /> : null)}
                            </Form.Control.Feedback>
                            {model.isSuccessed === false ?
                                <>
                                    <Form.Group controlId="email">
                                        <Form.Label><FormattedMessage id="reset.password.email" /></Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            value={model.email}
                                            onChange={(e: React.FormEvent<any>) => setModel({ ...model, email: (event.target as any).value })} />
                                    </Form.Group>
                                    <Form.Group controlId="formNewPassword">
                                        <Form.Label><FormattedMessage id="page.manage.newPassword" /></Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={model.password}
                                            onChange={(event: React.FormEvent<any>) => setModel({ ...model, password: (event.target as any).value })} />
                                    </Form.Group>
                                    <Form.Group controlId="formConfirmPassword">
                                        <Form.Label><FormattedMessage id="page.manage.confirmNewPassword" /></Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={model.confirmPassword}
                                            onChange={(event: React.FormEvent<any>) => setModel({ ...model, confirmPassword: (event.target as any).value })} />
                                    </Form.Group>
                                    <Button variant="success" disabled={!(model.email && model.password && model.confirmPassword && model.password === model.confirmPassword)} onClick={submitForm}>
                                        <FormattedMessage id="forgot.password.resetpassword" />
                                    </Button>
                                </> : <Navigate to="/login" />
                            }
                        </Form>
                    )}
                </div>
                <FooterLinks />
            </div>
        </div>
    );
};

export default ResetPassword;

