import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import "../../less/AccessControl/DeviceForm.css"
import SubmitButton from "../SubmitButton";
import {FormattedMessage, useIntl} from "react-intl";
import {Alert, Spinner} from "react-bootstrap";

export type AddUserFrom = {
    email: string,
    devices: Device[],
    isLoaded: boolean,
    isError: boolean,
}

export type Device = {
    deviceId: number,
    deviceName: string,
    checked: boolean,
}

type DeviceFormProps = {
    userForm: AddUserFrom,
    selectDevices: (deviceIds: number[], checked: boolean) => void;
    selectUserEmail: (email: string) => void;
    saveForm: () => void;
}

function validateEmail(email: string): boolean {
    // Regular expression to check if email is valid
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}


export const DeviceForm = (props: DeviceFormProps) => {
    const intl = useIntl();
    const [validatedCheckList, setValidatedForm] = useState(false);
    const [validatedEmail, setValidatedEmail] = useState(true);
    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const isValid = validateEmail(email);
        props.selectUserEmail(email);
        setValidatedEmail(isValid);
    }

    useEffect(() => {
        setValidatedForm(props.userForm.devices.filter(d => d.checked).length > 0);
    }, [props.userForm.devices]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.selectDevices([Number(event.currentTarget.id)], event.currentTarget.checked);
    }
    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) =>
        props.selectDevices(props.userForm.devices.map(x => x.deviceId), event.currentTarget.checked);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (validatedEmail && validatedCheckList)
            props.saveForm();
    };

    if (props.userForm.isLoaded)
        return (
            <div className="device-form">
                <Form noValidate={true} onSubmit={handleSubmit}>
                    <Form.Check
                        checked={props.userForm.devices.every(d => d.checked)}
                        onChange={handleChangeAll}
                        type="checkbox"
                        id={"select-all"}
                        label={<b><FormattedMessage id="page.access-control.devices"/></b>}
                    />
                    <div className="device-choose-list">
                        {
                            props.userForm.devices.length > 0 ?
                                props.userForm.devices.map(d => (
                                    <Form.Check
                                        key={`device-${d.deviceId}`}
                                        checked={d.checked}
                                        onChange={handleChange}
                                        type="checkbox"
                                        id={d.deviceId.toString()}
                                        label={d.deviceName}
                                    />))
                        : (<i style={{color:"grey"}}>
                                    <FormattedMessage id={"page.access-control.devices.no-devices"} />
                        </i>)
                        }
                    </div>
                    <hr style={{margin: "5px 0 5px 0"}}/>
                    <Form.Label>
                        <b><FormattedMessage id="page.access-control.role"/></b>
                    </Form.Label>
                    <Form.Check
                        key="role-reader"
                        defaultChecked={true}
                        type="radio"
                        id={"reader"}
                        label={<FormattedMessage id="page.access-control.role.reader"/>}
                    />
                    <hr style={{margin: "5px 0 5px 0"}}/>
                    <Form.Group className="mb-3" controlId="user.input">
                        <Form.Label title={intl.formatMessage({id:"page.access-control.user.description"})}>
                            <b><FormattedMessage id="page.access-control.user"/></b>
                        </Form.Label>
                        <Form.Control
                            isInvalid={!validatedEmail}
                            type="email"                          
                            placeholder={intl.formatMessage({id:"page.access-control.user.example-email"})}
                            value={props.userForm?.email ?? ""}
                            onChange={onEmailChange}/>
                    </Form.Group>
                    <div>
                        <SubmitButton variant="primary"
                                      disabled={!(validatedEmail && validatedCheckList && props.userForm.email)}>
                            <FormattedMessage id="page.access-control.user.add"/>
                        </SubmitButton>
                        {props.userForm.isError ?
                            (<div className="user-add-error">
                                <FormattedMessage id="page.access-control.user.add-error"/>
                            </div>)
                            : null
                        }
                    </div>
                </Form>
            </div>);

    return (<div className="device-form" style={{textAlign: "center"}}>
        <Spinner animation="grow"/>
    </div>)
}