import { IUserContext, UserInfoActions } from "components/UserContext";

export interface IUserContextStore extends IUserContext {
    message: string;
}

const initialState: IUserContextStore = { isAdmin: false, isSupervisor: false, isAuthenticated: false, message: "Initial" } as IUserContextStore;

export const reducer = (state: IUserContextStore, action: any): IUserContextStore => {
    state = state || initialState;
    switch (action.type) {
        case UserInfoActions.FETCH_USER:
            return { ...state, message: "Loading" };
        case UserInfoActions.FETCH_USER_SUCCESS:
            return { ...action.payload, message: null };
        case UserInfoActions.FETCH_USER_FAILED:
            return { ...initialState, message: "error" };
        case UserInfoActions.CHANGE_LEFT_PANEL_STATE:
            return { ...state, isLeftMenuExpanded: action.payload };
        default:
            return state;
    }
}