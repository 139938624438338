import * as React from "react";
import {createRef, useContext, useEffect, useState} from "react";
import * as d3 from "d3";
import {PlannerTooltip} from "./PlannerTooltip";
import {useNavigate} from "react-router-dom";
import {EnumDictionary, IPlannerDevice, PlannerDeviceStatus, TooltipMode} from "../../constants/PlannerDeviceConstants";
import {SvgContext} from "./PlannerCanvas";


const colorMap: EnumDictionary<PlannerDeviceStatus, string> = {
    [PlannerDeviceStatus.Normal]: "#77D141",
    [PlannerDeviceStatus.High]: "#FF5454",
    [PlannerDeviceStatus.Low]: "#67C2EF",
    [PlannerDeviceStatus.Undefined]: "#E0E0E0"
};

export const DeviceCircle = (props: {
    device: IPlannerDevice;
    removeDeviceAction: (device: IPlannerDevice) => void,
    activeDeviceAction: (deviceId: number) => void,
    moveDeviceAction: (deviceId: number, x: number, y:number) => void,
    tooltipEnabled: boolean
}) => {
    const [tooltipMode, setTooltipMode] = useState(TooltipMode.Hidden);
    const [parentRef, width, height] = useContext(SvgContext);
    
    const navigate = useNavigate();
    
    const svgRef = createRef<any>();

    let timeoutId: NodeJS.Timeout = null;
    const tryShowTooltip = (x: number, y: number, width: number) => {
        if(!props.tooltipEnabled)
            return;
        
        if (x > 90 && x < width - 90) {
            if (y < 85)
                setTooltipMode(TooltipMode.Down);
            else
                setTooltipMode(TooltipMode.Up);
        } else {
            if (x < 90)
                setTooltipMode(TooltipMode.Right);
            else
                setTooltipMode(TooltipMode.Left);
        }
        props.activeDeviceAction(props.device.deviceId);
    }
    const hideTooltip = () => {
        setTooltipMode(TooltipMode.Hidden);
    }
    useEffect(()=>{
        if(!props.tooltipEnabled){
            hideTooltip();
            if (timeoutId)
                clearTimeout(timeoutId);
        }
    }, [props.tooltipEnabled]);

    useEffect(() => {
        const handleDrag = d3.drag()
            .on('drag', function (event: any) {
                let x = props.device.x;
                let y = props.device.y;
                
                if (event.x > 0 && event.x < width)
                    x = event.x;
                if (event.y > 0 && event.y < height)
                    y = event.y;
                props.moveDeviceAction(props.device.deviceId, x, y);
                tryShowTooltip(x, y, width);
            });

        handleDrag(d3.select(svgRef.current));

        d3.select(svgRef.current)
            .on("mouseover", function (_: MouseEvent) {
                if (timeoutId)
                    clearTimeout(timeoutId);
                tryShowTooltip(props.device.x ?? width/2, props.device.y ?? height/2, width);
            })
            .on("mouseout", function (_: MouseEvent) {

                props.activeDeviceAction(null);
               
                timeoutId = setTimeout(function () {
                    hideTooltip();
                }, 2000);
            })
            .on("click", function (_: MouseEvent) {
                navigate(`/device/${props.device.deviceId}`);
        });
    }, [props.device]);

    const removeDeviceAction = () => props.removeDeviceAction(props.device);

    const defaultY = height / 2;
    const defaultX = width / 2;

    return (
        <g id={`object_${props.device.deviceId}`} ref={svgRef}>
            <circle id={`circle_${props.device.deviceId}`} 
                    cx={props.device.x ?? defaultX} 
                    cy={props.device.y ?? defaultY}
                    r={14}
                    fill={colorMap[props.device.status]}
                    stroke="black" strokeWidth="2"
                    name={props.device.name} 
                    style={{cursor: "pointer"}}>
            </circle>
            <PlannerTooltip
                x={props.device.x ?? defaultX}
                y={props.device.y ?? defaultY}
                device={props.device}
                mode={tooltipMode}
                removeDeviceAction={removeDeviceAction}/>
        </g>
    );
}