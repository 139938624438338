import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Popup} from "react-leaflet";
import { GetDateTime } from "constants/Common";
import { getParameterIcon } from "constants/Common";
import { IDeviceMarker, MarkerType } from "constants/MapConstants";
import QualityIndex from "../Smog/QualityIndex";
import MapTrendChart from "./MapTrendChart";

const dateHeader = (marker: IDeviceMarker) => (<div className="date-header">{GetDateTime(marker.readingDate)}</div>);

const header = (marker: IDeviceMarker) => {
    let content = null;
    if (marker.deviceId && marker.isMine)
        content = (<a href={`/Device/${marker.deviceId}`}><b>{marker.name}</b></a>);
    else if (marker.source === "GIOS")
        content = (<a href="https://www.gios.gov.pl/pl/">(GIOŚ) <b>{marker.name}</b></a>);
    else
        content = (<a href="https://oferta.ipomiar.pl"><b>{marker.name}</b></a>);

    return (<div>{content}</div>);
}

const MarkerPopup = (props: IDeviceMarker) => {
    const [parameterId, setParameterId] = useState((props.values || []).length > 0 ? props.values[0].parameterId : null);

    return (
        <Popup keepInView={true}>
            <div className="marker-popup">
                {dateHeader(props)}
                <div className="title">{header(props)}</div>
                {props.markerType === MarkerType.Smog
                    ? (<div className="marker-popup-row not-clickable">
                        <span className="name"><FormattedMessage id="smog.quality-index" /></span>
                        <div className="quality-index"><QualityIndex value={props.qualityIndex} /></div>
                    </div>)
                    : null}

                {(props.values
                    ? props.values.map(v => (
                        <div className={"marker-popup-row" + (parameterId === v.parameterId ? " selected" : "")} key={props.name + v.parameterId} onClick={() => setParameterId(v.parameterId)}>
                            <span className={"icon " + getParameterIcon(v.parameterId)} />
                            <span className="name"><FormattedMessage id={`device.chart.parameter_${v.parameterId}`} /></span>
                            <span className="value"><b>{v.value !== null ? v.value.toFixed(0) : null}</b><span className="unit"><FormattedMessage id={`device.chart.parameter.unit_${v.parameterId}`} /></span></span>
                        </div>))
                    : <FormattedMessage id="device.nodata" />
                )}
                <MapTrendChart deviceId={props.deviceId} source={props.source} parameter={parameterId} />
            </div>
        </Popup>
    );
}

export default MarkerPopup;