import * as React from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Navigate, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { UserActionCreators } from "actions/UserActionCreators";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IExternalLoginConfirmationModel } from "constants/LoginConstants";
import "../../less/Login.css";
import { IAppStore } from "store/configureStore";
import { IUserContextStore } from "store/UserInfoStore";
import InfoMenu from '../InfoMenu';
import RodoStatemant from "./Rodo";
import Spinner from "../Spinner";

interface IRegistrationModel {
    loginProvider: string;
    email: string;
    errorMessage: string;
    isAuthenticated: boolean;
}

interface IExternalLoginProps extends IUserContextStore {
    fetchUserInfo: () => void;
}

const ExternalLogin = (props: IExternalLoginProps) => {
    const [model, setModel] = React.useState<IRegistrationModel>({ loginProvider: null, email: null, errorMessage: null, isAuthenticated: false } as IRegistrationModel);

    const submitAccount = () => {
        const data = {
            email: model.email,
            loginProvider: model.loginProvider,
            termOfServiceAcceptance: true
        } as IExternalLoginConfirmationModel;

        UserAccountAPI.externalLoginConfirmation(data)
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.json())
            .then((result: IRegistrationModel) => {
                if (result.isAuthenticated)
                    props.fetchUserInfo();

                setModel({
                    ...model,
                    errorMessage: result.errorMessage,
                    isAuthenticated: result.isAuthenticated
                });
            });
    }

    React.useEffect(() => {
        UserAccountAPI.externalLogin()
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.json())
            .then((data: IRegistrationModel) => {
                setModel(data);
                if (data.isAuthenticated)
                    props.fetchUserInfo();

            })
    }, []);

    if (props.isAuthenticated)
        return (<Navigate to='/main' />);

    if (props.message === "Loading" || (model.errorMessage === null && model.email === null))
        return (<Spinner />);

    return (
        <div className={"register " + props.style}>
            <InfoMenu />
            {model.errorMessage === null
                ? (<div className="wrapper fadeInDown">
                    <div className={"logo-box " + props.applicationName} />
                    <Container>
                        <Row>
                            <Col className="formContent" xs={12} md={6}>
                                <Form>
                                    <h3><FormattedMessage id="page.registration.title" /></h3>
                                    <br />
                                    <Form.Label style={{textAlign:"left"}}><FormattedMessage id="page.login.external.authenticated" /> {" "}
                                        <strong>{model.loginProvider}</strong>{". "}
                                        <FormattedMessage id="page.login.external.register" />{"."}
                                    </Form.Label>
                                    <br/>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control
                                            type="email"
                                            readOnly={true}
                                            placeholder="Email"
                                            value={model.email}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formTermOfService">
                                        <Form.Check
                                            type="checkbox"
                                            label={
                                                <div style={{ marginTop: "-5px" }}>
                                                    <Link to="/tos" style={{ color: "white" }}>
                                                        <FormattedMessage id="termOfService.acceptance" />
                                                        <i className="material-icons" style={{ position: "relative", left: "2px", top: "5px" }}>assignment_turned_in</i>
                                                    </Link>
                                                </div>}
                                            checked={true}
                                        />
                                    </Form.Group>
                                    <Button variant="success" type="button" onClick={submitAccount}>
                                        <FormattedMessage id="page.registration.button" />
                                    </Button>
                                </Form>
                            </Col>
                            <Col className="statement" xs={12} md={6}>
                                <RodoStatemant/>
                            </Col>
                        </Row>
                    </Container>
                </div>)
                : (<div className="model-error">
                    <Alert variant="danger">
                        <FormattedMessage id={model.errorMessage} defaultMessage={model.errorMessage} />
                    </Alert>
                </div>)
            }
        </div>
    );
};

export default connect(
    (state: IAppStore) => state.userInfo,
    (dispatch: any) => bindActionCreators(UserActionCreators, dispatch)
)(ExternalLogin);

