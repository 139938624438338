import * as React from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { SamplingMap } from "constants/Common";
import { IDeviceDetails } from "constants/DeviceConstants";
import ChangeStateSign from "./ChangeStateSign";

const DeviceDetailsSampling = (
    props : {
        device: IDeviceDetails
    }) => {

        return (
            <Col xs={12} md={3} sm={4}>
                <span><FormattedMessage id="device.edit.sampling" />&nbsp;</span>
                <span>{SamplingMap(props.device.previousSampling ? props.device.previousSampling : props.device.sampling)}{
                    props.device.previousSampling ?
                        <span className="new-sampling">({SamplingMap(props.device.sampling)})</span>
                        : null
                }</span>
                <span className="sampling">
                    <ChangeStateSign hide={props.device.previousSampling === null} />
                </span>
            </Col>
        );
}

export default DeviceDetailsSampling;