import { divIcon } from 'leaflet';
import * as React from "react";
import { useEffect } from 'react';
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { CircleMarker, MapContainer, Marker, TileLayer } from 'react-leaflet';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { MapActionCreators } from "actions/MapActionCreators";
import { AccessType, IDeviceMarker, MarkerType } from "constants/MapConstants";
import '../../less/Map.css';
import { IAppStore } from "store/configureStore";
import Spinner from "../Spinner";
import UserContext, { IUserContext } from '../UserContext';
import BreathBoxLogo from './BreathBoxLogo';
import MarkerPopup from './MarkerPopup';

import {
    useLocation
} from "react-router-dom";

interface IMapComponentProps {
    markers: IDeviceMarker[],
    mapCenter: [number, number],
    bounds: [number, number][],
    fetchMapMarkers?: () => void,
    setFetchedStatus?: (status: boolean) => void,
    message: string;
}

const markerClassName = (marker: IDeviceMarker): string => {
    let result = "undefined";

    if (marker.markerType === MarkerType.Standard) {
        if (marker.offNormal)
            result = "offnormal";
        if (marker.offNormal === false)
            result = "normal";
        return "map-marker " + result;
    }

    return "map-marker " + result;
}

const isSmogDevice = (marker: IDeviceMarker): boolean => marker.markerType === MarkerType.Smog;


const MapComponent = (props: IMapComponentProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const [showExternalsFlag, setShowExternalsFlag] = React.useState(!userContext.isAuthenticated);
    const location = useLocation();
    useEffect(() => {
        props.fetchMapMarkers()
    }, []);

    const hasNoDevice = (props.markers || []).length === 0;
    const hasSmogDevice = (props.markers || []).filter(m => m.accessType === AccessType.Private && isSmogDevice(m)).length > 0;
    const handleExternalsFlagChange = (value: boolean) => {
        setShowExternalsFlag(value);
    }

    const getIcon = (m: IDeviceMarker) => divIcon({
        className: `icon-marker quality-${m.qualityIndex}`,
        iconSize: [30, 30],
        html: '<div class="marker-dot"></div><div class="blur-icon-marker"></div>'
    });

    const renderMap = () => {
        const queryParams = queryString.parse(location.search);
        const initMarker = (ref: any) => {
            if (ref) {
                window.setTimeout(() => {
                    ref.openPopup();
                }, 200);
            }
        }

        return (
            <React.Fragment>
                <MapContainer
                    bounds={props.bounds}
                    boundsOptions={{ padding: [50, 50] }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution={`&copy; <a href="/main">${userContext.applicationName}</a>`}
                    />
                    {(props.markers || []).filter(m => showExternalsFlag || m.accessType === AccessType.Private).map(m => {
                        return isSmogDevice(m)
                            ? <Marker ref={ queryParams && m.publicToken === queryParams["publickey"] ? initMarker : null}
                                      icon={getIcon(m)} position={[m.latitude, m.longitude]} key={"marker-" + m.name + m.deviceId}>
                                <MarkerPopup {...m} />
                              </Marker>
                            : <CircleMarker
                                center={[m.latitude, m.longitude]}
                                key={"marker-" + m.name + m.deviceId}
                                radius={4}
                                weight={2}
                                
                                fillOpacity={1.0}
                                className={markerClassName(m)}>
                                    <MarkerPopup {...m} />
                            </CircleMarker>;
                    })}
                </MapContainer>
                {
                    userContext.isAuthenticated && userContext.smogMapEnabled && hasSmogDevice
                        ? <div className="external-source-switch">
                            <ToggleButtonGroup name="external-source-switch" type="radio" value={showExternalsFlag} onChange={handleExternalsFlagChange}>
                                <ToggleButton value={1} id="external-source-switch-btn-1" checked={showExternalsFlag} ><FormattedMessage id="map.source.public" /></ToggleButton>
                                <ToggleButton value={0} id="external-source-switch-btn-2" checked={!showExternalsFlag}><FormattedMessage id="map.source.private" /></ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        : null}

                {
                    (props.message === null || props.message === undefined) && hasNoDevice
                        ? (<div className="map-no-points alert alert-info" > <FormattedMessage id="map.no-points.message" /></div>)
                        : null
                }
                {
                    !userContext.isAuthenticated
                        ? (<div className="logo-map"><BreathBoxLogo /></div>)
                        : null
                }
            </React.Fragment>
        );};



    return (props.message === "Loading"
        ? <Spinner />
        : renderMap()
    );
}

function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        markers: state.map.devices,
        mapCenter: state.map.mapCenter,
        bounds: state.map.bounds,
        message: state.map.message
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(MapActionCreators, dispatch)
)(MapComponent);
