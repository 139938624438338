import * as React from "react";
import { Col, FormGroup } from "react-bootstrap";
import Radio from "../../../controles/Radio";
import { FormattedMessage } from "react-intl";
import { OperatingMode } from "constants/Constants";
import { OperationModeMap } from "constants/Common";
import ChangeStateSign from "./ChangeStateSign";
import { IDeviceDetails, IEditedDevice } from "constants/DeviceConstants";

const DeviceDetailsOperatingMode = (
    props :{
        editForm: IEditedDevice,
        device: IDeviceDetails,
        handleInputChange: (event: React.FormEvent<any>) => void
    }
) => {
    return (
        <FormGroup controlId="formHorizontalActiveOption" className="row mode">
            <Col className="label" md={3} sm={3} xs={12}>
                <FormattedMessage id="device.edit.operatingMode" />
                <ChangeStateSign hide={props.device.previousOperatingMode === null
                    && props.device.operatingMode === Number(props.editForm.operatingMode)
                    || props.device.previousOperatingMode === Number(props.editForm.operatingMode)} />
            </Col>
            <Col md={8} sm={8} xs={12}>
                <Radio name="operatingMode" value={OperatingMode.Standard}
                    checked={Number(props.editForm.operatingMode) === Number(OperatingMode.Standard)}
                    onChange={props.handleInputChange}>
                    <FormattedMessage id={OperationModeMap(OperatingMode.Standard)} />
                </Radio>
                <Radio name="operatingMode" value={OperatingMode.Control}
                    checked={Number(props.editForm.operatingMode) === Number(OperatingMode.Control)}
                    onChange={props.handleInputChange}>
                    <FormattedMessage id={OperationModeMap(OperatingMode.Control)} />
                </Radio>
            </Col>
        </FormGroup>
     );
}

export default DeviceDetailsOperatingMode;