import * as React from "react";
import {FormControl} from "react-bootstrap";
import InputRange, {Range} from "react-input-range";
import {useEffect, useState} from "react";

export interface IThresholdRangeProps {
    maxValue: number;
    minValue: number;
    formatLabel: (value: number, type: string) => string;
    onChange: (value: Range | number) => void;
    thresholdMin?: number;
    thresholdMax?: number;
}

const toString = (value: number): string => value.toLocaleString("en-US", {
    style: 'decimal',
    useGrouping: false
});


const getMinValue = (props: IThresholdRangeProps): number => props.thresholdMin !== null ? (props.thresholdMin) : props.minValue;
const getMaxValue = (props: IThresholdRangeProps): number => props.thresholdMax !== null ? (props.thresholdMax) : props.maxValue;

const switchValuesIfNeeded = (minValue: number, maxValue: number): [number, number] => minValue > maxValue ? [maxValue, minValue] : [minValue, maxValue];
const ThresholdRange = (props: IThresholdRangeProps): JSX.Element => {
    const [minValueValidation, setMinValueValidation] = useState(true);
    const [maxValueValidation, setMaxValueValidation] = useState(true);

    const [minValue, maxValue] = switchValuesIfNeeded(getMinValue(props), getMaxValue(props));
    const [minValueText, setMinValueText] = useState(toString(minValue));
    const [maxValueText, setMaxValueText] = useState(toString(maxValue));

    useEffect(() => {
        const [minValue, maxValue] = switchValuesIfNeeded(getMinValue(props), getMaxValue(props));
        const minValueStr = toString(minValue);
        const maxValueStr = toString(maxValue);
        if (minValueStr !== minValueText) setMinValueText(minValueStr);
        if (maxValueStr !== maxValueText) setMaxValueText(maxValueStr);
    }, [props.thresholdMin, props.minValue, props.thresholdMax, props.maxValue]);
    const changeValue = (value: number, type: "min" | "max"): { min: number, max: number } => {
        
        if (type === "min")
            return {min: (value), max: value < maxValue ? maxValue : value}
        else
            return {min: value > minValue ? minValue : value, max: (value)}
    }

    const trimValueToLimits = (value:number): number => value > props.maxValue
        ? props.maxValue
        : value < props.minValue
            ? props.minValue
            : value;
    const minThresholdKeyDownEvent = (event: any) => thresholdKeyDownEvent(event, "min");
    const maxThresholdKeyDownEvent = (event: any) => thresholdKeyDownEvent(event, "max");

    const thresholdKeyDownEvent = (event: any, type: "min" | "max") => {
        let value = getValue(event);
        if (value === null)
            return;
        if (event.keyCode === 13)
            props.onChange(changeValue(value, type));
        else if (event.keyCode === 38)
            props.onChange(changeValue(value + 1, type));
        else if (event.keyCode === 40)
            props.onChange(changeValue(value - 1, type));
        else if (event.keyCode === 189)
            props.onChange(changeValue(-value, type));
        else if ((event.keyCode === 46 || event.keyCode === 8) && Math.abs(value) < 10)
            props.onChange(changeValue(0, type));
    }

    const maxThresholdInputChange = (event: any) => thresholdInputChange(event, "max", false);
    const minThresholdInputChange = (event: any) => thresholdInputChange(event, "min", false);
    const minThresholdInputLeave = (event: any) => thresholdInputChange(event, "min", true);
    const maxThresholdInputLeave = (event: any) => thresholdInputChange(event, "max", true);

    const getValue = (event: any): number => {
        const strValue = event.target.value;
        if (isNumeric(strValue))
            return Number(strValue);
        return null;
    }

    function isNumeric(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const thresholdInputChange = (event: any, type: "min" | "max", applyChanges: boolean) => {
        let value = getValue(event);
        const isNumber = value !== null;
        
        if(isNumber)
            value = trimValueToLimits(value);

        if (type === "min") {
            if(isNumber) 
                setMinValueText(toString(value));
            setMinValueValidation(isNumber);
        } else {
            if(isNumber)
                setMaxValueText(toString(value));
            setMaxValueValidation(isNumber);
        }

        if (applyChanges && isNumber)
            props.onChange(changeValue(value, type));

    };
    const rangeMaxValue = props.maxValue >= props.minValue ? props.maxValue : props.minValue;
    const rangeMinValue = props.maxValue >= props.minValue ? props.minValue : props.maxValue;
    return (
        <div className="input-range-container">
            <div className="text-box">
                <FormControl type="text"
                             autoComplete="off"
                             name="minThreshold"
                             value={minValueText}
                             onChange={minThresholdInputChange}
                             onBlur={minThresholdInputLeave}
                             onKeyDown={minThresholdKeyDownEvent}
                             isInvalid={!minValueValidation}
                />
            </div>
            <div className="slider-box">
                <InputRange
                    maxValue={rangeMaxValue}
                    minValue={rangeMinValue}
                    formatLabel={props.formatLabel}
                    value={{min: minValue, max: maxValue}}
                    onChange={props.onChange}/>
            </div>
            <div className="text-box">
                <FormControl type="text"
                             autoComplete="off"
                             name="maxThreshold"
                             value={maxValueText}
                             onChange={maxThresholdInputChange}
                             onBlur={maxThresholdInputLeave}
                             onKeyDown={maxThresholdKeyDownEvent}
                             isInvalid={!maxValueValidation}
                />
            </div>
        </div>
    );
};

export default ThresholdRange;