import * as React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { LinkContainer } from 'react-router-bootstrap';
import "../../less/Welcome.css";

const Welcome = () => {
    return (
        <div className="dashboard-welcome">
            <Modal.Dialog>
                <Modal.Body>
                    <div className="title"> <FormattedMessage id="welcome.header"/> </div>
                    <div className="text"> <FormattedMessage id="welcome.text" values={{
                        contact: <a href="/Contact"><FormattedMessage id="contact" /></a>,
                        about: <a href="/About"><FormattedMessage id="about" /></a>
                    }}
                    /></div>
                    <div className="text-center">
                        <LinkContainer to="/register-device">
                            <div className="welcome-button btn btn-success" title="Add Device">
                                <i className="material-icons">add_circle</i> <FormattedMessage id="register.device" defaultMessage="Register Device" />
                            </div>
                        </LinkContainer>
                    </div>
                </Modal.Body>
            </Modal.Dialog>
        </div>
    );
}

export default Welcome;