import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
const DeviceDetailsFormControl = (props: {
    children: React.ReactNode,
    className?: string,
    left: number,
    right?: number,
    titleId?: string,
    labelId: string
    validated?: boolean
}) => {
    const intl = useIntl();
    if (props.right)
        return (
            <Form noValidate validated={props.validated} className={props.className}>
                <Row>
                    <Col md={props.left} sm={props.left} xs={12} className="label">
                        <span title={props.titleId ? intl.formatMessage({ id: props.titleId }) : null}>
                            <FormattedMessage id={props.labelId} /> {(props.titleId ? <i className="material-icons">info</i> : null)} </span>
                    </Col>
                    <Col xs={12} sm={props.right} md={props.right} className="content">
                        {props.children}
                    </Col>
                </Row>
            </Form>
        );

    return (
        <Form noValidate validated={props.validated} className={props.className}>
            <Row>
                <Col md={props.left} sm={props.left} xs={12}>
                    <div className="label">
                        <span title={props.titleId ? intl.formatMessage({ id: props.titleId }) : null}>  <FormattedMessage id={props.labelId} /> {(props.titleId ? <i className="material-icons info-box">info</i> : null)} </span>
                    </div>
                    {props.children}
                </Col>
            </Row>
        </Form>
    );
}
export default DeviceDetailsFormControl;