import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";


const FooterLinks = () => (    
    <div className="formFooter">
        <div className="link-container">
            <Link className="underlineHover" to="/login"><FormattedMessage id="page.login.form" /></Link>
        </div>
        <div className="link-container">
            <Link className="underlineHover" to="/registration"><FormattedMessage id="page.login.gotoRegister" /></Link>
        </div>
    </div>);

export default FooterLinks;