import { INotificationEmailAddress } from "constants/EmailNotificationConstants";
import * as React from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IAppStore } from "store/configureStore";
import "../../less/Notification.css";
import { IEmailNotification } from "store/EmailNotificationStore";
import UserContext, { IUserContext } from "../UserContext";
import SubmitButton from "../SubmitButton";
import {EmailNotificationActionCreators} from "../../actions/EmailNotificationActionCreators";

interface IEmailRegistrationDialogProps extends IEmailNotification {
    saveEmail: (model: INotificationEmailAddress) => void;
    closeEmailDialog: () => void;
    setEmail: (email: string) => void;
}

const EmailRegistrationDialog = (props: IEmailRegistrationDialogProps) => {

    const sanitizeEmail = (email: string): string => email ? email.trim().toLowerCase() : email;
    
    const validateEmail = (num:string): boolean => {
        const n = sanitizeEmail(num);
        if(!validateEmailPattern(n))
            return false;
        return props.emailList.filter(p => p.email === n).length == 0;
    }
    
    const validateEmailPattern = (email: string): boolean => {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return expression.test(email);
    }

    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.value;
        props.setEmail(value ? value.toLowerCase() : value);
    }

    const handleSavingEmail = () => { props.saveEmail({ email: sanitizeEmail(props.editingEmail) }); }
    const userContext = React.useContext<IUserContext>(UserContext);

    return (
        <Modal centered show={true} className={"register-phone-dialog " + userContext.style}>
            <Modal.Body>
                <div className="row">
                    <div className="col-sm-12">
                        <Form noValidate validated={validateEmail(props.editingEmail)}>
                            <div className="title-label" ><FormattedMessage id="notifications.email.list.add" /></div>
                            <div className="email">
                                 <FormControl
                                    className="form-control-inline"
                                    placeholder=""
                                    type="text"
                                    name="email"
                                    value={props.editingEmail !== null ? props.editingEmail : ""}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Form>
                        <div className="comment">
                            <FormattedMessage id="notifications.email.send.note" />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeEmailDialog}>
                    <FormattedMessage id="notifications.cancel" />
                </Button>
                    <SubmitButton  disabled={!validateEmail(props.editingEmail)}
                                   onClick={handleSavingEmail}>
                        <FormattedMessage id="notifications.email.register" />
                    </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default connect(
    (state: IAppStore) => state.emailNotification,
    (dispatch: any) => bindActionCreators(EmailNotificationActionCreators, dispatch)
)(EmailRegistrationDialog);