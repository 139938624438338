import { fetch } from 'whatwg-fetch';
import { ILoginModel, IForgotPasswordModel, IResetPasswordModel, IExternalLoginConfirmationModel } from 'constants/LoginConstants';
import { IRegistrationModel } from 'constants/RegistrationConstants';
import {
    IChangeLanguageViewModel,
    IChangePasswordViewModel,
    IDeleteAccountViewModel,
    IConfirmEmailViewModel,
    ISubscribeEmailViewModel
} from 'constants/UserManage';
import { jsonHeader } from 'constants/Constants';

class UserAccountAPI {

    public fetchUserInfo(): Promise<Response> {
        return fetch(`/api/user`);
    }

    public loginUser(model: ILoginModel): Promise<Response> {
        return fetch(`/api/account/login`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public loginDemo(): Promise<Response> {
        return fetch(`/api/account/login-demo`, {
            method: "POST",
            body: null,
            headers: jsonHeader
        });
    }

    public registerUser(model: IRegistrationModel): Promise<Response> {
        return fetch(`/api/account/register`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public setLanguage(model: IChangeLanguageViewModel): Promise<Response> {
        return fetch(`/api/user/language`, {
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public changePassword(model: IChangePasswordViewModel): Promise<Response> {
        return fetch(`/api/user/password`, {
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public deleteAccount(model: IDeleteAccountViewModel): Promise<Response> {
        return fetch(`/api/user`, {
            method: "DELETE",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public forgotPassword(model: IForgotPasswordModel): Promise<Response> {
        return fetch(`/api/account/forgotpassword`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public confirmEmail(model: IConfirmEmailViewModel): Promise<Response> {
        return fetch(`/api/account/confirmemail`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public subscribeEmail(model: ISubscribeEmailViewModel): Promise<Response> {
        return fetch(`/api/notification/subscribe-email`, {
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }
    public unsubscribeEmail(model: ISubscribeEmailViewModel): Promise<Response> {
        return fetch(`/api/notification/unsubscribe-email`, {
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public resetPassword(model: IResetPasswordModel): Promise<Response> {
        return fetch(`/api/account/resetpassword`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public resendEmail(email: string): Promise<Response> {
        return fetch(`/api/account/resendemail`, {
            method: "POST",
            body: JSON.stringify(email),
            headers: jsonHeader
        });
    }

    public setLeftPanelState(isExpand: boolean): Promise<Response> {
        return fetch(`/api/user/expandleftmenu`, {
            method: "PUT",
            body: JSON.stringify(isExpand),
            headers: jsonHeader
        });
    }

    public externalLogin(): Promise<Response> {
        return fetch(`/api/account/ExternalLogin`, {
            method: "GET",
            headers: jsonHeader
        });
    }

    public externalLoginConfirmation(model: IExternalLoginConfirmationModel): Promise<Response> {
        return fetch(`/api/account/ExternalLoginConfirmation`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }
}

export default new UserAccountAPI;