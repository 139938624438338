import {Col, Row} from "react-bootstrap";
import C3Chart from "react-c3js";
import {useIntl} from "react-intl";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import {bindActionCreators} from "redux";
import {SmogActionCreators} from "actions/SmogActionCreators";
import DatePicker from "react-datepicker";
import {DateTimeSecFormat, MeasurableParameter, DateFormatDatePicker, DateFormat} from "constants/Constants";
import {IDeviceSmogData} from "constants/SmogConstants";
import "../../../less/Chart.css";
import "../../../less/ChartSmog.css";
import {IAppStore} from "store/configureStore";
import Spinner from "../../Spinner";
import * as React from "react";
import * as moment from 'moment';
import DeviceContext, {IDeviceContext} from "../../DeviceContext";

interface IDeviceParameterSmogChartProps {
    smogData: IDeviceSmogData,
    message: string,
    fetchData: (deviceId: number, from: Date, to: Date) => void;
}

const sensors: { [id: string]: number; } = {};
sensors["temperature"] = 2;
sensors["pm25"] = 5;
sensors["pm10"] = 6;

const getStartDate = (data: IDeviceSmogData): Date => {
    if (data == null || (data.records || []).length === 0)
        return null;
    return moment.utc(data.records[data.records.length - 1].date).toDate();
}

const getEndDate = (data: IDeviceSmogData): Date => {
    if (data == null || (data.records || []).length === 0)
        return null;
    return moment.utc(data.records[0].date).toDate();
}

const DeviceParameterSmogChart = (props: IDeviceParameterSmogChartProps) => {
    const intl = useIntl();
    const deviceContext = React.useContext<IDeviceContext>(DeviceContext);
    
    const [parameterId, setParameter] = React.useState<number>(null);
    const [startDate, setStartDate] = React.useState<Date>(null);
    const [endDate, setEndDate] = React.useState<Date>(null);

    React.useEffect(() => {
        props.fetchData(deviceContext.deviceId, startDate, endDate);
    }, [startDate, endDate]);

    React.useEffect(() => {
        if(startDate !== null || endDate != null )
            return;
        
        const start = getStartDate(props.smogData);
        const end = getEndDate(props.smogData);
        if (start !== null && end !== null) {
            setStartDate(start);
            setEndDate(end);
        }
    }, [props.smogData]);

    if (props.smogData === null || props.message === "Loading")
        return (<Spinner/>);

    if (parameterId)
        return (< Navigate to={`/device/${deviceContext.deviceId}/${MeasurableParameter[parameterId]}`}/>);

    const legend = {
        item: {
            onclick: (name: any) => {
                setParameter(sensors[name]);
            }
        }
    };

    let emptyObject = null;
    if ((props.smogData.records || []).length === 0)
        emptyObject = {
            label: {text: intl.formatMessage({id: "device.nodata"})}
        };

    const data = {
        x: 'x',
        xFormat: "%Y-%m-%d %H:%M:%S",
        columns: [
            ['x'].concat(props.smogData.records.map(r => moment.utc(r.date).local().format(DateTimeSecFormat))),
            ['temperature' as any].concat(props.smogData.records.map(r => r.temperature)),
            ['pm25' as any].concat(props.smogData.records.map(r => r.pm25)),
            ['pm10' as any].concat(props.smogData.records.map(r => r.pm10))
        ],
        types: {
            pm25: 'area',
            pm10: 'area',
            temperature: 'line'
        },
        colors: {
            pm25: '#1f77b4',
            pm10: '#aec7e8',
            temperature: '#ff7f0e'
        },
        axes: {
            pm25: 'y',
            pm10: 'y',
            temperature: 'y2'
        },
        groups: [['pm25', 'pm10']],
        names: {
            temperature: intl.formatMessage({id: "device.chart.parameter_2"}),
            pm25: intl.formatMessage({id: "device.chart.parameter_5"}),
            pm10: intl.formatMessage({id: "device.chart.parameter_6"})
        },
        empty: emptyObject
    };

    const axis = {
        x: {
            type: 'timeseries',
            tick: {
                count: 3,
                format: '%Y-%m-%d %H:%M'
            }
        },
        y: {
            label: {
                text: intl.formatMessage({id: "device.chart.parameter.unit_5"}),
                position: 'outer-middle'
            }
        },
        y2: {
            show: true,
            label: {
                text: intl.formatMessage({id: "device.chart.parameter.unit_2"}),
                position: 'outer-middle'
            },
            tick: {
                format: (x: number) => x.toFixed(1),
            }
        }
    };
    const grid = {
        y: {
            lines: [
                {value: props.smogData.pm25Norm, text: intl.formatMessage({id: "device.chart.parameter_5"})},
                {value: props.smogData.pm10Norm, text: intl.formatMessage({id: "device.chart.parameter_6"})},
            ]
        }
    };
    const size: any = { height: "480" };
    const handleDateStartChange = (date: any) => {
        setStartDate(date);
    }

    const handleDateEndChange = (date: any) => {
        setEndDate(date);
    }
    return (
        <Row>
            <Col className="device-parameter-smog-chart">
                <div className="chart-settings">
                    {(startDate !== null && endDate !== null)
                        ? (<div className="dates">
                            <DatePicker
                                placeholderText={startDate ? moment.utc(startDate).local().format(DateFormat) : intl.formatMessage({id: "device.date.from"})}
                                selectsStart
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={endDate}
                                onChange={handleDateStartChange}
                                dateFormat={DateFormatDatePicker}
                                className="date-input"
                            />
                            <DatePicker
                                placeholderText={endDate ? moment.utc(endDate).local().format(DateFormat) : intl.formatMessage({id: "device.date.to"})}
                                selectsEnd
                                selected={endDate}
                                endDate={endDate}
                                startDate={startDate}
                                minDate={startDate}
                                onChange={handleDateEndChange}
                                dateFormat={DateFormatDatePicker}
                                className="date-input"
                            />
                        </div>)
                        : null
                    }
                </div>
                <div className="chart">
                    <C3Chart
                        size={size}
                        data={data}
                        axis={axis}
                        grid={grid}
                        legend={legend}
                    />
                </div>
            </Col>
        </Row>);
};


function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        smogData: state.deviceSmog.data,
        message: state.deviceSmog.message,
        deviceId: ownProps.deviceId,
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(SmogActionCreators, dispatch)
)(DeviceParameterSmogChart);
