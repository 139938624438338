import { ModeDeviceDetailsView, MeasurableParameter, ModeDeviceParametersView } from "constants/Constants";
import { DeviceParameterActions, DeviceActions, DeviceDataActions, IDeviceDetails, IDataRecord, IDeviceDataRecordKey, IGaugeParameter, IEditedDevice } from "../constants/DeviceConstants";
import DeviceDataAPI from "../api/DeviceDataAPI";
import promiseDispatch from "./promiseDispatch";

export const DeviceActionCreators = {
    changeDate: (dateStart: Date, dateEnd: Date) => ({ type: DeviceDataActions.DATE_CHANGE, payload: { dateStart, dateEnd } }),
    selectDevice: (deviceId: number) => ({ type: DeviceActions.SELECTED_DEVICE, payload: deviceId }),
    cleanDeviceParameters: () => ({ type: DeviceParameterActions.NO_DATA }),
    editDeviceForm: (editedForm: IEditedDevice) => ({ type: DeviceActions.EDITED_FORM, payload: editedForm }),
    changeModeView: (mode: ModeDeviceDetailsView) => ({ type: DeviceActions.MODE_VIEW, payload: mode }),
    cancelEditing: () => ({ type: DeviceActions.CANCEL_EDIT_MODE }),

    fetchSingleDevice: (deviceId: number) => async (dispatch: any, getState: any) => {
        promiseDispatch<IDeviceDetails>(dispatch,
            () => DeviceDataAPI.fetchSingleDevice(deviceId),
            {
                request: DeviceActions.FETCH_DEVICE_DETAILS,
                success: DeviceActions.FETCH_DEVICE_DETAILS_SUCCESS,
                failure: DeviceActions.FETCH_DEVICE_DETAILS_FAILED
            });
    },

    fetchDeviceParameters: (deviceId: number, mode: ModeDeviceParametersView) => async (dispatch: any, getState: any) => {
        dispatch({ type: DeviceParameterActions.FETCH_DATA, payload: { deviceId, mode } });
        promiseDispatch<IGaugeParameter[]>(
            dispatch,
            () => DeviceDataAPI.fetchDeviceParameters(deviceId),
            {
                request: null,
                success: DeviceParameterActions.FETCH_DATA_SUCCESS,
                failure: DeviceParameterActions.FETCH_DATA_FAILED
            });
    },

    fetchDataByDate: (deviceId: number, parameter: MeasurableParameter, from: Date = null, to: Date = null, refreshGrid: boolean = true) =>
        async (dispatch: any, getState: any) => {
            if (deviceId && parameter) {
                dispatch({ type: DeviceDataActions.FETCH_DATA, payload: refreshGrid });
                promiseDispatch<IDataRecord[]>(
                    dispatch,
                    () => DeviceDataAPI.fetchDataByDate(deviceId, parameter, from, to),
                    {
                        request: DeviceDataActions.FETCH_DATA,
                        success: DeviceDataActions.FETCH_DATA_SUCCESS,
                        failure: DeviceDataActions.FETCH_DATA_FAILED
                    }
                );
            } else {
                dispatch({ type: DeviceDataActions.FETCH_NO_DATA, payload: null });
            }
        },


    deleteDevice: (deviceId: number) => async (dispatch: any, getState: any) => {
        promiseDispatch<boolean>(dispatch,
            () => DeviceDataAPI.deleteDevice(deviceId),
            {
                request: DeviceActions.DELETE,
                success: DeviceActions.DELETED,
                failure: DeviceActions.DELETE_FAILED
            });
    },

    deleteDeviceData: (deviceId: number) => async (dispatch: any, getState: any) => {
        promiseDispatch<boolean>(
            dispatch,
            () => DeviceDataAPI.deleteDeviceData(deviceId),
            {
                request: DeviceDataActions.CLEANING_DATA,
                success: DeviceDataActions.CLEAN,
                failure: DeviceDataActions.CLEANING_DATA_FAILED
            });
    },

    deleteDeviceDataEntry: (model: IDeviceDataRecordKey) => async (dispatch: any, getState: any) => {
        promiseDispatch<boolean>(
            dispatch,
            () => DeviceDataAPI.deleteDeviceDataEntry(model),
            {
                request: DeviceDataActions.ENTRY_REMOVING,
                success: DeviceDataActions.ENTRY_REMOVED,
                failure: DeviceDataActions.ENTRY_REMOVE_FAILED
            });
    },

    showHideDeleteDataDialog: (show: boolean) => ({ type: DeviceActions.SHOW_HIDE_DELETE_DATA_DIALOG, payload: show }),
    showHideDeleteDeviceDialog: (show: boolean) => ({ type: DeviceActions.SHOW_HIDE_DELETE_DEVICE_DIALOG, payload: show }),

    saveDeviceDetails: (model: IEditedDevice) => async (dispatch: any, getState: any) => {
        dispatch({ type: DeviceActions.EDIT_DEVICE, payload: model });
        promiseDispatch<boolean>(
            dispatch,
            () => DeviceDataAPI.editDevice(model),
            {
                request: null,
                success: DeviceActions.EDIT_DEVICE_SUCCESS,
                failure: DeviceActions.EDIT_DEVICE_FAILED
            }, () => {
                dispatch({ type: DeviceDataActions.UPDATE_DEVICE_DATA });
            });
    },
}
