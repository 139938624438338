import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IForgotPasswordModel } from "../../constants/LoginConstants";
import { IResult } from "../../constants/Constants";
import "../../less/Login.css";
import InfoMenu from '../InfoMenu';
import Spinner from "../Spinner";
import UserContext, { IUserContext } from "../UserContext";
import FooterLinks from "./FooterLinks";

interface IForgotPasswordForm extends IForgotPasswordModel, IResult { }

const ForgotPassword = () => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const [model, setModel] = React.useState({ email: "", isSuccessed: false, message: null } as IForgotPasswordForm);

    const submitForm = () => {
        setModel({ ...model, message: "loading" });
        UserAccountAPI.forgotPassword(model)
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.json())
            .then((forgotPassowrdResult: boolean) => {
                setModel({ ...model, isSuccessed: forgotPassowrdResult, message: forgotPassowrdResult ? "forgot.password.email" : "forgot.password.error" });
            })
            .catch((r: Response) => r.json())
            .then((r: string) => { if (r) { setModel({ ...model, isSuccessed: false, message: "account.login.invalid.data" }); } });
    };

    return (
        <div className={"forgot-password " + userContext.style}>
            <InfoMenu />
            <div className="wrapper fadeInDown">
                <div className={"logo-box " + userContext.applicationName} />
                <div className="formContent">
                    {(model.message === "loading"
                        ? <Spinner />
                        : <Form>
                            <Form.Label><FormattedMessage id="page.login.description" /></Form.Label>
                            <Form.Control.Feedback type={model.isSuccessed ? "valid" : "invalid"} className="login-message" hidden={model.message == null}>
                                {(model.message != null ? <FormattedMessage id={model.message} /> : null)}
                            </Form.Control.Feedback>
                            {model.isSuccessed === false ?
                                <>
                                    <Form.Group controlId="login">
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            value={model.email}
                                            onInput={(e: any) => setModel({ ...model, email: e.target.value })} />
                                    </Form.Group>
                                    <Button variant="success" type="button" onClick={submitForm} disabled={!model.email}>
                                        <FormattedMessage id="forgot.password.submit" />
                                    </Button>
                                </> : null}
                        </Form>
                    )}
                </div>
                <FooterLinks />
            </div>
        </div>
    );
};

export default ForgotPassword;

