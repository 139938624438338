import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as DashboardStore from './DashboardStore';
import * as DeviceDataStore from './DeviceDataStore';
import * as DeviceDetailsStore from './DeviceDetailsStore';
import * as DeviceParametersStore from './DeviceParametersStore';
import * as DevicesStore from './DevicesStore';
import * as MapStore from './MapStore';
import * as NotificationStore from './NotificationStore';
import * as RegisterDeviceStore from './RegisterDeviceStore';
import * as UserInfoStore from './UserInfoStore';
import * as DeviceSmogStore from './DeviceSmogStore';
import * as SmsNotificationStore from './SmsNotificationStore';
import * as EmailNotificationStore from './EmailNotificationStore';
import {History} from "history";

export interface IAppStore {
    devicesList: DevicesStore.IDevices,
    dashboard: DashboardStore.IDashboard,
    deviceData: DeviceDataStore.IDeviceData,
    deviceDetails: DeviceDetailsStore.IDeviceDetailsStore,
    deviceParameters: DeviceParametersStore.IDeviceParameters,
    notification: NotificationStore.INotification,
    smsNotification: SmsNotificationStore.ISmsNotification,
    emailNotification: EmailNotificationStore.IEmailNotification,
    registerDevice: RegisterDeviceStore.IRegisterDevice,
    userInfo: UserInfoStore.IUserContextStore,
    map: MapStore.IDeviceMarkupsList,
    deviceSmog: DeviceSmogStore.IDeviceSmog,
}

export default function configureStore(history: History, initialState: IAppStore) {
    const reducers = {
        devicesList: DevicesStore.reducer,
        dashboard: DashboardStore.reducer,
        deviceData: DeviceDataStore.reducer,
        deviceDetails: DeviceDetailsStore.reducer,
        deviceParameters: DeviceParametersStore.reducer,
        registerDevice: RegisterDeviceStore.reducer,
        notification: NotificationStore.reducer,
        smsNotification: SmsNotificationStore.reducer,
        emailNotification: EmailNotificationStore.reducer,
        userInfo: UserInfoStore.reducer,
        map: MapStore.reducer,
        deviceSmog: DeviceSmogStore.reducer,
      };

  const middleware = [
    thunk
  ];

  const rootReducer = (browserHistory: History) => combineReducers({
      router: createRouterReducer(browserHistory),
        ...reducers,
  })

  return createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(...middleware))
  );
}