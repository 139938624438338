import * as React from "react";
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const TosConsumerRights = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">

                    <h3>POUCZENIE O PRAWIE KONSUMENTA DO ODSTĄPIENIA OD UMOWY ŚWIADCZENIA USŁUG</h3>


                    <p><b>Prawo Konsumenta odstąpienia od umowy świadczenia usług</b></p>
                    <p>
                        Informujemy, ze mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania jakiejkolwiek przyczyny.
                        Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia zawarcia umowy.
        Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować Usługodawcę [kontakt: Jakuba Szczerba „Termipol”, ul. Powstańców Śl. 64, 42-700 Lubliniec  lub email <a href="mailto:info@ipomiar.pl">info@ipomiar.pl</a> o swojej decyzji o odstąpieniu od niniejszej umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą, faksem lub pocztą elektroniczną).
                        Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, ale nie jest to obowiązkowe.
                        Aby zachować termin do odstąpienia od umowy, wystarczy wysłać  informację dotyczącą odstąpienia od umowy przed upływem terminu wskazanego powyżej.
    </p>
                    <p><b>Skutki odstąpienia od umowy</b></p>
                    <p>
                        W przypadku odstąpienia od umowy o świadczenie usługi odpłatnej zwrócimy Państwu wszystkie otrzymane od Państwa płatności niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia otrzymania oświadczenia o odstąpieniu od umowy. Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem.
                        Jeżeli zażądali Państwo rozpoczęcia świadczenia usług przed upływem terminu do odstąpienia od umowy, wówczas uiszczą Państwo kwotę proporcjonalną do zakresu świadczeń spełnionych do chwili, w której poinformowali nas Państwo o odstąpieniu od niniejszej umowy.
    </p>
                </div>
            </ScrollArea>
        </div>
    );

export default TosConsumerRights;