import * as React from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import '../less/TopMenu.css';
import { UserContextConsumer } from './UserContext';

const TopMenu = () => {
    const intl = useIntl();
    return (
        <UserContextConsumer>
            {userContext => userContext && (
                <React.Fragment>
                    <Navbar collapseOnSelect className="top-menu" expand="lg">
                        <Navbar.Toggle aria-controls="menu-items" />
                        <Navbar.Collapse id="menu-items">
                            <Nav className="mr-auto">
                                {
                                    (userContext.isAuthenticated ?
                                        <React.Fragment>
                                            <LinkContainer to="/main">
                                                <Nav.Item className="dashboard-link">
                                                    <i className="material-icons">dashboard</i> <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
                                                </Nav.Item>
                                            </LinkContainer>
                                            <LinkContainer to="/notifications">
                                                <Nav.Item>
                                                    <i className="material-icons">email</i> <FormattedMessage id="notifications" defaultMessage="Notifications" />
                                                </Nav.Item>
                                            </LinkContainer>
                                            <LinkContainer to="/map">
                                                <Nav.Item>
                                                    <i className="material-icons">public</i> <FormattedMessage id="map" defaultMessage="Map" />
                                                </Nav.Item>
                                            </LinkContainer>
                                            <LinkContainer to="/space-planner">
                                                <Nav.Item>
                                                    <i className="material-icons">room</i> <FormattedMessage id="space-planner" defaultMessage="Planner" />
                                                </Nav.Item>
                                            </LinkContainer>
                                            {userContext.onlineSubscriptionEnabled ?
                                                <LinkContainer to="/subscription">
                                                    <Nav.Item>
                                                        <i className="material-icons">payment</i> <FormattedMessage id="subscription" defaultMessage="Subscription" />
                                                    </Nav.Item>
                                                </LinkContainer>
                                                : null
                                            }
                                            {userContext.isSupervisor ?
                                                <LinkContainer to="/supervisor">
                                                    <Nav.Item>
                                                        <i className="material-icons">visibility</i> <FormattedMessage id="supervisor" defaultMessage="Supervisor" />
                                                    </Nav.Item>
                                                </LinkContainer>
                                                : null
                                            }
                                            
                                        </React.Fragment>
                                        : <Link to="/portal">
                                            <Button variant="success" style={{ marginTop: "10px" }}><FormattedMessage id="page.registration.title" defaultMessage="Registration" /></Button>
                                        </Link>)
                                }

                                {(
                                    userContext.smogMapEnabled && !userContext.isAuthenticated
                                        ? <LinkContainer to="/map">
                                            <Nav.Item>
                                                <i className="material-icons">place</i> <FormattedMessage id="map" defaultMessage="Map" />
                                            </Nav.Item>
                                        </LinkContainer>
                                        : null
                                )}

                                <LinkContainer to="/about">
                                    <Nav.Item>
                                        <i className="material-icons">info</i> <FormattedMessage id="about" defaultMessage="About" />
                                    </Nav.Item>
                                </LinkContainer>
                                {(
                                    userContext.applicationName != "dcx" ? 
                                    <>
                                        <LinkContainer to="/contact">
                                            <Nav.Item>
                                                <i className="material-icons">phone</i> <FormattedMessage id="contact" defaultMessage="Contact" />
                                            </Nav.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/tos">
                                            <Nav.Item>
                                                <i className="material-icons">assignment_turned_in</i> <FormattedMessage id="termOfService" defaultMessage="Term of service" />
                                            </Nav.Item>
                                        </LinkContainer>
                                    </> :
                                        <Nav.Link key="dcx" href="https://dcx.eu/contact/">
                                           <i className="material-icons">phone</i> <FormattedMessage id="contact" defaultMessage="Contact" />
                                        </Nav.Link>
                               )}

                                <LinkContainer to="/access-control">
                                    <Nav.Item>
                                        <i className="material-icons">manage_accounts</i> <FormattedMessage id="access-control" defaultMessage="Access" />
                                    </Nav.Item>
                                </LinkContainer>
                            </Nav>
                            {
                                (userContext.isAuthenticated ?
                                    <Nav>
                                        <LinkContainer to="/manage">
                                            <Nav.Item>
                                                <i className="material-icons" title={userContext.userName}>account_box</i> <span className="username"> {userContext.userName} </span>
                                            </Nav.Item>
                                        </LinkContainer>
                                        <Nav.Link key="logout" href="/Account/Logout">
                                            <i className="material-icons" title={intl.formatMessage({ id: "logout" })}>exit_to_app</i> <span className="logout">  <FormattedMessage id="logout" defaultMessage="Logout" /> </span>
                                        </Nav.Link>

                                        {userContext.isAdmin ?
                                            <Nav.Link key="admin" href="/Admin/Index">
                                                Admin
                                        </Nav.Link>
                                            : null
                                        }
                                    </Nav>
                                    : null)
                            }
                        </Navbar.Collapse>
                    </Navbar>
                    <div className="shadow-top" />
                </React.Fragment>
            )}
        </UserContextConsumer>
    );
};
export default TopMenu;