import * as React from "react";
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const TosComplaintTemplate = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">
                    <h3>WZÓR FORMULARZA REKLAMACYJNEGO W ZAKRESIE ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ</h3>
                    <p>(formularz ten należy wypełnić i odesłać wyłącznie w przypadku reklamacji usług)</p>
                    <pre>{`
	                                                        ………………………………………(miejscowość i data)

    Jakuba Szczerba „Termipol”
    ul. Powstańców Śl. 64
    42-700 Lubliniec 
 
 
 
    Ja…………………………………………adres ……………………………………………………………
    w związku z korzystaniem z Serwisu iPomiar niniejszym informuję o tym, że składam reklamację na
    [nazwa usługi]   ………………………………………………………………………………………
    …………………………………………………………………………………………………………………
    …………………………………………………………………………………………………………………
    [opis problemu] ………………………………………………………………………………………………
    …………………………………………………………………………………………………………………
    …………………………………………………………………………………………………………………

    Data złożenia zamówienia		………………………………………………
    Data opłacenia Usługi			………………………………………………
    Imię i nazwisko / Nazwa	………………………………………………
    Adres		………………………………………………
    Podpis  (tylko jeżeli formularz jest przesyłany w wersji papierowej)
    …………………………………………………………………………………………………………


    Data………………………
    `}</pre>
                </div>
            </ScrollArea>
        </div>
    )

export default TosComplaintTemplate;