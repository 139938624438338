import * as React from "react";
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const TosCharges = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">
                    <h2>Opłaty</h2>

                    <h3>Pakiety</h3>
                    <ol>
                        <li>Pakiet FREE – wynosi: 0 zł</li>
                        <li>Pakiet PREMIUM – wynosi: 100zł / rok</li>
                    </ol>

                    <h3>Uwagi ogólne</h3>
                    W przypadku wyboru formy zapłaty przelew bankowy, wpłat należy dokonać na konto nr 32 1050 1155 1000 0092 1478 2709 (ING)
                
    <h3>ROZLICZENIA NALEŻNOŚCI</h3>
                    <ol>
                        <li>Dla każdego urządzenia rozliczenia prowadzone są w sposób indywidualny.</li>
                        <li>Użytkownik jest zobowiązany do uiszczania na bieżąco należności wynikających z wystawianych przez Usługodawcę faktur, w terminie w nich określonym. Nieterminowe wpłaty wiązać się będą z naliczaniem odsetek za opóźnienie i powrót do pakietu FREE.</li>
                        <li>Powrót do pakietu FREE oznacza bezpowrotną utrate danych z wyjątkiem ostatnich pomiarów zagwarantowanych w pakiecie FREE.</li>
                        <li>Warunkiem przywrócenia pełnej funkcjonalności urządzenia jest niezwłoczne uregulowanie wszelkich należności na rzecz Usługodawcy.</li>
                    </ol>

                    <h3>Nadpłaty</h3>
                    <ol>
                        <li>W przypadku powstania na Koncie Użytkownika nadpłaty, Użytkownik może w dowolnym czasie żądać jej zwrotu, z zastrzeżeniem ust. 3.</li>
                        <li>Zwrot nadpłaty nastąpi po uprzednim skierowaniu przez Użytkownika pisemnego żądania, za pośrednictwem poczty elektronicznej, na adres e-mail: info@ipomiar.pl lub na adres siedziby Usługodawcy  wraz ze wskazaniem przez Użytkownika numeru rachunku bankowego, na który kwota nadpłaty ma zostać zwrócona.</li>
                        <li>W przypadku istnienia na Koncie Użytkownika aktualnie naliczonych opłat oraz ewentualnego zadłużenia na innych Kontach danego Użytkownika, nadpłata, o której mowa w ust. 1, zostanie zaliczona na poczet tych bieżących zaległości.</li>
                        <li>W niektórych przypadkach zwrot nadpłaty znajdującej się na Rachunku łączyć się będzie  z wystawieniem faktury korygującej dla Użytkownika.  Wówczas  obowiązkiem Użytkownika jest odesłanie na adres Usługodawcy pierwotnie wystawionej faktury wraz z potwierdzeniem jej otrzymania przez Usługodawcę.</li>
                    </ol>

                    <h3>TERMIN PŁATNOŚCI</h3>
                    <ol>
                        <li>Z zastrzeżeniem przepisów konsumenckich, Użytkowników Serwisu obowiązuje 14-dniowy termin płatności liczony od dnia wystawienia faktury, przy czym dniem zapłaty jest dzień uznania rachunku bankowego Administratora.</li>
                        <li>Usługodawca ma prawo do zmiany terminu płatności ustalonego dla danego Użytkownika. Termin ten może ulec wydłużeniu lub skróceniu w stosunku do terminu płatności, określonego w ust. 1.</li>
                    </ol>
                    Użytkownik jest zobowiązany dokonać płatności za odpłatną Usługę w terminie 14 dni od złożenia Zamówienia. W przypadku braku płatności w terminie, o którym mowa w zdaniu poprzednim, Usługodawca po uprzednim bezskutecznym wezwaniu do zaprzestania lub usunięcia naruszeń z wyznaczeniem stosownego terminu może odstąpić od umowy sprzedaży usług na podstawie art. 491 Kodeksu Cywilnego.
    <h3>FAKTURY</h3>
                    <ol>
                        <li>Faktury są wystawiane, przesyłane i doręczane przez Usługodawcę  w formie elektronicznej.</li>
                        <li>
                            Rejestrując się w serwisie iPomiar oraz akceptując Regulamin, Użytkownik wyraża jednocześnie zgodę na poniższe warunki dotyczące przesyłania faktur w formie elektronicznej:
            <ol type="a">
                                <li>Usługodawca ma prawo udostępniać Użytkownikowi faktury do pobrania z serwera Usługodawcy (poprzez stronę „Moje Konto”) lub przesyłać je pocztą elektroniczną</li>
                                <li>faktury są udostępniane lub przesyłane w formacie plików elektronicznych PDF.</li>
                            </ol>
                        </li>
                        <li>Faktury korygujące oraz duplikaty do faktur przesyłanych w formie elektronicznej będą również dostępne w formie elektronicznej.</li>
                        <li>Użytkownik jest uprawniony do wycofania akceptacji na wystawianie i przesyłanie faktur w formie elektronicznej. O wycofaniu akceptacji Użytkownik obowiązany jest poinformować Administratora za pośrednictwem wiadomości e-mail wysłanej na adres <a href="mailto:faktury@ipomiar.pl">faktury@ipomiar.pl</a>. W przypadku określonym w zdaniu poprzednim, faktury, faktury korygujące oraz duplikaty do faktur, począwszy od kolejnej najbliższej faktury, będą wystawiane w formie papierowej i zostaną przesyłane na wskazany przez Użytkownika adres korespondencyjny.</li>
                        <li>W przypadku wycofania akceptacji na wystawianie i przesyłanie faktur w formie elektronicznej ponowne skorzystanie z usługi e-faktury jest możliwe po uprzednim zwróceniu się przez Użytkownika do Usługodawcy za pośrednictwem wiadomości e-mail wysłanej na adres: <a href="mailto:faktury@ipomiar.pl">faktury@ipomiar.pl</a> z wnioskiem o ponowną możliwość skorzystania z usługi pobierania i przesyłania faktur w formie elektronicznej.</li>
                        <li>Akceptacja przez Użytkownika elektronicznej formy wystawiania i przesyłania faktur nie wyłącza możliwości przesyłania faktur również w formie papierowej przez Usługodawcę.</li>
                        <li>Użytkownik oraz Usługodawca oświadczają, że zapewnią autentyczność pochodzenia oraz integralność treści faktur przesyłanych w formie elektronicznej.</li>
                        <li>Faktury za dany miesiąc kalendarzowy wystawiane są ostatniego dnia tego miesiąca i obejmują wszystkie należności (opłaty) z tytułu dokonanych transakcji naliczone w tym miesiącu dla danego Użytkownika.</li>
                        <li>Użytkownik będący podatnikiem podatku od wartości dodanej (VAT) z siedzibą na terytorium kraju Unii Europejskiej innego niż Polska obowiązany jest przedstawić dokumenty potwierdzające zarejestrowanie go jako podatnika w jednym z krajów Unii Europejskiej innym niż Polska.</li>
                        <li>Użytkownik obowiązany jest do dokonywania bieżącej aktualizacji danych wymaganych do wystawienia faktur, niezwłocznie po zaistnieniu każdej zmiany, w szczególności aktualizacji adresu e-mail bądź odpowiednio adresu, na który mają być doręczane faktury w formie papierowej.</li>
                    </ol>
                </div>
            </ScrollArea>
        </div>
    );

export default TosCharges;