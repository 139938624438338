import {IBackgroundImage, IPlannerDevice, ISpacePlannerViewModel} from "../constants/PlannerDeviceConstants";

export interface ISpacePlanner {
    backgroundImage: IBackgroundImage;
    devices: IPlannerDevice[];
    loadStatus?: string;
    wasChanged: boolean;
    message: string;
    name: string;
}

export const initialState: ISpacePlanner = { devices: [], backgroundImage: null, wasChanged: false, message: null, name: null};

export const reducer = (state: ISpacePlanner, action: any): ISpacePlanner => {
    state = state || initialState;
    switch (action.type) {
        case "loading":
            return {... state, loadStatus: "loading"}
        case "loaded":
            const payload = action.payload as ISpacePlannerViewModel;
            return {... state,
                name: payload.name,
                devices: payload.devices, 
                backgroundImage: payload.backgroundImage, 
                loadStatus: null,
                wasChanged:false}
        case "loading_error":
            return {... state, loadStatus: "error"}
        case "add_device":
            return { ...state, devices: [ ...state.devices, (action.device as IPlannerDevice)], wasChanged: true };
        case "remove_device":
            return { ...state, devices: [ ...state.devices.filter(d=> d.deviceId != action.deviceId)], wasChanged: true };
        case "remove_all_devices":
            return { ...state, devices: [], message: "saved" };
        case "add_background":
            return { ...state, devices: [], backgroundImage: (action.backgroundImage as IBackgroundImage), wasChanged: true};
        case "delete_background":
            return { ...state, devices: [], backgroundImage: null, wasChanged: true };
        case "move_device":
            const {deviceId,x,y} = action.data;
            const deviceIndex = state.devices.findIndex(t => t.deviceId == deviceId);
            if(deviceIndex == -1)
                return state;
            return {
                ...state,
                devices: [...state.devices.slice(0, deviceIndex), {...state.devices[deviceIndex], x, y}, ...state.devices.slice(deviceIndex + 1)],
                wasChanged: true
            };
        case "saved":
            return { ...state, wasChanged: false, message: "saved" }
        case "start_saving":
            return { ...state, wasChanged: false, message: "saving" }
        case "saving_error":
            return {... state, message: "error"}
        case "clear_message":
            return { ...state, message: null}
        case "confirm_dialog":
            return { ...state, message: "confirm_delete" }
        case "change_name":
            return { ...state, name: action.name, wasChanged: true }
        default:
            return state;
    }
}