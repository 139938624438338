import {IParameterSettings} from "../../../constants/Constants";
import * as React from "react";
import {useEffect} from "react";
import {Coalesce, GetValueFromControl, IsNullOrWhiteSpace, ValidateNumber} from "../../../constants/Common";
import {Col, Container, FormControl, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {IEditedDevice} from "../../../constants/DeviceConstants";


export const ValidateParameterConverter = (editForm: IEditedDevice): boolean => {
    let result = true;
    editForm.parameterSettings.forEach((parameter: IParameterSettings) => {
        result = result && ValidateParameterSettings(parameter);
    });
    return result;
}

const ValidateParameterSettings = (parameter: IParameterSettings): boolean =>
    ValidateUnitName(parameter) &&
    ValidateNumber(parameter.shifter) &&
    ValidateNumber(parameter.converter);

const ValidateUnitName = (parameterSettings: IParameterSettings): boolean => {
    const maxLengthName = 6;
    const value = parameterSettings.customUnit;
    return value === null || value === undefined
        || (value.length > 0 && value.length <= maxLengthName && !IsNullOrWhiteSpace(value) && !(/[0-9]/.test(value[0])));
}

const ConverterForm  = (
    props: {
        parameter: IParameterSettings,
        handleSensorChange: (parameter: IParameterSettings) => void,
        handleValidationCheck: (validate: boolean) => void;
    }
) => {
    const [shiftInValidation, checkShiftInValidation] = React.useState(false);
    const [converterInValidation, checkConverterInValidation] = React.useState(false);
    const [unitNameInValidation, checkUnitNameInValidation] = React.useState(false);

    useEffect(() => {
        props.handleValidationCheck(!(shiftInValidation || converterInValidation || unitNameInValidation));
    }, [shiftInValidation, converterInValidation, unitNameInValidation])
    const scaleValue = (value: number, converter: number, shifter: number): number => {
        return value === null
            ? null
            : Number((( (value - Coalesce(props.parameter.shifter,0))/Coalesce(props.parameter.converter,1)) * converter + shifter).toFixed(5));
    }

    const recalculateThresholds = (converter: number, shifter: number) : IParameterSettings => {
        return { ... props.parameter,
            thresholdMax: scaleValue(props.parameter.thresholdMax, converter, shifter),
            thresholdMin: scaleValue(props.parameter.thresholdMin, converter, shifter),
            maxValue: scaleValue(props.parameter.maxValue, converter, shifter),
            minValue: scaleValue(props.parameter.minValue, converter, shifter),
            customMinValue: scaleValue(props.parameter.customMinValue, converter, shifter),
            customMaxValue: scaleValue(props.parameter.customMaxValue, converter, shifter),
            converter,
            shifter};
    }

    const handleConverterChange = (event: React.FormEvent<any>) => {
        let converter = GetValueFromControl(event);
        if(converter === null || converter === "")
            converter = 1;

        const isValid = ValidateNumber(converter);
        checkConverterInValidation(!isValid);
        props.handleSensorChange(recalculateThresholds(converter !== null && isValid ? Number(converter) : 1, Coalesce(props.parameter.shifter,0)));
    }

    const handleShifterChange = (event: React.FormEvent<any>) => {
        const shifter = GetValueFromControl(event);
        const isValid = ValidateNumber(shifter);
        checkShiftInValidation(!isValid);
        props.handleSensorChange(recalculateThresholds(Coalesce(props.parameter.converter ,1), shifter !== null && isValid ? Number(shifter) : 0));
    }

    const handleUnitChange = (event: React.FormEvent<any>) => {
        const unit = GetValueFromControl(event);

        checkUnitNameInValidation(!ValidateUnitName({ customUnit: unit } as IParameterSettings));
        props.handleSensorChange({ ... props.parameter, customUnit: unit as string, unit: unit as string });
    }

    return (
        <Container>
            <Row style={{height:"50px"}}>
                <Col xs={6}><FormattedMessage id="device.edit.converter.multiplier" /></Col>
                <Col xs={6}>
                    <FormControl type="text"
                                 key={`converter_${props.parameter.parameterId}`}
                                 name={`converter_${props.parameter.parameterId}`}
                                 defaultValue={props.parameter.converter}
                                 onChange={handleConverterChange}
                                 isInvalid={converterInValidation}
                    /></Col>
            </Row>
            <Row style={{height:"50px"}}>
                <Col xs={6}> <FormattedMessage id="device.edit.converter.shifter" /> </Col>
                <Col xs={6}>
                    <FormControl type="text"
                                 key={`shifter_${props.parameter.parameterId}`}
                                 name={`shifter_${props.parameter.parameterId}`}
                                 defaultValue={props.parameter.shifter}
                                 onChange={handleShifterChange}
                                 isInvalid={shiftInValidation}/></Col>
            </Row>
            <Row style={{height:"50px"}}>
                <Col xs={6}> <FormattedMessage id="device.edit.converter.unit" /> </Col>
                <Col xs={6}>
                    <FormControl type="text"
                                 key={`unit_${props.parameter.parameterId}`}
                                 name={`unit_${props.parameter.parameterId}`}
                                 defaultValue={props.parameter.customUnit}
                                 onChange={handleUnitChange}
                                 isInvalid={unitNameInValidation} /></Col>
            </Row>
            <div className="legend" >
                <div className="desc"><FormattedMessage id="device.edit.converter.desc" /></div>
                <div className="converter-image-container">
                    <div className="y-axis-label"><FormattedMessage id="device.edit.converter.image.y-label" /></div>
                    <div><div className="converter-help-image" /></div>
                    <div/>
                    <div className="x-axis-label"><FormattedMessage id="device.edit.converter.image.x-label" /></div>
                </div>
            </div>
        </Container>
    );
}

export default ConverterForm;