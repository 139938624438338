import * as React from "react";
import "../../less/Battery.css";
export enum BatteryPosition {
    Horizontal,
    Vertical
}

export interface IBatteryIconProps {
    batteryLevel: number;
    title: string;
    position: BatteryPosition;
}

const Normalize = (batteryLevel: number ): number => {
    if (batteryLevel == null)
        return 0;
    else if (batteryLevel < 0)
        return 0;
    else if (batteryLevel > 100)
        return 100;
    else
        return batteryLevel;
}

const BatteryIcon = (props: IBatteryIconProps) =>
{
    const style =
        (props.position === BatteryPosition.Horizontal
            ? { height: `${Normalize(props.batteryLevel)}%` }
            : { width: `${Normalize(props.batteryLevel)}%` });

    const css = 
        (props.position === BatteryPosition.Vertical ? "battery" : "battery-tile")

    return (
        props.batteryLevel ?
            <div className={css + " black"} title={`${props.title}: ${props.batteryLevel}%`}>
                <div className="top black" />
                <div className="level black">
                    <div className="text-content">{props.batteryLevel}%</div>
                    <div className="level-value black" style={style} />
                </div>
            </div>
            :
            <div className={css + " black"}>
                <div className="top black" />
                <div className="network">
                    <i className="material-icons">flash_on</i>
                </div>
            </div>);
}

export default BatteryIcon;