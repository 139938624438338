import * as React from "react";
import { useState} from "react";
import {Form} from "react-bootstrap";
export enum ViewMode{
    Default,
    New,
    Edit,
}

export const TabHeader = ( 
    props: { 
        title?: string,
        defaultMode: ViewMode
        onChange: (title:string) => void
    }) => {
    const [mode, setMode] = useState(props.defaultMode);
    
    const onAddEvent = (value: string) => {
        if(value)
            props.onChange(value);
        setMode(props.defaultMode);
    }
    if(mode == ViewMode.New)
        return (
            <div style={{height:"24px"}}>
            <span className="material-icons" style={{float:"left"}} 
                  onClick={()=>{ setMode(ViewMode.Edit); }}>control_point </span>
        </div>);
    else if(mode == ViewMode.Edit)
        return (<div style={{height:"27px", marginTop:"-3px"}}>
            <Form.Control size="sm" type="text" placeholder="New Tab"
                          autoFocus={true}
                          defaultValue={props.title}
                          onBlur={(event:any) => {
                              onAddEvent(event.target.value);
                          }}
                          onKeyUp={(event: any) => {
                              if(event.code == "Enter")
                              {
                                  onAddEvent(event.target.value);
                              }
            }} /> 
        </div>)
    else
        return <span onDoubleClick={()=>{
            setMode(ViewMode.Edit); 
        }}>{props.title}</span>;
}