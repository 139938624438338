import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Navigate, useSearchParams, Link} from "react-router-dom";
import { UserActionCreators } from "../../actions/UserActionCreators";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IResult } from "../../constants/Constants";
import { ILoginModel } from "../../constants/LoginConstants";
import "../../less/Login.css";
import { IAppStore } from "../../store/configureStore";
import { IUserContextStore } from "../../store/UserInfoStore";
import InfoMenu from '../InfoMenu';
import Spinner from "../Spinner";
import UserContext, { IUserContext } from "./../UserContext";

interface ILoginForm extends ILoginModel, IResult { }

interface IUserContextSiteProps extends IUserContextStore {
    fetchUserInfo: () => void;
}

const Login = (props: IUserContextSiteProps) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let [code, setCode] = React.useState(
        searchParams.get("code")
    );
    const [model, setLoginModel] = React.useState({ email: "", password: "", rememberMe: false, isSuccessed: null, message: null, messageValues:null } as ILoginForm);
    const [emailConfirm, setEmailConfirm] = React.useState({ isSuccessed: null, message: null } as IResult);
    const intl = useIntl();
    const userContext = React.useContext<IUserContext>(UserContext);

    React.useEffect(() => {
        const decodedData = decodeParameter(code);
        if (decodedData)
            setLoginModel({ ...model, email: decodedData[0], password: decodedData[1] });
    }, []);

    React.useEffect(() => {
        if (model.email && model.password && code)
            submitForm();
    }, [model.email, model.password]);


    const submitForm = () => {
        setLoginModel({ ...model, message: "loading" });
        UserAccountAPI.loginUser(model)
            .then((response: Response) => {
                if (!response.ok) {
                    setLoginModel({ ...model, isSuccessed: false, message: "account.login.invalid.data" });
                    return Promise.reject(response.statusText);
                }
                return response;
            })
            .then((response: Response) => response.json())
            .then((loginResult: IResult) => {
                setLoginModel({ ...model, isSuccessed: loginResult.isSuccessed, message: loginResult.message, messageValues: loginResult.messageValues });
                if (loginResult.isSuccessed)
                    props.fetchUserInfo();
            });
    };

    const resendEmail = () => {
        UserAccountAPI.resendEmail(model.email)
            .then((response: Response) => {
                if (!response.ok) {
                    setEmailConfirm({ ...emailConfirm, isSuccessed: false });
                    setLoginModel({ ...model, message: "email.confirmed.error" });
                    return Promise.reject(response.statusText);
                }
                return response;
            })
            .then((response: Response) => response.json())
            .then((result: IResult) => {
                if (result.isSuccessed) {
                    setEmailConfirm({ ...emailConfirm, isSuccessed: true, });
                    setLoginModel({ ...model, message: "email.confirmed.check" });
                }
            });
    };

    if (props.isAuthenticated && props.isEmailConfirmed)
        return (<Navigate to="/main" />);

    return (
        <div className={"login animation-box " + props.style}>
            <InfoMenu />
            <div className="wrapper fadeInDown">
                <div className={"logo-box " + props.applicationName} />
                <div className="formContent">
                    {(model.message === "loading"
                        ? <Spinner />
                        :
                        <>
                            <Form>
                                <Form.Label><FormattedMessage id="page.login.description" /></Form.Label>
                                {(model.isSuccessed === false ?
                                    <Form.Control.Feedback type={emailConfirm.isSuccessed ? "valid" : "invalid"} className="login-message">
                                        {(model.message != null ? <FormattedMessage id={model.message} values={model.messageValues} /> : null)}
                                        {(model.message === "account.login.email.confirmation" ?
                                            <Button type="button" variant="success" onClick={resendEmail}>
                                                <FormattedMessage id="email.confirmed.again" />
                                            </Button> : null)}
                                    </Form.Control.Feedback>
                                    : null)}
                                <Form.Group controlId="login">
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={model.email}
                                        onInput={(e: any) => setLoginModel({ ...model, email: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={model.password}
                                        onInput={(e: any) => setLoginModel({ ...model, password: e.target.value })}
                                        onKeyDown={(e: any) => {
                                            if (e.key === "Enter")
                                                submitForm();
                                        }}
                                    />
                                </Form.Group>
                                <Button variant="success" type="button" onClick={submitForm} disabled={!(model.email && model.password)}>
                                    <FormattedMessage id="page.login.title" />
                                </Button>
                            </Form>
                            {userContext && userContext.externalAuthenticationEnabled && props.externalLogins
                                ? <>
                                    <div className="or"> <label><FormattedMessage id="page.login.or" /></label> </div>
                                    <form action="/Account/ExternalLogins" method="post" >
                                        {(props.externalLogins || []).map(p => (
                                            <div>
                                                <input type="hidden" name="loginProvider" value={p.name} required />
                                                <input type="hidden" name="returnUrl" value={props.redirectUrl} required />
                                                <input className={p.name.toLowerCase() + "-provider"} type="submit" value=""
                                                    title={intl.formatMessage({ id: "page.login.useAccount" }, { "0": p.displayName })}
                                                />
                                            </div>)
                                        )}
                                    </form>
                                </>
                                : null
                            }
                        </>
                    )}
                </div>
                <div className="formFooter">
                    <div className="link-container">
                        <Link className="underlineHover" to="/forgotpassword"><FormattedMessage id="page.login.forgotPassword" /></Link>
                    </div>
                    <div className="link-container">
                        <Link className="underlineHover" to="/registration"><FormattedMessage id="page.login.gotoRegister" /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(
    (state: IAppStore) => state.userInfo,
    (dispatch: any) => bindActionCreators(UserActionCreators, dispatch)
)(Login);


function decodeParameter(parameter: string): string[] {
    if (parameter) {
        const decoded = atob(parameter);
        const separatorIndex = decoded.indexOf(";");
        if (separatorIndex > 0) {
            const login = decoded.substring(0, separatorIndex);
            const password = decoded.substring(separatorIndex + 1);
            return [login, password];
        }
    }
    return null;
}
