import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceActionCreators } from "actions/DeviceActionCreators";
import { ModeDeviceParametersView, Sampling} from "constants/Constants";
import { IGaugeParameter } from "constants/DeviceConstants";
import "../../less/DeviceParameters.css";
import { IAppStore } from "store/configureStore";
import Spinner from "../Spinner";
import DeviceNoData from "./DeviceNoData";
import DeviceParameterGauges from "./DeviceParameterGauges";
import DeviceParametersTab from "./DeviceParametersTab";
import DeviceParameterSmogChart from "./Smog/DeviceParameterSmogChart";
import {IsSmogDevice} from "constants/Common";
import DeviceContext, {IDeviceContext} from "../DeviceContext";

interface IDeviceParameterProps {
    sampling?: Sampling;
    parameters: IGaugeParameter[];
    mode: ModeDeviceParametersView;
    fetchDeviceParameters: (deviceId: number, mode: ModeDeviceParametersView) => void;
    message: string;
}

const DeviceParameters = (props: IDeviceParameterProps) => {
    const deviceContext = React.useContext<IDeviceContext>(DeviceContext);
    useEffect(() => {
        props.fetchDeviceParameters(deviceContext.deviceId, deviceContext.parameterId ? "Collapse" : "Expand");
    }, [deviceContext.deviceId, deviceContext.parameterId]);

    if (props.message === "loading" && props.mode === "Expand") { return (<Spinner />); }

    const hasData = (props.parameters || []).length > 0;
    const mode = props.mode;
    const isSmog = IsSmogDevice(props.parameters);

    if(hasData)
        return (
            <div className={"device-parameters" + (props.mode === "Expand" ? " border-top" : "")}>
                {
                    mode === "Expand"
                        ? (isSmog
                            ? <DeviceParameterSmogChart/>
                            : <DeviceParameterGauges parameters={props.parameters} />)
                        : <DeviceParametersTab parameters={props.parameters} />
                }
            </div>
        );

    return (<DeviceNoData />);
}


function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        selectedParameterId: ownProps.selectedParameterId,
        sampling: null as Sampling,
        deviceId: ownProps.deviceId,
        parameters: state.deviceParameters.parameters,
        mode: state.deviceParameters.mode,
        message: state.deviceParameters.message
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(DeviceParameters);
