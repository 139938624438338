import { LinkContainer } from "react-router-bootstrap";
import { MeasurableParameter } from "constants/Constants";
import { getParameterIcon } from "constants/Common";
import { IGaugeParameter } from "constants/DeviceConstants";
import * as React from "react";
import ParameterName from "components/Device/ParameterName";

const DeviceParametersTab = (props: { parameters: IGaugeParameter[] }) => (
    <div className="parameters-collapse">
        {
            (props.parameters || []).map((p: IGaugeParameter) =>
                <LinkContainer to={`/device/${p.deviceId}/${MeasurableParameter[p.parameterId]}`} key={`key_${p.parameterId}`}>
                    <div className="btn-tab">
                        <span className={getParameterIcon(p.parameterId)} /> &nbsp;
                        <ParameterName parameter={p} />
                    </div>
                </LinkContainer>)
        }
    </div >
);

export default DeviceParametersTab;