import { IActionType } from "../constants/Constants";

export const getContent = (response: Response) : Promise<any> => {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
    } else {
        return response.text();
    }
}

export default function promiseDispatch<T>(
    dispatch: any,
    action: () => Promise<Response>,
    types: IActionType,
    successFunction: () => void = null
){
    const { request, success, failure } = types;
    if (request) {
        dispatch({ type: request });
    }
    action()
        .then((response: Response) => {
            if (!response.ok)
                throw Error(response.statusText);
            return response;
        })
        .then((response: Response) => {
            return getContent(response);
        })
        .then((items: T) => {
            dispatch({ type: success, payload: items });
            if (successFunction)
                successFunction();
            return items;
        })
        .catch(() => {
            dispatch({ type: failure });
        });
};

export function promiseResult<T = void>(
    action: () => Promise<Response>,
    successFunction: (items: T) => void,
    failureFunction: () => void,
) {
    action()
        .then((response: Response) => {
            if (!response.ok)
                throw Error(response.statusText);
            return response;
        })
        .then((response: Response) => {
            return getContent(response);
        })
        .then((items: T) => {
                successFunction(items);
            return items;
        })
        .catch(() => {
            failureFunction();
        });
}