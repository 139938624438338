import * as moment from "moment";
import * as React from "react";
import { useState } from "react";
import { DateTimeSecFormat } from "constants/Constants";
import { IDataRecord } from "constants/DeviceConstants";
import "../../../less/DeviceData.css";
import {FormattedMessage, useIntl} from "react-intl";

interface IDeviceGridRowProps {
    thresholdMin?: number;
    thresholdMax?: number;
    parameterMaxValue?: number;
    parameterMinValue?: number;
    dataRecord: IDataRecord;
    onDeleteClick: (record: IDataRecord) => void;
    deleteButtonEnabled?: boolean;
}

const DeviceGridRow = (props: IDeviceGridRowProps) => {
    const intl = useIntl();
    const [deleteButton, updateState] = useState(false);
    const deleteRecordEvent = () => { props.onDeleteClick(props.dataRecord); };
    
    const parameterRange = [props.parameterMinValue, props.parameterMaxValue].sort();
    const parameterMinValue = parameterRange[0];
    const parameterMaxValue = parameterRange[1];
    
    const thresholdMaxAlert = (props.thresholdMax !== null && props.dataRecord.value > props.thresholdMax);
    const thresholdMinAlert = (props.thresholdMin !== null && props.dataRecord.value < props.thresholdMin);
    const dataWarningAlert = (parameterMinValue !== null && parameterMinValue >= props.dataRecord.value - 0.1) || (parameterMaxValue !== null && parameterMaxValue <= props.dataRecord.value + 0.1) 

    const color = (dataWarningAlert ? "hidden" :
        thresholdMaxAlert ? "threshold-max"
            : thresholdMinAlert ? "threshold-min"
                : "hidden");
    
    const warning = dataWarningAlert ? "data-warning" : "hidden";
    return (
        <tr className="grid-row"
            onMouseMove={() => updateState(true)}
            onMouseLeave={() => updateState(false)}>
            <td>{deleteButton && props.deleteButtonEnabled ?
                <div className="remove" onClick={deleteRecordEvent}>
                    <i className="material-icons"> delete_forever </i>
                </div> : null} 
            </td>
            <td className="grid-row-date">
                {moment.utc(props.dataRecord.date).local().format(DateTimeSecFormat)}
            </td>
            <td>
                {props.dataRecord.value.toFixed(1)}
            </td>
            <td>
                <span className={color}> <i className="material-icons" title={intl.formatMessage({ id: "device.grid.alert" })}> error </i></span>
                <span className={warning}> <i className="material-icons" title={intl.formatMessage({ id: "device.grid.warning" })}> warning </i></span>

            </td>
        </tr>
    );
};

export default DeviceGridRow;