import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { SamplingMap } from "constants/Common";
import { IDeviceDetails, IEditedDevice } from "constants/DeviceConstants";
import Radio from "../../../controles/Radio";
import ChangeStateSign from "./ChangeStateSign";


const DeviceDetailsSamplingRadio = (
    props: {
        editForm: IEditedDevice,
        device: IDeviceDetails
        handleInputChange: (event: React.FormEvent<any>) => void
    }
) => {
    return (<FormGroup controlId="formHorizontalSampling" className="sampling">
        <Row>
            <Col className="label">
                <FormattedMessage id="device.edit.sampling" />
                <ChangeStateSign
                    hide={(props.device.previousSampling === null
                        && props.device.sampling === Number(props.editForm.sampling))
                        || props.device.previousSampling === Number(props.editForm.sampling)}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                {
                    [30, 60, 900, 1800, 3600, 43200, 86400].map(v =>
                        <Radio name="sampling" key={`radio_${v}`} value={v}
                            checked={Number(props.editForm.sampling) === v}
                            onChange={props.handleInputChange}>
                            {SamplingMap(v)}
                        </Radio>
                    )
                }
            </Col>
        </Row>
    </FormGroup>)
}

export default DeviceDetailsSamplingRadio;