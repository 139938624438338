import * as React from "react";
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const TechnicalBreak = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">
                    <h3>POLITYKA PRZERW TECHNICZNYCH</h3>
                    <ol>
                        <li>Usługodawca dokłada wszelkich starań, aby zapewnić prawidłowe i nieprzerwane funkcjonowanie Serwisu. Tak jak przy każdym systemie informatycznym, w naszym Serwisie mogą wystąpić awarie i błędy techniczne spowodowane kłopotami ze sprzętem bądź oprogramowaniem. W każdym przypadku Usługodawca stara się w jak największym stopniu ograniczyć negatywne skutki zaistniałych problemów technicznych.</li>
                        <li>Aby rozwijać serwis oraz minimalizować ryzyko wystąpienia awarii, konieczne są okresowe przerwy techniczne, w czasie których w systemie informatycznym wprowadzane są odpowiednie zmiany. Usługodawca  dba o to, aby przerwy techniczne nie były uciążliwe dla Użytkowników, w tym celu, w miarę możliwości, planuje je na godziny nocne.</li>
                    </ol>
                </div>
            </ScrollArea>
        </div>
    );

export default TechnicalBreak;