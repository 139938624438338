import * as React from "react";
import {FormattedMessage} from "react-intl";
import {IDeviceParameter} from "constants/DeviceConstants";

const ParameterName = (
    props: { parameter : IDeviceParameter }
) => {
    if(props.parameter.customName)
        return (<span>{props.parameter.customName}</span>);
    return (<FormattedMessage id={`device.chart.parameter_${props.parameter.parameterId}`} />);
}

export default ParameterName;