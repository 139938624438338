import {ChangeEvent, useEffect, useReducer, useState} from "react";
import {Button, ButtonGroup, Form} from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {IBackgroundImage, PlannerDeviceLimits} from "../../constants/PlannerDeviceConstants";

interface IImageSizeValidator {
    fileSizeInKb: number,
}

export const BackgroundButton = (props: {
    onUploadBackground: (backgroundImage: IBackgroundImage) => void
    onDeleteBackground: () => void,
    disabled: boolean
}) => {
    const [showFileInput, setShowFileInput] = useState(false);
    const [sizeAlert, setSizeAlert] = useState<IImageSizeValidator>(null);

    const intl = useIntl();

    useEffect(() => {
        return () => {
            setShowFileInput(false)
        };
    }, []);

    const reader = new FileReader();
    reader.onloadend = function () {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = function (event: any) {
            const height = event.target.height;
            const width = event.target.width;
            props.onUploadBackground({content: reader.result as string, height: height, width: width});
            setShowFileInput(false);
        };
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const fileSizeInKb = file.size / 1024;
            if (fileSizeInKb <= PlannerDeviceLimits.BackgroundImageSizeLimit) {
                reader.readAsDataURL(file);
            } else {
                setSizeAlert({fileSizeInKb});
            }
        }
    };

    const SizeAlert = () => {
        return (
            <Alert show={sizeAlert != null}
                   variant="danger"
                   style={{width: "500px", position: "absolute", zIndex: "999", left: "35%", top: "60px"}}>
                <FormattedMessage id="space-planner.file-size.desc"
                                  values={{value: <b>{sizeAlert?.fileSizeInKb.toFixed(1)}</b>}}/>
                <br/>
                <FormattedMessage id="space-planner.file-limit.desc"
                                  values={{value: <b>{PlannerDeviceLimits.BackgroundImageSizeLimit}</b>}}/>
                <hr/>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setSizeAlert(null)} variant="outline-danger">
                        <FormattedMessage id="space-planner.close" />
                    </Button>
                </div>
            </Alert>)
    }

    return (<>
            <SizeAlert/>
            <ButtonGroup style={{marginBottom: "10px"}}>
                {showFileInput
                    ? (<Form.Group controlId="formFile">
                            <Form.Control
                                type="file"
                                style={{padding: "0", borderRadius: "3px 0 0 3px", width: "500px", display: "inline"}}
                                accept="image/png, image/jpeg"
                                onChange={handleFileChange}
                                disabled={props.disabled}
                            />
                            <Button style={{borderRadius: "0 4px 4px 0", height: "38px", marginTop: "-1px"}}
                                    onClick={() => setShowFileInput(false)}
                                    disabled={props.disabled}>
                        <span className="material-icons">
                            remove_circle_outline
                        </span>
                            </Button>
                        </Form.Group>
                    )
                    : (<>
                        <Button variant="primary" style={{height: "38px"}}
                                title={intl.formatMessage({
                                    id: "space-planner.add-background"
                                })}
                                onClick={() => setShowFileInput(true)}
                                disabled={props.disabled}>
                       <span className="material-icons">
                            image
                        </span>
                        </Button>
                        <Button variant="warning" style={{height: "38px"}}
                                title={intl.formatMessage({
                                    id: "space-planner.remove-background"
                                })}
                                onClick={() => props.onDeleteBackground()}
                                disabled={props.disabled}>
                        <span className="material-icons">
                            crop_free
                        </span>
                        </Button>
                    </>)}
            </ButtonGroup>
        </>
    );
}