import * as React from "react";
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const TosIndex = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">
                    <h3><i className="material-icons">assignment_turned_in</i>{" "}<FormattedMessage id="termOfService.title" /></h3>
                    <h4>§1 POSTANOWIENIA OGÓLNE</h4>
                    <ol>
                        <li> Niniejszy Regulamin określa ogólne warunki, zasady oraz sposób świadczenia Usług drogą elektroniczną, za pośrednictwem serwisu internetowego iPomiar, dostępnego  pod adresem internetowym <a href="http://ipomiar.pl">http://ipomiar.pl</a>. Serwis jest prowadzony i zarządzany przez Usługodawcę. </li>
                        <li> Regulamin określa zbiór norm i zasad regulujących świadczenie Usług przez Usługodawcę. Korzystanie z Serwisu może odbywać się wyłącznie na zasadach i w zakresie wskazanym w Regulaminie.</li>
                        <li> Kontakt z Usługodawcą odbywa się przez formularz kontaktowy dostępny pod adresem  <a href="http://ipomiar.pl/kontakt">http://ipomiar.pl/kontakt</a> , mailowo: info@ipomiar.pl oraz telefonicznie  (34) 39 30 015.</li>
                        <li> Treść Regulaminu jest nieprzerwanie i nieodpłatnie dostępna w witrynie internetowej <a href="http://ipomiar.pl/regulamin">http://ipomiar.pl/regulamin</a>, w sposób umożliwiający Użytkownikom jego pozyskanie, odczytanie i utrwalanie jego treści poprzez wydrukowanie lub zapisanie na nośniku w każdej chwili.</li>
                        <li> Wszelkie prawa do iPomiar, w tym majątkowe prawa autorskie, prawa własności intelektualnej do jego nazwy, domeny internetowej, strony internetowej iPomiar, a także do formularzy, logotypów i innych należą do Usługodawcy, a korzystanie z nich może nastąpić wyłącznie w sposób określony i zgodny z Regulaminem.</li>
                        <li> Usługodawca dopuszcza zamieszczanie na stronie internetowej Serwisu treści reklamowych dotyczących oferowanych Usług, jak i towarów i usług podmiotów trzecich. Korzystanie z takich ofert lub usług nie jest elementem Serwisu, a ich zasady określają odpowiednie podmioty trzecie.</li>
                        <li> Dodatkowe regulaminy mogą określać zasady świadczenia w ramach Serwisu innych usług.</li>
                    </ol>

                    <h4> §2 DEFINICJE </h4>

                    <h4> Terminy użyte w Regulaminie oznaczają: </h4>

                    <p><b>ABONAMENT</b> – stała opłata ponoszona i wybrana przez Użytkownika z tytułu zapewnienia Użytkownikowi stałego dostępu do Serwisu i możliwości korzystania przez Użytkownika  z usług świadczonych przez Usługodawcę;</p>

                    <p><b>AKTYWNE URZĄDZENIE</b> – Urządzenie, które posiada wykupiony abonament lub wysyła dane w ramach darmowego planu; </p>

                    <p><b>CENNIK</b> – cennik Usług świadczonych przez Usługodawcę, stanowiący wykaz Usług wraz z zestawieniem rodzaju i wysokości opłat oraz zasad ich naliczania;</p>

                    <p><b>KONTO</b> - bezpłatne konto prowadzone przez Usługodawcę indywidualnie dla każdego Użytkownika pod unikalną nazwą Użytkownika (login) będące zbiorem zasobów, na którym gromadzone są dane dotyczące Użytkownika, w tym informacje o jego działaniach w ramach Serwisu;</p>

                    <p><b>KONSUMENT</b> – Użytkownik będący osobą fizyczną korzystający z Serwisu albo zainteresowany korzystaniem z Serwisu,  będący konsumentem w rozumieniu art. 22[1] Kodeksu cywilnego (osoba fizyczna, która dokonuje czynności prawnej niezwiązanej bezpośrednio z prowadzoną przez siebie działalnością gospodarczą lub zawodową);</p>

                    <p><b>PRZEDSIĘBIORCA</b> - Użytkownik będący przedsiębiorcą w rozumieniu art. 43[1] Kodeksu cywilnego;</p>

                    <p><b>PRZETWARZANIE DANYCH</b> - jakiekolwiek operacje wykonywane na danych z Urządzeń, takie jak zbieranie, utrwalanie, przechowywanie, opracowywanie, zmienianie, udostępnianie i usuwanie oraz inne operacje, a zwłaszcza te, które wykonuje się w systemach informatycznych;</p>

                    <p><b>REGULAMIN</b> – niniejszy Regulamin;</p>

                    <p><b>REJESTRACJA</b> –  procedura zakładania Konta;</p>

                    <p><b>SERWIS</b> - serwis internetowy  iPomiar, dostępny  pod adresem internetowym <a href="http://ipomiar.pl">http://ipomiar.pl</a>, którego właścicielem i administratorem jest Usługodawca, w ramach którego Użytkownicy mogą korzystać z Usług oferowanych przez Usługodawcę;</p>

                    <p><b>USŁUGI</b> – usługi świadczone przez Usługodawcę w ramach Serwisu i udostępniane odpłatnie lub nieodpłatnie;</p>

                    <p><b>URZĄDZENIE</b>  - oferowany przez Usługodawcę sprzęt elektroniczny służący do zbierania, gromadzenia, prezentacji różnych danych – w zależności od rodzaju Urządzenia (temperatura, wilgotność, pozycja GPS, stan baterii, zasięg RSSI, inne), który zakupił Użytkownik od Usługodawcy;</p>

                    <p><b>USŁUGODAWCA</b> -  Jakuba Szczerba prowadzący działalność gospodarczą pod firmą Termipol, ul. Powstańców Śl. 64, 42-700 Lubliniec, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej (CEIDG) prowadzonej przez ministra właściwego ds. gospodarki, NIP 575-180-10-22, REGON 362191270;</p>

                    <p><b>UŻYTKOWNIK</b> – pełnoletnia osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której przepisy szczególne przyznają zdolność prawną, która dokonała prawidłowej Rejestracji, w wyniku której uzyskała dostęp do Usług świadczonych przez Usługodawcę w ramach Serwisu na zasadach określonych w Regulaminie;</p>

                    <p><b>ZAMÓWIENIE</b> – oświadczenie woli Użytkownika, zmierzające bezpośrednio do zawarcia umowy o świadczenie odpłatnych Usług, określające w szczególności rodzaj Abonamentu.</p>


                    <h4> §3 ZASADY KORZYSTANIA Z SERWISU </h4>
                    <ol>
                        <li> Korzystanie z Serwisu może odbywać się wyłącznie na zasadach i w zakresie wskazanym w Regulaminie.</li>
                        <li> Usługi świadczone są za pomocą strony internetowej na której loguje się Użytkownik drogą elektroniczną lub za pomocą Aplikacji iPomiar.</li>
                        <li>
                            Minimalne wymagania techniczne umożliwiające korzystanie z Serwisu:
            <ol type="a">
                                <li>urządzenie z dostępem do Internetu,</li>
                                <li>dostęp do poczty elektronicznej,</li>
                                <li>przeglądarka internetowa z włączoną obsługą JavaScript oraz plików cookies, (zalecana przeglądarka internetowa : Internet Explorer w wersji 11 lub nowszej, Firefox w wersji 28.0 lub nowszej lub Chrome w wersji 32 lub nowszej).</li>
                            </ol>
                        </li>
                        <li> Użytkownik może korzystać z usługi prowadzenia Konta w Serwisie pod warunkiem, że zakupił u Usługodawcy Urządzenie dedykowane do obsługi przez Serwis.</li>
                        <li> Usługodawca przyjmuje do przetwarzania dane przesyłane przez Urządzenia poprawnie zainstalowane w Serwisie i poprawnie aktywowane przez Użytkownika. O poprawności zainstalowania Urządzenia w Serwisie świadczy jego widoczność i funkcjonalność w Serwisie.</li>
                        <li> Usługodawca przetwarza dane z Urządzeń gdy Urządzenie jest Aktywne w jego bazie danych (Aktywne Urządzenie).</li>
                        <li> Historia danych w Serwisie  jest dostępna wyłącznie dla aktywnych Urządzeń. W zależności od wybranego Abonamentu, Użytkownik ma dostęp do historycznych danych z określonego czasookresu – 24 miesiące . Dane archiwalne, nieobjęte Abonamentem, po 3 miesiącach od momentu zakończenia Abonamentu mogą zostać bezpowrotnie usunięte z Serwisu, a Usługodawca nie będzie ponosił żadnej odpowiedzialności z tego tytułu. Przy wyborze „pakietu FREE”  dane będą usuwane niezwłocznie jeśli Użytkownik nie opłaci abonamentu na kolejny miesiąc.</li>
                        <li> Korzystanie z Serwisu oznacza, że Użytkownik upoważnia Usługodawcę do przetwarzania danych, jakie są potrzebne do prawidłowego świadczenia Usług.</li>
                        <li> Użytkownik nie może ingerować w Urządzenie w sposób niezgodny z instrukcją obsługi załączoną do Urządzenia lub dostępną na stronie internetowej Usługodawcy. W przypadku podejmowania przez Użytkownika prób takich działań albo dokonania faktycznego naruszenia Urządzenia, Usługodawca nie będzie ponosił odpowiedzialności za jakość danych przesyłanych do Serwisu, w tym za prezentowane wyniki i pomiary. Przywrócenie poprawnego funkcjonowania Serwisu i Konta Użytkownika może wiązać się z koniecznością zakupu nowego Urządzenia.</li>
                        <li> Zabronione jest korzystanie przez Użytkownika z Serwisu lub Usług w sposób naruszający przepisy prawa, dobre obyczaje, dobra osobiste osób trzecich lub uzasadnione interesy Usługodawcy, a w szczególności dostarczanie treści o charakterze bezprawnym.</li>
                        <li> Zawarcie umowy o świadczenie odpłatnych Usług następuje w procesie składania Zamówienia poprzez wybranie Abonamentu i zobowiązanie się do zapłaty poprzez kliknięcie  w „Zamówienie z obowiązkiem zapłaty”. Po opłaceniu Zamówienia Użytkownik otrzymuje dostęp do odpłatnych Usług świadczonych przez Usługodawcę.</li>
                    </ol>

                    <h4> §4 PRAWA I OBOWIĄZKI UŻYTKOWNIKÓW </h4>
                    <ol>
                        <li>
                            Do obowiązków Użytkownika należy:
            <ol type="a">
                                <li> prawidłowa (zgodna z instrukcją obsługi) instalacja Urządzenia;</li>
                                <li> zapewnienie poprawnej pracy Urządzenia przez m.in. wymianę baterii, doładowanie karty SIM, poprawną konfigurację sieci WiFi, aktualizację aplikacji itp;</li>
                                <li> właściwa konserwacja Urządzenia;</li>
                                <li> niedostarczanie treści o charakterze bezprawnym;</li>
                                <li> niekorzystanie z Serwisu w sposób naruszający przepisy prawa lub postanowienia niniejszego Regulaminu, dobre obyczaje, dobra osobiste osób trzecich lub uzasadnione interesy Usługodawcy, a w szczególności niedostarczanie treści o charakterze bezprawnym;</li>
                                <li> poszanowanie dóbr osobistych oraz innych praw, w tym praw własności intelektualnej osób trzecich;</li>
                                <li> wprowadzanie do Serwisu danych zgodnych ze stanem faktycznym;</li>
                                <li> niestosowanie urządzeń, oprogramowania oraz metod mogących zakłócić działanie Serwisu, a także  w sposób uciążliwy dla Usługodawcy oraz innych Użytkowników.</li>
                            </ol>
                        </li>
                        <li> Użytkownik nie może prowadzić działań, których celem jest  obciążanie skrzynek odbiorczych innych Użytkowników lub Usługodawcy.  Wysyłanie wiadomości reklamowych jest niedopuszczalne.</li>
                        <li> Użytkownik wyraża zgodę na wykorzystanie przez Usługodawcę danych wprowadzonych przez Użytkownika i umieszczonych w jego Koncie do obsługi złożonego Zamówienia, a Usługodawca zobowiązuje się do ochrony danych osobowych Użytkownika i ich nieujawniania osobom trzecim.  Po zrealizowaniu Zamówienia dane osobowe Użytkownika zostaną usunięte, i mogą być przechowywane najdłużej do czasu wygaśnięcia ewentualnych roszczeń Użytkowników wynikających z umowy o świadczenie usług. Użytkownik wyraża zgodę na nieodpłatne zamieszczanie w sieci Internet opinii Użytkownika na temat Usługodawcy i funkcjonalności Systemu.</li>
                    </ol>

                    <h4> §5 USŁUGI </h4>
                    <ol>
                        <li>
                            1.	Zakres Usług świadczonych za pośrednictwem Serwisu obejmuje:
            <ol type="a">
                                <li>odbiór, zbieranie, gromadzenie i przechowywanie, wyświetlanie, prezentacja różnych danych – w zależności od rodzaju Urządzenia (temperatura, wilgotność, pozycja GPS, stan baterii, zasięg RSSI, inne)- usługa odpłatna;</li>
                                <li>prowadzenie Konta – usługa nieodpłatna;</li>
                                <li>umożliwienie zamieszczania opinii – usługa nieodpłatna;</li>
                                <li>udostępnianie interaktywnego formularza umożliwiającego kontakt z Usługodawcą – usługa nieodpłatna;</li>
                                <li>udostępnienie Aplikacji iPomiar – usługa nieodpłatna.</li>
                            </ol>
                        </li>
                        <li>Usługa o której mowa w pkt 1 a powyżej dostępna jest po opłaceniu Abonamentu. Umowa o świadczenie odpłatnych Usług zawierana jest  na czas oznaczony i ulega rozwiązaniu z chwilą zakończenia okresu trwania określonego w Abonamencie lub wykorzystaniu dostępnej w ramach  Abonamentu ilości pomiarów. Wówczas dostęp do Konta zostaje zablokowany. Wznowienie dostępu do Konta możliwe jest po opłaceniu Abonamentu, z zastrzeżeniem § 3 ust 5.</li>
                        <li>Usługa prowadzenia Konta w Serwisie Internetowym dostępna jest po dokonaniu rejestracji. Rejestracja dokonywana jest po wypełnieniu i zaakceptowaniu formularza rejestracyjnego, udostępnianego w Serwisie. Do rejestracji wymagane jest wprowadzenie następujących danych: adres e-mail, hasło.</li>
                        <li>Umowa o świadczenie usługi polegającej na prowadzeniu Konta w Serwisie zawierana jest na czas nieoznaczony i ulega rozwiązaniu z chwilą przesłania przez Użytkownika żądania usunięcia Konta. Usługodawca zastrzega sobie możliwość usuwania kont Użytkowników nieaktywnych Urządzeń.</li>
                        <li>Użytkownik ma możliwość wysyłania za pomocą formularza kontaktowego wiadomości do Usługodawcy. Umowa o świadczenie usługi polegającej na udostępnianiu interaktywnego formularza umożliwiającego Użytkownikom kontakt z Usługodawcą jest zawierana na czas oznaczony i ulega rozwiązaniu z chwilą udzielenia odpowiedzi przez Usługodawcę.</li>
                        <li>Użytkownik ma możliwość zamieszczania w Serwisie indywidualnych i subiektywnych opinii odnoszących się m.in. do usług Serwisu. Umowa o świadczenie usługi zamieszczania opinii zawierana jest na czas określony i ulega rozwiązaniu z chwilą jej dodania w Serwisie.</li>
                        <li>Użytkownik dodając opinię oświadcza, że posiada wszelkie prawa do tych treści, a w szczególności autorskie prawa majątkowe.</li>
                        <li>Treść opinii powinna być zgodna z obowiązującymi przepisami prawa oraz dobrymi obyczajami.</li>
                        <li>Usługodawca jest uprawniony do nie zamieszczenia wypowiedzi lub usuwania wypowiedzi, które są niezgodne ze stanem faktycznym, sprzeczne z prawem, naruszają prawa osób trzecich lub Usługodawcy (w szczególności dobra osobiste, prawa autorskie, prawa pokrewne, prawa własności przemysłowej lub inne) lub sprzeczne z zasadami współżycia społecznego lub dobrymi obyczajami.</li>
                        <li>Poprzez zamieszczenie wypowiedzi Użytkownik wyraża zgodę na nieodpłatne korzystanie z tej wypowiedzi oraz jej publikowanie przez Usługodawcę, a także dokonywanie opracowań utworów w rozumieniu ustawy o prawie autorskim i prawach pokrewnych.</li>
                        <li> W przypadku naruszenia przez Użytkownika postanowień niniejszego Regulaminu, Sprzedawca po uprzednim wezwaniu do zaprzestania lub usunięcia naruszeń, może rozwiązać umowę o świadczenie Usług z zachowaniem 14-dniowego okresu wypowiedzenia.</li>
                    </ol>
                    <h4> §6  OPŁATY </h4>
                    <ol>
                        <li> Niektóre Usługi świadczone przez Usługodawcę w ramach iPomiar są odpłatne.</li>
                        <li> Wszelkie zasady dotyczące opłat i płatności opisane są w Załączniku nr 1 do niniejszego Regulaminu - „Opłaty”.</li>
                        <li> Ceny Towarów podawane są w złotych polskich. W Cenniku zamieszczona jest cena netto i brutto.</li>
                        <li>
                            Użytkownik może wybrać następujące metody płatności:
            <ol type="a">
                                <li>przelew na rachunek bankowy wskazany w informacji przesłanej Użytkownikowi;</li>
                                <li>płatność elektroniczna.</li>
                            </ol>
                        </li>
                        <li>Użytkownik jest zobowiązany dokonać płatności za odpłatną Usługę w terminie 14 dni od złożenia Zamówienia. W przypadku braku płatności w terminie, o którym mowa w zdaniu poprzednim, Usługodawca może odstąpić od umowy sprzedaży usług na podstawie art. 491 Kodeksu Cywilnego pod warunkiem uprzedniego bezskutecznego wezwania Użytkownika do usunięcia naruszeń z wyznaczeniem stosownego terminu.</li>
                        <li>Na podstawie danych zamieszczonych  na Koncie Użytkownika wystawiane będą Użytkownikowi faktury VAT.</li>
                        <li>Faktury doręczane będą Użytkownikowi drogą elektroniczną na adres mail podany podczas zakładania Konta.</li>
                        <li>Opłaty za usługi świadczone przez Usługodawcę w ramach Serwisu uiszczone będą  przez Użytkownika na wskazany w fakturze rachunek bankowy Usługodawcy.</li>
                        <li>Brak opłaty za Usługę dla przyporządkowanego dla Użytkownika Urządzenia, które posiada aktywny abonament, powoduje zablokowanie dostępu do niektórych funkcji Serwisu tj. do odpłatnych funkcji.</li>
                        <li>Odblokowanie dostępu do funkcji dostępnych w abonamencie nastąpi po wniesieniu opłaty za bieżący okres rozliczeniowy, z zastrzeżeniem postanowień § 3 pkt 7.</li>
                    </ol>
                    <h4> §7 PRAWO DO ODSTĄPIENIA OD UMOWY. </h4>
                    <ol>
                        <li> Konsument może w terminie 14 dni odstąpić od Umowy sprzedaży lub od Umowy o świadczenie usługi prowadzenia Konta, bez podania jakiejkolwiek przyczyny poprzez złożenie stosownego oświadczenia.</li>
                        <li> Bieg terminu określonego w ust. 1 rozpoczyna się od dostarczenia Towaru Konsumentowi lub w przypadku Umowy o świadczenie Usług - od dnia jej zawarcia.</li>
                        <li> W przypadku Umowy, która obejmuje wiele Towarów, które są dostarczane osobno, partiami lub w częściach, termin wskazany w ust. 1 biegnie od dostawy ostatniej rzeczy, partii lub części.</li>
                        <li> Do zachowania terminu odstąpienia od Umowy wystarczy wysłanie przez Konsumenta oświadczenia przed upływem  14-dniowego terminu. Oświadczenie o odstąpieniu od Umowy może być sformułowane przez Użytkownika samodzielnie, albo może być to  wzór oświadczenia o odstąpieniu od Umowy, który stanowi Załącznik nr 6 do Regulaminu. Pouczenie o prawie Konsumenta do odstąpienia od Umowy stanowi załącznik nr 7.</li>
                        <li> Oświadczenie może być wysłane za pomocą poczty tradycyjnej, przez przesłanie oświadczenia na adres e-mail Usługodawcy lub złożone osobiście.</li>
                        <li> Usługodawca z chwilą otrzymania oświadczenia w formie elektronicznej o odstąpieniu od Umowy przez Konsumenta prześle na adres poczty elektronicznej Konsumenta potwierdzenie otrzymania oświadczenia o odstąpieniu od Umowy.</li>
                        <li>
                            Skutki odstąpienia od Umowy:
            <ol type="a">
                                <li>W przypadku odstąpienia od Umowy zawartej na odległość Umowę uważa się za niezawartą;</li>
                                <li>W przypadku odstąpienia od Umowy Usługodawca zwraca Konsumentowi niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od Umowy, wszystkie dokonane przez niego płatności, w tym koszty dostarczenia rzeczy, z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Konsumenta sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez Usługodawcę;</li>
                                <li>Zwrotu płatności Usługodawca dokona przy użyciu takich samych metod płatności, jakich użył  Konsument  w pierwotnej transakcji, chyba że Konsument wyraźnie zgodził się na inny sposób zwrotu, przy czym sposób ten nie będzie się wiązał dla Konsumenta z żadnym kosztem.;</li>
                                <li>Zakupiony Towar należy zwrócić na adres Usługodawcy. Konsument powinien odesłać Towar na adres Usługodawcy podany w niniejszym Regulaminie niezwłocznie, nie później niż 14 dni od dnia, w którym poinformował Usługodawcę o odstąpieniu od Umowy. Termin zostanie zachowany, jeśli Konsument odeśle Towar przed upływem terminu 14 dni;</li>
                                <li>Usługodawca wstrzyma się ze zwrotem płatności do czasu otrzymania Towaru z powrotem lub do czasu dostarczenia mu dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej;</li>
                                <li>Konsument odpowiada tylko za zmniejszenie wartości Towaru wynikające z korzystania z niego w sposób inny niż było to konieczne do stwierdzenia charakteru, cech i funkcjonowania Towaru;</li>
                            </ol>
                        </li>
                        <li>
                            Prawo do odstąpienia od Umowy zawartej na odległość nie przysługuje Konsumentowi w następujących przypadkach:
            <ol type="a">
                                <li>
                                    świadczenia usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od Umowy;
                </li>
                                <li>
                                    w której cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którymi przedsiębiorca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od Umowy;
                </li>
                                <li>
                                    w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb;
                </li>
                                <li>
                                    w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia;
                </li>
                                <li>
                                    w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;
                </li>
                                <li>
                                    w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami;
                </li>
                                <li>
                                    w której przedmiotem świadczenia są napoje alkoholowe, których cena została uzgodniona przy zawarciu Umowy sprzedaży, a których dostarczenie może nastąpić dopiero po upływie 30 dni i których wartość zależy od wahań na rynku, nad którymi przedsiębiorca nie ma kontroli;
                </li>
                                <li>
                                    w której konsument wyraźnie żądał, aby przedsiębiorca do niego przyjechał w celu dokonania pilnej naprawy lub konserwacji; jeżeli przedsiębiorca świadczy dodatkowo inne usługi niż te, których wykonania konsument żądał, lub dostarcza rzeczy inne niż części zamienne niezbędne do wykonania naprawy lub konserwacji, prawo odstąpienia od Umowy przysługuje konsumentowi w odniesieniu do dodatkowych usług lub rzeczy;
                </li>
                                <li>
                                    w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu;
                </li>
                                <li>o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem Umowy o prenumeratę;</li>
                                <li>
                                    zawartej w drodze aukcji publicznej;
                </li>
                                <li>
                                    o świadczenie usług w zakresie zakwaterowania, innych niż do celów mieszkalnych, przewozu rzeczy, najmu samochodów, gastronomii, usług związanych z wypoczynkiem, wydarzeniami rozrywkowymi, sportowymi lub kulturalnymi, jeżeli w Umowie oznaczono dzień lub okres świadczenia usługi;
                </li>
                                <li>o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od Umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od Umowy.</li>
                            </ol>
                        </li>
                    </ol>

                    <h4> §8  WYŁACZENIE ODPOWIEDZIALNOŚCI USŁUGODAWCY WZGLĘDEM UŻYTKOWNIKÓW </h4>
                    <ol>
                        <li>Usługodawca nie odpowiada za szkody wyrządzone osobom trzecim jakie mogą powstać w wyniku niezgodnego z prawem lub celem, zastosowania Urządzeń i danych przez nie zebranych.</li>
                        <li>Usługodawca nie odpowiada za problemy z użytkowaniem Serwisu wynikające z niedostępności sieci internetowej lub sieci GSM po stronie Użytkownika.</li>
                        <li>Usługodawca nie odpowiada za szkody wynikające z okresowych awarii jakie mogą wystąpić po stronie Usługodawcy w postaci awarii serwerów, przerw w dostawie prądu czy przerw w dostępie do sieci Internet.</li>
                        <li>Usługodawca nie odpowiada za problemy w transmisji danych z Urządzeń na serwer Usługodawcy wynikające z nieprawidłowego funkcjonowania sieci GSM lub sieci internetowej. W szczególności nie odpowiada za awarie i takie zmiany w konfiguracji sieci GSM lub konfigurację sieci internetowej, które uniemożliwiają prawidłową transmisję danych.</li>
                        <li>Usługodawca nie odpowiada za szkody powstałe w wyniku błędnego działania oprogramowania urządzeń podłączonych do Serwisu lub oprogramowania serwerowego. W szczególności Usługodawca nie odpowiada za skutki prawne i faktyczne za czynności jakie wykonał Użytkownik, a które nie są zgodne z czynnościami opisanymi w instrukcji obsługi.</li>
                        <li>Wyłączając Klientów będących Konsumentami, Usługodawca nie odpowiada za braki w danych spowodowane zdarzeniami losowymi lub okolicznościami technicznymi na jakie nie ma wpływu (a  w tym m .in.: awarie sieci GSM, awarie sieci internetowej,  awarie i przerwy w dostawie energii elektrycznej, awarie instalacji elektrycznej, awarie sieci informatycznej i sprzętu komputerowego,  nieprawidłowy montaż Urządzeń,  nieprawidłowe funkcjonowanie  WiFi, GPRS, GPS,  siła wyższa). Ograniczenie odpowiedzialności względem Konsumentów jest o tyle możliwe, o ile zezwalają na to obowiązujące przepisy prawa.</li>
                    </ol>

                    <h4> § 9  OCHRONA DANYCH OSOBOWYCH </h4>
                    <ol>
                        <li>Administratorem danych osobowych Użytkowników zbieranych za pośrednictwem Serwisu jest Usługodawca. Dane osobowe Użytkowników zbierane są wyłącznie w celu realizacji umowy o świadczenie usług.</li>
                        <li>Podane przez Użytkowników dane osobowe Usługodawca zbiera i przetwarza zgodnie z obowiązującymi przepisami prawa oraz zgodnie z Polityką Prywatności, która stanowi Załącznik nr 5 do Regulaminu.</li>
                        <li>Usługodawca zbiera dobrowolnie podane przez Użytkowników informacje. Usługodawca może też zapisać informacje o parametrach połączenia jak np. adres IP w celach technicznych, związanych z administracją serwerami oraz wykorzystać je w celach bezpieczeństwa.</li>
                        <li>Dane osobowe zbierane przez Usługodawcę są przetwarzane zgodnie z przepisami ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. 1997 nr 133 poz. 883 z późn. zm) i ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. 2002 nr 144 poz. 1204 z późn. zm.) wyłącznie w celu realizacji usług świadczonych drogą elektroniczną przez Usługodawcę.</li>
                        <li>Usługodawca dokłada szczególnych starań w celu ochrony prywatności i informacji mu przekazanych, a dotyczących Użytkowników Serwisu. Usługodawca z należytą starannością dobiera i stosuje odpowiednie środki techniczne, w tym o charakterze informatycznym i organizacyjnym. Usługodawca stosuje środki zapewniające ochronę przetwarzanych danych, w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, przed ich ujawnieniem, utraceniem i zniszczeniem, a także przed nieuprawnioną modyfikacją, jak również przed ich przetwarzaniem z naruszeniem obowiązujących przepisów prawa.</li>
                        <li>Podanie danych osobowych przez Użytkowników jest dobrowolne. Użytkownicy mają prawo dostępu do swoich danych osobowych, ich poprawiania, uzupełniania lub usunięcia, z zastrzeżeniem zdania następnego. W szczególnych przypadkach Usługodawca może odmówić usunięcia danych, jeżeli Użytkownik swoim dotychczasowym zachowaniem w Serwisie  naruszył postanowienia niniejszego Regulaminu bądź obowiązujące przepisy prawa, a zachowanie danych jest niezbędne do wyjaśnienia tych okoliczności i ustalenia odpowiedzialności Użytkownika.</li>
                        <li>W przypadku zmiany danych Użytkownika, powinien on dokonać ich modyfikacji w Serwisie.</li>
                        <li>Usługodawca może powierzyć przetwarzanie danych osobowych Użytkowników podmiotom trzecim realizującym niektóre usługi, a w szczególności realizację dostawy zamówienia, realizację płatności.</li>
                    </ol>

                    <h4> §10  REKLAMACJE </h4>
                    <ol>
                        <li>Umową Sprzedaży objęte są nowe Towary, a Usługodawca jest obowiązany dostarczyć Użytkownikowi rzecz wolną od wad.</li>
                        <li>Usługodawca odpowiada wobec Użytkownika będącego Konsumentem z tytułu rękojmi za wady na zasadach określonych w art. 556 – 576 Kodeksu Cywilnego. </li>
                        <li>Użytkownik może zgłosić reklamację dotyczącą m.in. jakości, ilości, ceny lub rozliczenia Zamówienia,  warunków dostawy albo dotyczącą funkcjonowania sklepu w formie pisemnej. Reklamację można zgłaszać pisemnie, w formie listu poleconego, na adres Usługodawcy, pocztą tradycyjną lub mailową albo za pomocą formularza kontaktowego.</li>
                        <li>Celem rozpatrzenia reklamacji Towaru, Użytkownik powinien przesłać lub dostarczyć reklamowany Towar, o ile to możliwe - dołączając do niego dowód zakupu. Towar należy dostarczyć lub przesłać na adres Usługodawcy.</li>
                        <li>Zgłoszenie reklamacji powinno zawierać oznaczenie osoby zgłaszającej reklamację (imię i nazwisko bądź nazwę firmy, adres zamieszkania lub siedziby, adres e-mail) oraz opis zaistniałego problemu będącego przyczyną reklamacji. Wzór formularza reklamacyjnego stanowi załącznik nr 3 do niniejszego Regulaminu, ale skorzystanie z niego nie jest obowiązkowe.</li>
                        <li>Usługodawca zobowiązuje się do rozpatrzenia każdej reklamacji dotyczącej Produktu w terminie do 14 dni kalendarzowych, a reklamacji dotyczącej Usług elektronicznych - w terminie do 30 dni kalendarzowych.</li>
                        <li>W przypadku braków, Usługodawca wezwie Użytkownika do uzupełnienia reklamacji w niezbędnym zakresie niezwłocznie, nie później jednak niż w terminie 7 dni, od daty otrzymania wezwania przez Użytkownika. Nieprzekraczalny termin na udzielenie odpowiedzi Konsumentowi to 30 dni kalendarzowych. </li>
                        <li>W przypadku, gdy na Towar została udzielona gwarancja, informacja o niej, a także jej treść, będą zawarte przy opisie Towaru w Sklepie. Usługodawca dołączy też kartę gwarancyjną do sprzedanego Towaru.</li>
                    </ol>

                    <h4> §11 PRZEDSIĘBIORCY </h4>
                    <ol>
                        <li>Postanowienia niniejszego paragrafu mają zastosowanie wyłącznie do Użytkowników będących Przedsiębiorcami.</li>
                        <li>
                            Użytkownikowi (klientowi) będącemu Przedsiębiorcą nie przysługuje prawo odstąpienia od Umowy z Usługodawcą.
        </li>
                        <li>
                            Usługodawcy przysługuje prawo odstąpienia od Umowy sprzedaży bez podawania przyczyny w terminie 10 dni od dnia jej zawarcia. Z tego tytułu Przedsiębiorcy nie przysługują żadne roszczenia względem Usługodawcy.
        </li>
                        <li>Z chwilą wydania Produktów będących przedmiotem Umowy sprzedaży przewoźnikowi, na Przedsiębiorcę przechodzą korzyści i ciężary związane z Produktami oraz niebezpieczeństwo ich przypadkowej utraty lub uszkodzenia.</li>
                        <li>Przedsiębiorca po otrzymaniu Produktów zobowiązany jest sprawdzić ich stan. W przypadku stwierdzenia uszkodzeń lub innych zastrzeżeń podczas odbioru Produktów, Przedsiębiorca ma obowiązek sporządzenia - w obecności przewoźnika - protokół zastrzeżeń, określając precyzyjnie ilość i rodzaj Produktów oraz ich uszkodzenia zgodnie z procedurą obowiązującą u danego przewoźnika.</li>
                        <li>Usługodawca nie ponosi odpowiedzialności za działania przewoźnika. Usługodawca nie odpowiada za szkody wynikłe z niepoprawności danych podanych przez Użytkownika w trakcie składania Zamówienia, spowodowanych przez błędne podanie danych kontaktowych lub adresu odbioru, a w tym nie ponosi kosztów przesyłki. </li>
                        <li>
                            W stosunku do Przedsiębiorców odpowiedzialność z tytułu rękojmi za wady określone w art. 556 - 576 Kodeksu cywilnego zostaje wyłączona.
        </li>
                        <li>
                            Ewentualne spory powstałe pomiędzy Usługodawcą a Przedsiębiorcą zostaną poddane sądowi właściwemu ze względu na siedzibę Usługodawcy.
        </li>
                        <li>Podstawą przetwarzania przez Sprzedawcę danych osobowych jest wykonanie Umowy </li>
                        <li>
                            Administratorem danych osobowych Użytkowników zbieranych za pośrednictwem Serwisu jest Usługodawca. Dane osobowe Użytkowników zbierane są wyłącznie w celu realizacji Umowy sprzedaży lub Umowy o świadczenie Usług.
        </li>
                        <li>
                            Dane osobowe Użytkowników przetwarzane są przez Sprzedawcę  wyłącznie w celu realizacji Umowy. Podstawą przetwarzania  danych jest art. 6 ust. 1 lit. b rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i  w  sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, RODO).
        </li>
                        <li>Dane osobowe będą przetwarzane przez okres niezbędny do realizacji Umowy lub do czasu świadczenia Usługi albo do czasu rozwiązania Umowy/rezygnacji z Usługi i/lub ewentualnego zgłoszenia sprzeciwu przez osobę, której dane dotyczą, a po tym okresie dla celów i przez okres wymagany przez przepisy prawa lub dla zabezpieczenia ewentualnych roszczeń. </li>
                        <li>Użytkownikowi przysługuje prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec ich przetwarzania, a także prawo wniesienia skargi do organu nadzorczego.  W celu realizacji uprawnień, o których mowa powyżej Użytkownik winien przesłać stosowną wiadomość pisemnie lub za pośrednictwem poczty elektronicznej na adres Usługodawcy wskazany na wstępie.</li>
                        <li>
                            Podanie danych osobowych jest dobrowolne, jednak niezbędne do wykonania Umowy i/lub świadczenia Usługi.
        </li>
                        <li>Usługodawca nie ponosi odpowiedzialności za podanie przez osoby korzystające z Serwisu - danych osobowych osób trzecich. Ewentualne spory wynikłe w związku z realizacją umów w Serwisie będą rozstrzygane polubownie.</li>
                        <li>
                            Usługodawca dokłada szczególnych starań w celu ochrony prywatności i informacji mu przekazanych, a dotyczących Użytkowników. Usługodawca z należytą starannością dobiera i stosuje odpowiednie środki techniczne, w tym o charakterze informatycznym i organizacyjnym, zapewniające ochronę przetwarzanych danych, w szczególności w celu ochrony danych osobowych przed zagrożeniami podczas przekazywania danych oraz przed dostępem osób trzecich do tych danych.
        </li>
                        <li>
                            Podane przez Użytkowników dane osobowe Usługodawca zbiera i przetwarza zgodnie z obowiązującymi przepisami prawa oraz zgodnie z Polityką Prywatności, która stanowi Załącznik nr 5 do Regulaminu.
        </li>
                        <li>W przypadku zmiany danych Użytkownika, powinien on o zaistniałych zmianach poinformować Sprzedawcę. </li>
                    </ol>

                    <h4> §12 POSTANOWIENIA KOŃCOWE </h4>
                    <ol>
                        <li>Regulamin jest dostępny w języku polskim.</li>
                        <li>Usługodawca ponosi odpowiedzialność za świadczone Usługi na podstawie Regulaminu i w zakresie określonym przez bezwzględnie obowiązujące przepisy prawa.</li>
                        <li>O ile bezwzględnie obowiązujące przepisy prawa nie stanowią inaczej, prawem właściwym dla rozstrzygania wszelkich sporów powstałych na gruncie niniejszego Regulaminu jest prawo polskie oraz obowiązuje jurysdykcja sądów polskich.</li>
                        <li>W przypadku, gdy stroną Umowy jest Konsument, rozstrzyganie ewentualnych sporów powstałych pomiędzy Usługodawcą a Konsumentem, zostaje poddane sądom właściwym zgodnie z postanowieniami właściwych przepisów Kodeksu postępowania cywilnego. W przypadku Klientów niebędących Konsumentami, do rozstrzyganie ewentualnych sporów właściwy jest sąd ze względu na siedzibę Usługodawcy.</li>
                        <li>Treść niniejszego Regulaminu może ulec zmianie. Informacja o zmianie regulaminu będzie umieszczona w Serwisie. Termin wejścia w życie zmian nie będzie krótszy niż 14 dni od dnia ich ogłoszenia. W sytuacji, gdy Użytkownik posiadający Konto, nie akceptuje nowej treści Regulaminu, powinien zawiadomić o tym fakcie Usługodawcę w ciągu 14 dni od daty poinformowania o zmianie Regulaminu, przesyłając stosowną informację na adres e-mail Usługodawcy. Brak akceptacji skutkuje rozwiązaniem Umowy.</li>
                        <li>Regulamin wchodzi w życie z dniem 31.10.2017 r.</li>
                    </ol>


                    <h4>ZAŁĄCZNIKI:</h4>
                    Poniższe załączniki stanowią integralną część Regulaminu:
    <ol>
                        <li> <Link to="/tos/charges" > Opłaty </Link></li>
                        <li> <Link to="/tos/sla"> Polityka Przerw Technicznych </Link></li>
                        <li> <Link to="/tos/complaint-template"> Wzór Reklamacja w zakresie świadczenia usług drogą elektroniczną </Link></li>
                        <li> <Link to="/tos/threats"> Informacja o szczególnych zagrożeniach związanych z korzystaniem przez użytkowników z usług świadczonych drogą elektroniczną  w związku z obsługą Serwisu iPomiar </Link></li>
                        <li> <Link to="/tos/privacy"> Polityka Prywatności </Link></li>
                        <li> <Link to="/tos/renouncement"> Wzór oświadczenia o odstąpieniu od umowy </Link></li>
                        <li> <Link to="/tos/consumer-rights"> Pouczenie o prawie Konsumenta do odstąpienia od umowy świadczenia usług </Link></li>
                    </ol>
                </div>
            </ScrollArea>
        </div>
    );

export default TosIndex;