import { FormattedMessage } from "react-intl";
import * as React from "react";

const RodoStatemant = () => (<>
    <h6 style={{ textAlign: "center" }}><FormattedMessage id="gdpr.statement" /></h6>
    <ol>
        <li>Administratorem danych osobowych jest Jakub Szczerba prowadzący działalność gospodarczą pod firmą Termipol, z siedzibą w Lublińcu (42-700), ul. Powstańców Śl. 64, NIP 575-180-10-22, REGON 362191270</li>
        <li>Dane osobowe przetwarzane będą w celu realizacji zamówienia (art. 6 ust. 1 lit. b rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych). Dane osobowe będą przetwarzane aż do ewentualnego zgłoszenia sprzeciwu przez osobę, której dane dotyczą, a po tym okresie dla celów i przez okres oraz w zakresie wymaganym przez przepisy prawa lub dla zabezpieczenia ewentualnych roszczeń</li>
        <li>Dane osobowe udostępniane będą firmie kurierskiej i podmiotowi za pomocą którego realizowana jest płatność, z którymi współpracuje administrator danych osobowych</li>
        <li>Osobie, której dane dotyczą, przysługuje prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec ich przetwarzania, a także  prawo wniesienia skargi do organu nadzorczego</li>
        <li>Podanie danych jest dobrowolne, lecz niezbędne do realizacji zamówienia</li>
    </ol>
</>);

export default RodoStatemant;