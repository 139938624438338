import * as moment from 'moment';
import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AlertLevel, DateTimeFormat, IDeviceTile} from "../../constants/Constants";
import "../../less/DashboardGridItem.css";
import OnlineOffline from "../OnlineOffline";

interface IDashboardTileProps {
    data: IDeviceTile;
}

const DashboardGridItem = (props: IDashboardTileProps) => {
    const tile = props.data
    const intl = useIntl();
    const value = (tile.values || []).length > 0 ? tile.values[0] : null;
    const parameterUnit = value ? value.unit : null;
    return (<div className={"dashboard-grid-item " + (value && value.alert !== AlertLevel.Inside ? (value.alert == AlertLevel.Above ? "above" : "below") : "normal")}>
        <div className="online">
            <OnlineOffline isOnline={tile.isOnline} />
        </div>
        <div className="name"> {tile.name} </div>
        <div className="value">
            {value ?
                <span className="normal" key={`values_${value.parameterId}`}
                    title={intl.formatMessage({ id: `device.chart.parameter_${value.parameterId}` })} >
                    {value.value.toFixed(1)}
                    {parameterUnit ? <span> {parameterUnit} </span> : <FormattedMessage id={`device.chart.parameter.unit_${value.parameterId}`}/>}
                </span>
                : <i className="material-icons">remove</i>}
        </div>
        <div className="lastUpdate"> {tile.lastUpdate ? moment.utc(tile.lastUpdate).local().format(DateTimeFormat) : null} </div>
    </div>)
}

export default DashboardGridItem;