import * as React from "react";
import {MeasurableParameter} from "../constants/Constants";

export interface IDeviceContext {
    deviceId: number,
    parameterId: MeasurableParameter | null
}

const DeviceContext = React.createContext<IDeviceContext | null>(null);

export const DeviceContextProvider = DeviceContext.Provider;
export const DeviceContextConsumer = DeviceContext.Consumer;
export default DeviceContext;
    