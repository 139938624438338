import * as React from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IAppStore } from "store/configureStore";
import {SmsNotificationActionCreators} from "actions/SmsNotificationActionCreators";
import "../../less/Notification.css";
import UserContext, { IUserContext } from "../UserContext";
import SubmitButton from "../SubmitButton";
import {ISmsNotification} from "../../store/SmsNotificationStore";
import {INotificationPhoneConfirmation} from "../../constants/SmsNotificationConstants";

interface IPhoneConfirmDialogProps extends ISmsNotification {
    savePhoneNumberCode: (model: INotificationPhoneConfirmation) => void;
    closePhoneDialog: () => void;
    setPhoneNumberCode: (code: string) => void;
}

const PhoneConfirmDialog = (props: IPhoneConfirmDialogProps) => {
    const CODE_LENGTH = 7;

    const sanitizeNumber = (num: string): string =>
        (num ? num.replace("(", "")
        .replace(")", "")
        .replace(new RegExp(' ', 'g'), "") : num);

    const validatePhoneNumberCode = (code: any): boolean => {
        return (code && code.length === CODE_LENGTH)
    }

    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.type === 'checkbox' ? target.checked : target.value;
     
        props.setPhoneNumberCode(value ? value.toUpperCase().substring(0, CODE_LENGTH) : value);
    }

    const handleSavingPhoneNumberCode = () => { props.savePhoneNumberCode({ phoneNumber: sanitizeNumber(props.editingPhoneNumber), code: props.editingPhoneCode }); }
    const userContext = React.useContext<IUserContext>(UserContext);

    return (
        <Modal centered show={true} className={"register-phone-dialog " + userContext.style}>
            <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <Form noValidate validated={ validatePhoneNumberCode(props.editingPhoneCode) }>
                                <div><i className="material-icons">smartphone</i><FormattedMessage id="notifications.phone.confirm.number" /></div>
                                <FormControl
                                    className="phone-number-code"
                                    type="text"
                                    name="phone-number-code"
                                    value={props.editingPhoneCode !== null ? props.editingPhoneCode : ""}
                                    onChange={handleInputChange} />
                            </Form>
                            {props.message ?
                                <div className="error-message">
                                    <FormattedMessage id={props.message} />
                                </div>
                                : null}
                        </div>
                    </div> 
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closePhoneDialog}>
                    <FormattedMessage id="notifications.cancel" />
                </Button>             
                <SubmitButton disabled={!validatePhoneNumberCode(props.editingPhoneCode)}
                              onClick={handleSavingPhoneNumberCode}>
                    <FormattedMessage id="notifications.phone.confirm" />
                </SubmitButton>               
            </Modal.Footer>
        </Modal>
    );
};

export default connect(
    (state: IAppStore) => state.smsNotification,
    (dispatch: any) => bindActionCreators(SmsNotificationActionCreators, dispatch)
)(PhoneConfirmDialog);