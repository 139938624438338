import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import {AccessMode, IParameterSettings} from "../../../constants/Constants";
import AccessControlRemoveModal from "../../AccessControl/AccessControlRemoveModal";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DeviceActionCreators} from "../../../actions/DeviceActionCreators";
import {IAppStore} from "../../../store/configureStore";
import {IDeviceDetails, IEditedDevice} from "../../../constants/DeviceConstants";

const DeviceDetailsSettings = (
    props: {
        device: IDeviceDetails,
        editDeviceForm: (editedForm: IEditedDevice) => void;
        fetchSingleDevice: (deviceId: number) => void;
    }
) => {
    const editDeviceForm = () => {
        props.editDeviceForm(
            {
                deviceId: props.device.deviceId,
                name: props.device.name,
                sampling: props.device.sampling,
                operatingMode: props.device.operatingMode,
                disconnectedAlert: props.device.disconnectedAlert,
                latitude: (props.device.latitude || "").toString(),
                longitude: (props.device.longitude || "").toString(),
                publicData: props.device.publicData,
                parameterSettings: (props.device.parameters || []).map(p => { return {... p } as IParameterSettings })
            });
    }
    
    
    const intl = useIntl();
      
    switch (props.device.accessMode){
        case AccessMode.Administrator:
            return (<Row className="show-grid">
                <Col md={12} style={{textAlign:"right"}}>
                    <div className="device-settings-panel-button"
                         onClick={editDeviceForm}
                         title={intl.formatMessage({id: "device.edit.settings"})}>
                        <i className="material-icons">settings</i>
                    </div>                
                </Col>
            </Row>);
        case AccessMode.Readonly:
            return (   <Row>
                <Col>
                    <AccessControlRemoveModal />
                </Col>
            </Row>);
        default:
            return null;
    }
}

function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        device: state.deviceDetails.device,
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(DeviceDetailsSettings);
