import * as React from "react";
import UserContext, {IUserContext, UserContextConsumer} from "../UserContext";
import {FormattedMessage} from "react-intl";
import {Navigate} from "react-router-dom";
import {AddUserFrom, Device, DeviceForm} from "./DeviceForm";
import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {DeviceUsersList} from "./DeviceUsersList";
import "../../less/AccessControl/AccessControl.css";
import {DeviceAccessControlAPI} from "../../api/DeviceAccessControlAPI";
import {DeviceUsersModel} from "../../constants/DeviceAccessControl/DeviceUsersModel";

export const DeviceAccessControl = () => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const [addUserForm, setDevices] = useState<AddUserFrom>({email: null, devices: [], isLoaded: false, isError: false});
    const [deviceUsers, setUsers] = useState<{records: DeviceUsersModel[], isLoaded: boolean}>({ records: [], isLoaded: false});
    
    useEffect(() => {
        fetchDeviceUsers();
        fetchDevices();
    }, []);

    const fetchDeviceUsers = () => {
        DeviceAccessControlAPI
            .fetchDeviceUsers()
            .then(result => setUsers({records: result, isLoaded:true} ));
    }
    const fetchDevices = () => {
        DeviceAccessControlAPI
            .fetchDevices()
            .then(result => {
                const devices = result.map<Device>((d) => ({
                    deviceId: d.deviceId,
                    deviceName: d.deviceName,
                    checked: false
                }));
                setDevices({
                    email: null,
                    devices: devices,
                    isLoaded: true,
                    isError: false
                });
            });
    };

    const removeUserHandler = (deviceId: number, email: string) => {
        DeviceAccessControlAPI.removeUser({
            email: email,
            deviceId: deviceId
        })
            .then((response: Response) => response.json())
            .then((result: boolean) => {
                if (result)
                    fetchDeviceUsers();
            });
    }
    
    const selectDevicesHandler = (deviceIds: number[], checked: boolean) =>{
        let devices = addUserForm.devices.map(d => ({
            deviceId: d.deviceId,
            deviceName: d.deviceName,
            checked: (deviceIds.includes(d.deviceId) ? checked : d.checked)
        }));
        setDevices({
            email: addUserForm.email,
            devices: devices,
            isLoaded: addUserForm.isLoaded,
            isError: false
        });
    }
    
    const selectUserEmailHandler = (email: string) => {
        setDevices({
            email: email,
            devices: addUserForm.devices,
            isLoaded: addUserForm.isLoaded,
            isError: false
        })
    }
    
    const getCheckedDevices = (form: AddUserFrom) : number[] => 
        form.devices.filter(d => d.checked === true).map(d => d.deviceId);
    
    const saveFormHandler = () => {
        const deviceIds = getCheckedDevices(addUserForm);
        if(( (deviceIds?.length ?? 0) == 0) 
            || (addUserForm.email.toLowerCase() === userContext.userName.toLowerCase())) {
            setDevices({...addUserForm, isError: true});
            return;
        }
        DeviceAccessControlAPI.addAccessForDevices({
            email: addUserForm.email,
            deviceIds: deviceIds
        })
            .then((response: Response) => response.json())
            .then((result: boolean) => {
                if (result)
                    fetchDeviceUsers();
                else
                    setDevices({ ... addUserForm, isError:true });
            });
    };

    return (userContext && (
                userContext.isAuthenticated
                    ? <div className="access-control">
                        <h3>
                            <i className="material-icons">manage_accounts</i> {" "}
                            <FormattedMessage id="page.access-control.title"/>
                        </h3>
                        <Container>
                            <Row>
                                <Col md={4}>
                                    <DeviceForm userForm={addUserForm}
                                                selectDevices={selectDevicesHandler}
                                                selectUserEmail={selectUserEmailHandler}
                                                saveForm={saveFormHandler}
                                    />
                                </Col>
                                <Col md={8}>
                                    <DeviceUsersList
                                        deviceUsers={deviceUsers.records}
                                        isLoaded={deviceUsers.isLoaded}
                                        removeUser={removeUserHandler}/>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                    : <Navigate to="/portal"/>
            ));
}