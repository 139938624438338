import {FormattedMessage, useIntl} from "react-intl";
import * as React from "react";
import {Typeahead} from "react-bootstrap-typeahead";
import {TimeZoneOption} from "../../constants/NotificationConstants";

const TimeZoneSelector = (props: {
    reportTimeZoneChange: (timeZoneId: string) => void,
    timeZones: TimeZoneOption[],
    selectedTimeZoneId: string
}) => {
    return (
        <div className="row content">
            <div className="col-sm-12">
                <div className="input-group center-element">
                    <span><FormattedMessage id="notifications.summary.report.time-zone.title"/>:</span>
                    {props.timeZones ? (
                        <Typeahead
                            style={{margin: "0 0 0 6px", width: "450px"}}
                            id="report-time-zone"
                            multiple={false}
                            labelKey={(option: TimeZoneOption) => `${option.displayName}`}
                            placeholder={"(UTC) Coordinated Universal Time"}
                            defaultSelected={props.timeZones?.filter(t => t.id === props.selectedTimeZoneId) ?? []}
                            paginationText={<FormattedMessage id="notifications.summary.report.time-zone.pagination"/>}
                            onChange={(selected) => {
                                if (selected?.length == 0)
                                    props.reportTimeZoneChange(null);
                                else
                                    props.reportTimeZoneChange((selected[0] as TimeZoneOption).id);
                            }}
                            options={props.timeZones ?? []}
                        />) : null}
                </div>
            </div>
        </div>);
}

export default TimeZoneSelector;