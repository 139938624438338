import {useEffect, useState} from 'react';
import {IPlannerTab} from "../constants/PlannerDeviceConstants";
import FloorPlannerAPI from "../api/FloorPlannerAPI";
import {promiseResult} from "../actions/promiseDispatch";

export interface ISpacePlannerTabsState {
    tabs: IPlannerTab[],
    activeTabId: string
}

export function useSpacePlannerTabs(): [
    ISpacePlannerTabsState,
    (title: string) => void,
    (planId: string) => void,
    (planId: string, title: string) => void,
    (planId: string) => void,
] {
    const [state, setState] = useState<ISpacePlannerTabsState>({tabs: [], activeTabId: null});

    function addTab(name: string) {
        const tab: IPlannerTab = {planId: crypto.randomUUID(), name: name}
        setState({...state, tabs: [...state.tabs, tab], activeTabId: tab.planId});
    }

    function removeTab(planId: string) {
        const tabs = state.tabs.filter(d => d.planId != planId);
        const activeTabId: string = tabs.length == 0 ? null : tabs[0].planId;

        promiseResult(
            () => FloorPlannerAPI.deletePlan(planId),
            () => {
                setState({...state, tabs: tabs, activeTabId: activeTabId});
            },
            () => { }
        );
    }

    function renameTab(planId: string, name: string) {
        const tabIndex = state.tabs.findIndex(t => t.planId == planId);
        if (tabIndex == -1)
            return;

        setState({
            ...state, tabs:
                [...state.tabs.slice(0, tabIndex),
                    {...state.tabs[tabIndex], name: name},
                    ...state.tabs.slice(tabIndex + 1)
                ]
        });
    }

    function setActiveTab(planId: string) {
        setState({...state, activeTabId: planId});
    }

    useEffect(() => {
        promiseResult<IPlannerTab[]>(
            FloorPlannerAPI.getPlansHeaders,
            (tabs: IPlannerTab[]) => {
                setState({...state, tabs: tabs, activeTabId: tabs.length != 0 ? tabs[0].planId : null});
            },
            () => { }
        );
    }, []);

    return [state, addTab, removeTab, renameTab, setActiveTab];
}