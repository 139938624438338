export interface IDeviceSmogRecord {
    date: Date;
    temperature: number;
    humidity: number;
    pm25: number;
    pm10: number;
}

export interface IDeviceSmogData {
    deviceId: number;
    pm25Norm: number;
    pm10Norm: number;
    records: IDeviceSmogRecord[];
}

export class SmogActions {
    public static readonly FETCH_DATA = "FETCH_SMOG_REQUEST";
    public static readonly FETCH_DATA_SUCCESS = "FETCH_SMOG_SUCCESS";
    public static readonly FETCH_DATA_FAILED = "FETCH_SMOG_FAILED";
}