import * as React from "react";
import {Button, ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";
import {BackgroundButton} from "./BackgroundButton";
import {IBackgroundImage, IPlannerDevice} from "../../constants/PlannerDeviceConstants";
import {useIntl} from "react-intl";
import UserContext, {IUserContext} from "../UserContext";
export const Menu = (props: {
    devices: IPlannerDevice[],
    saveEnabled: boolean,
    onSelectDevice: (eventKey: string) => void
    onUploadBackground: (backgroundImage: IBackgroundImage) => void
    OnDeleteBackground: () => void,
    onSave: () => void,
    onDelete: () => void
}) => {
    const intl = useIntl();
    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        <div style={{padding:"10px"}}>
            <BackgroundButton 
                onUploadBackground={props.onUploadBackground} 
                onDeleteBackground={props.OnDeleteBackground}
                disabled={userContext.isDemoUser}
            />
            {" "}
            <DropdownButton as={ButtonGroup} 
                            title={intl.formatMessage({ id: "space-planner.add-devices"})} 
                            style={{marginTop: "-11px"}}
                            variant="primary"
                            onSelect={props.onSelectDevice}>
                {props.devices.map(d => (<Dropdown.Item key={`drop_down_item_${d.deviceId}`} eventKey={d.deviceId}>{d.name}</Dropdown.Item>))}
            </DropdownButton>

            <ButtonGroup style={{marginBottom: "10px", float:"right"}}>
                <Button variant="primary"
                        title={intl.formatMessage({ id: "space-planner.save.desc"})}
                        onClick={props.onSave} style={{height:"38px"}}
                        disabled={userContext.isDemoUser || !props.saveEnabled}>
                    <span className="material-icons">save</span>
                </Button>
                <Button variant="danger"
                        title={intl.formatMessage({ id: "space-planner.delete.desc"})}
                        onClick={props.onDelete} style={{height:"38px"}}
                        disabled={userContext.isDemoUser}>
                    <span className="material-icons">delete</span>
                </Button>
            </ButtonGroup>
        </div>
    );
}