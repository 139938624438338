import * as React from "react";
import '../less/Logo.css';
import UserContext, { IUserContext } from './UserContext';

const Logo = () => {
    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        userContext.isLeftMenuExpanded ?
        <div
            className={`logo ${userContext.applicationName}`}
            onClick={() => { userContext.setLeftPanelState(false); }}>
            {(userContext.isDemoUser ? <div className="demo-box">Demo</div> : null)}
        </div>
            :
        <i onClick={() => { userContext.setLeftPanelState(true); }} className="material-icons expand-collapse-horizontal">
            chevron_right
            {(userContext.isDemoUser ? <div className="demo-box"/> : null)}
        </i>
    );
}

export default Logo;