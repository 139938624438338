import * as React from "react";
import {useState} from "react";
import {FormattedMessage} from 'react-intl';
import '../../less/RegisterDevice.css';
import UserContext, {IUserContext} from '../UserContext';
import {Button, Modal} from "react-bootstrap";
import "../../less/ActivationCodeModal.css";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DeviceActionCreators} from "../../actions/DeviceActionCreators";
import {IAppStore} from "../../store/configureStore";
import SubmitButton from "../SubmitButton";
import {DeviceAccessControlAPI} from "../../api/DeviceAccessControlAPI";
import {Navigate} from "react-router-dom";

interface IAccessControlRemoveModalProps {
    deviceId: number;
    fetchSingleDevice: (deviceId: number) => {}
}
const AccessControlRemoveModal = (props: IAccessControlRemoveModalProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
    const closeDialog = (shouldRedirect: boolean = false) => {
        setModalVisible(false);
        setShouldRedirect(shouldRedirect);
    } 
    const openDialog = () => setModalVisible(true);
    
    const submit = () => {
        DeviceAccessControlAPI.removeUser({deviceId: props.deviceId, email: userContext.userName})
            .then((response: Response) => response.json())
            .then((result: boolean) => {
                if (result)
                    closeDialog(true);
            });
    }
    
    if(shouldRedirect)
        return (<Navigate replace to="/main" />)
    
    return (
        <>
            <Button onClick={openDialog}>
                <i className="material-icons">manage_accounts</i> <FormattedMessage id="access-control-modal.remove.button"/>
            </Button>
            <Modal centered show={modalVisible} onHide={closeDialog} className={"access-control-modal-remove " +userContext.style}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="access-control-modal.remove.button"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="control-label">
                            <FormattedMessage id="access-control-modal.remove.label"/>
                        </label>
                    </div>
                    <div style={{float: "right", marginTop: "5px"}}>
                        <SubmitButton onClick={submit}>
                            <FormattedMessage id="access-control-modal.remove.button"/>
                        </SubmitButton>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

function mapStateToProps(state: IAppStore, ownProps: any) {
    return {
        deviceId: state.deviceDetails.device.deviceId
    };
}

export default connect(
    mapStateToProps,
    (dispatch: any) => bindActionCreators(DeviceActionCreators, dispatch)
)(AccessControlRemoveModal);
