import * as React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useIntl } from "react-intl";
import "../less/InfoMenu.css";
import UserContext, { IUserContext } from "./UserContext";

const InfoMenu = () => {
    const intl = useIntl();
    const userContext = React.useContext<IUserContext>(UserContext);
    return (
        <Navbar variant="dark" className="navbar-dark">
            <Nav>
                {userContext && userContext.smogMapEnabled
                    ? <Nav.Link href="/map">
                        <i className="material-icons" title={intl.formatMessage({ id: "about" })}>place</i>
                    </Nav.Link>
                    : null
                }
                <Nav.Link href="/about">
                    <i className="material-icons" title={intl.formatMessage({ id: "about" })}>info</i>
                </Nav.Link>
                <Nav.Link href="/contact">
                    <i className="material-icons" title={intl.formatMessage({ id: "contact" })}> phone </i>
                </Nav.Link>
                <Nav.Link href="/tos">
                    <i className="material-icons" title={intl.formatMessage({ id: "termOfService" })} > assignment_turned_in </i>
                </Nav.Link>
            </Nav>
        </Navbar>
    );
};

export default InfoMenu;
