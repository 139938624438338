import promiseDispatch from "./promiseDispatch";
import NotificationAPI from "../api/NotificationAPI";

import {IRegisteredEmail, EmailNotificationActions, IRegisteredEmailForm, INotificationEmailAddress} from "../constants/EmailNotificationConstants";

export const EmailNotificationActionCreators = {
    loadEmails: () => async (dispatch: any, getState: any) => {
        promiseDispatch<IRegisteredEmail[]>(
            dispatch,
            NotificationAPI.getRegisteredEmails,
            {
                request: EmailNotificationActions.GET_REGISTERED_EMAILS,
                success: EmailNotificationActions.GET_REGISTERED_EMAILS_SUCCESS,
                failure: EmailNotificationActions.GET_REGISTERED_EMAIL_FAILED
            });
    },
    showEmailRegistrationDialog: () => ({ type: EmailNotificationActions.SHOW_EMAIL_DIALOG }),
    saveEmailActivity: (model: IRegisteredEmailForm[]) => async (dispatch: any, getState: any) => {
        promiseDispatch<object>(
            dispatch,
            () => NotificationAPI.saveEmailActivity(model),
            {
                request: EmailNotificationActions.SAVE_EMAIL_ACTIVITY,
                success: EmailNotificationActions.SAVE_EMAIL_ACTIVITY_SUCCESS,
                failure: EmailNotificationActions.SAVE_EMAIL_ACTIVITY_FAILED
            });
    },

    activeEmail: (email: string, isActive: boolean, isReportActive: boolean) => (
        {
            type: EmailNotificationActions.CHANGE_EMAIL_ACTIVITY, 
            payload: {email, isActive, isReportActive}
        }),
    deleteEmail: (toDelete: boolean, email: string) => ({ type: EmailNotificationActions.DELETE_EMAIL, payload: {email, toDelete}}),
    closeEmailDialog: () => ({ type: EmailNotificationActions.CLOSE_EMAIL_DIALOG }),
    saveEmail: (model: INotificationEmailAddress) => async (dispatch: any, getState: any) => {
        promiseDispatch<string>(
            dispatch,
            () => NotificationAPI.saveEmailAddress(model),
            {
                request: EmailNotificationActions.SAVE_EMAIL,
                success: EmailNotificationActions.SAVE_EMAIL_SUCCESS,
                failure: EmailNotificationActions.SAVE_EMAIL_FAILED
            });
    },
    setEmail: (email:string) => ({ type: EmailNotificationActions.SET_EMAIL, payload: email })
}