import {fetch} from "whatwg-fetch";
import {jsonHeader} from "../constants/Constants";
import {IActivationCode} from "../constants/ActivationCodeConstants";

export class ActivationCodeAPI {
    public static activateCode(model: IActivationCode): Promise<Response> {
        return fetch(`/api/activation-code`, {
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader,
        });
    }  
}