import {createRef, useEffect, useState} from "react";
import * as d3 from "d3";
import * as React from "react";
import {EnumDictionary, IPlannerDevice, TooltipMode} from "../../constants/PlannerDeviceConstants";
import {AlertLevel} from "../../constants/Constants";


const colorMap: EnumDictionary<AlertLevel, string> = {
    [AlertLevel.Inside]: "Black",
    [AlertLevel.Above]: "#FF5454",
    [AlertLevel.Below]: "#67C2EF",
};

export const PlannerTooltip = (props: {
    mode: TooltipMode,
    x: number,
    y: number,
    device: IPlannerDevice,
    removeDeviceAction: () => void
}) => {
    const popUpWidth = 220;
    const popUpHeight = 60;
    const tooltipRef = createRef<any>();
    const removeButtonRef = createRef<any>();
    const [tooltipMode, setTooltipMode] = useState(props.mode);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        d3.select(tooltipRef.current)
            .on("mouseover", function (_: MouseEvent) {
                setIsActive(true);
            })
            .on("mouseout", function (_: MouseEvent) {
                setIsActive(false);
            });

        d3.select(removeButtonRef.current)
            .on("click", function (_: MouseEvent) {
                props.removeDeviceAction();
            })
    });

    useEffect(() => {
        if (props.mode != TooltipMode.Hidden || !isActive)
            setTooltipMode(props.mode);
    }, [props.mode]);


    if (tooltipMode == TooltipMode.Hidden)
        return null;

    const startPosition = (() => {
        switch (tooltipMode) {
            case TooltipMode.Up:
                return [-popUpWidth / 2, -popUpHeight - 25];
            case TooltipMode.Down:
                return [-popUpWidth / 2, popUpHeight - 30];
            case TooltipMode.Right:
                return [25, -popUpHeight / 2]
            case TooltipMode.Left:
                return [-popUpWidth - 25, -popUpHeight / 2]
            default:
                return null;
        }
    })();


    const titlePosition = (() => {
        switch (tooltipMode) {
            case TooltipMode.Up:
                return [0, -popUpHeight - 10];
            case TooltipMode.Down:
                return [0, popUpHeight - 15];
            case TooltipMode.Right:
                return [popUpWidth / 2 + 20, - 15]
            case TooltipMode.Left:
                return [-popUpWidth / 2 - 20, -15]
            default:
                return null;
        }
    })();

    const valuePosition = (index: number) => {
        const move =  180*(index / valuesCount) - valuesMargin;
        switch (tooltipMode) {
            case TooltipMode.Up:
                return [move, -popUpHeight + 15];
            case TooltipMode.Down:
                return [move, popUpHeight + 15];
            case TooltipMode.Right:
                return [move + popUpWidth / 2 + 25, 10]
            case TooltipMode.Left:
                return [move -popUpWidth / 2 - 25, 10]
            default:
                return null;
        }
    };

    
    const iconPosition = (() => {
        switch (tooltipMode) {
            case TooltipMode.Up:
                return [popUpWidth / 2 - 12, -popUpHeight - 10];
            case TooltipMode.Down:
                return [popUpWidth / 2 - 12, popUpHeight - 15];
            case TooltipMode.Right:
                return [popUpWidth + 14, -15]
            case TooltipMode.Left:
                return [-popUpWidth - 14, -15]
            default:
                return null;
        }
    })();

    const popUpX1 = props.x + startPosition[0];
    const popUpY1 = props.y + startPosition[1];
    const popUpX2 = popUpX1 + popUpWidth;
    const popUpY2 = popUpY1 + popUpHeight;
    const popUpCX = popUpX1 + (popUpX2 - popUpX1) / 2;
    const popUpCY = popUpY1 + (popUpY2 - popUpY1) / 2;
    const deviceValues = props.device.values.slice(0, 3);
    const valuesCount = deviceValues?.length ?? 1;
    const valuesMargin = (valuesCount - 1) * (29 - (valuesCount - 3) * 19);

    const polygonPoints = (() => {
        switch (tooltipMode) {
            case TooltipMode.Up:
                return `${popUpX1},${popUpY1} ${popUpX2},${popUpY1} ${popUpX2},${popUpY2} ${popUpCX + 5},${popUpY2} ${popUpCX},${popUpY2 + 5} ${popUpCX - 5},${popUpY2} ${popUpX1},${popUpY2}`;
            case TooltipMode.Down:
                return `${popUpX1},${popUpY1} ${popUpCX - 5},${popUpY1} ${popUpCX},${popUpY1 - 5} ${popUpCX + 5},${popUpY1} ${popUpX2},${popUpY1} ${popUpX2},${popUpY2} ${popUpX1},${popUpY2}`;
            case TooltipMode.Left:
                return `${popUpX1},${popUpY1} ${popUpX2},${popUpY1} ${popUpX2},${popUpCY - 5} ${popUpX2 + 5},${popUpCY} ${popUpX2},${popUpCY + 5} ${popUpX2},${popUpY2} ${popUpX1},${popUpY2}`;
            case TooltipMode.Right:
                return `${popUpX1},${popUpY1} ${popUpX2},${popUpY1} ${popUpX2},${popUpY2} ${popUpX1},${popUpY2} ${popUpX1},${popUpCY + 5} ${popUpX1 - 5},${popUpCY} ${popUpX1},${popUpCY - 5}`;
            default:
                return "";
        }
    })();

    
    return (<g id="tooltip" ref={tooltipRef}>
        <polygon points={polygonPoints} style={{fill: "white", stroke: "black", strokeWidth: "1", opacity: "0.75"}}/>
        <text x={props.x + titlePosition[0]} y={props.y + titlePosition[1]} alignmentBaseline="central" textAnchor="middle" fontSize={15}
              pointerEvents="none">{props.device.name}
        </text>

        {deviceValues ? deviceValues.map((v, idx) =>
            <text key={idx} 
                  x={props.x + valuePosition(idx)[0]} 
                  y={props.y + valuePosition(idx)[1]} 
                  alignmentBaseline="central" 
                  textAnchor="middle" 
                  fontSize={14}
                  fill={colorMap[v.alert]}
                  pointerEvents="none">{v.value + v.unit}
            </text>
        ) : <text x={props.x + valuePosition(0)[0]}
                  y={props.y + valuePosition(0)[1]}
                  alignmentBaseline="central"
                  textAnchor="middle"
                  fontSize={14}
                  pointerEvents="none">{" - "}
        </text>}


        <circle cx={props.x + iconPosition[0]} cy={props.y + iconPosition[1]} strokeWidth={1} stroke="black" fill="white" r={8} ref={removeButtonRef}>
            <title>Remove device from plan</title>
        </circle>
        <line x1={props.x + iconPosition[0]-6} y1={props.y + iconPosition[1]-6} x2={props.x + iconPosition[0]+6} y2={props.y + iconPosition[1]+6} stroke="black" strokeWidth={1} />
        <line x1={props.x + iconPosition[0]+6} y1={props.y + iconPosition[1]-6} x2={props.x + iconPosition[0]-6} y2={props.y + iconPosition[1]+6} stroke="black" strokeWidth={1} />
    </g>);
}