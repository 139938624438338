import * as React from "react";
import ScrollArea from "react-scrollbar";
import "../../less/Tos.css";

const TosPrivacy = () =>
    (
        <div className="tos-main">
            <ScrollArea
                speed={0.8}
                className="scroll-area"
                horizontal={false}
            >
                <div className="content">
                    <h3>POLITYKA PRYWATNOŚCI</h3>
                    Polityka prywatności dotyczy przetwarzania i ochrony danych osobowych Użytkowników w związku z korzystaniem z serwisu www.ipomiar.pl, zwanego dalej SERWISEM, administrowanego przez Jakuba Szczerba prowadzącego działalność gospodarczą pod firmą Termipol, ul. Powstańców Śl. 64, 42-700 Lubliniec, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej (CEIDG) prowadzonej przez ministra właściwego ds. gospodarki, NIP 575-180-10-22, REGON 362191270 (zwanego dalej USŁUGODAWCĄ lub ADMINISTRATOREM).
                
                
    <b>JAKIE DANE I JAK PRZETWARZAMY?</b>
                    <ol>
                        <li>Korzystając z usług Serwisu, zostanie Pan/Pani poproszony/a o podanie niektórych swoich danych osobowych. Poprosimy Państwa jedynie o te dane, które są adekwatne w stosunku do celów, w jakich będą one przetwarzane. </li>

                        <li>Administratorem Państwa danych osobowych jest Jakub Szczerba prowadzący działalność gospodarczą pod firmą Termipol, ul. Powstańców Śl. 64, 42-700 Lubliniec (Administrator). </li>
                        <li>
                            Administrator zbiera dane osobowe w celu i zakresie niezbędnym do:
            <ol>
                                <li>
                                    rejestracji, utworzenia i prowadzenia kota w Serwisie iPomiar;
                </li>
                                <li>
                                    prawidłowej realizacji usług;
                </li>
                                <li>
                                    udzielenia odpowiedzi na pytanie skierowane za pośrednictwem formularza kontaktowego.
                </li>
                            </ol>
                        </li>
                    </ol>
                    <p>
                        (podstawą prawną przetwarzania jest art. 6 ust. 1 lit. b) i f) rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w  sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o  ochronie danych),dalej jako: „RODO”
    </p>
                    <ol>
                        <li>
                            Administrator danych przetwarza Państwa dane osobowe w następującym zakresie: imię nazwisko, adres, numer telefonu, adres email, IP.
        </li>
                        <li>
                            Podanie danych osobowych jest dobrowolne, ale stanowi warunek realizacji usług i udzielenia odpowiedzi na pytanie skierowane za pośrednictwem formularza kontaktowego.
        </li>
                        <li>
                            Osobie, której dane dotyczą, przysługuje prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych oraz w zakresie celów marketingowych, prawo wniesienia sprzeciwu wobec ich przetwarzania.
        </li>
                        <li>
                            Osobie, której dane dotyczą, przysługuje prawo wniesienia skargi do organu nadzorczego, gdy uzna, iż przetwarzanie danych osobowych narusza przepisy powszechnie obowiązującego prawa.
        </li>
                        <li>
                            W każdym czasie ma Pan/Pani możliwość rezygnacji że świadczenia usług za pośrednictwem serwisu, poprzez kontakt ze Sprzedawcą, co będzie skutkowało zaprzestaniem przetwarzania Państwa danych osobowych, bez wpływu na zgodność z prawem przetwarzania, którego dokonano przed rezygnacją z usług za pośrednictwem serwisu. W przypadku otrzymania prośby o rezygnację ze świadczenia usług dane zostaną usunięte w terminie 30 dni. Dane osobowe będą przetwarzane przez okres niezbędny dla wykonania umowy, a po tym okresie dla celów i przez okres oraz w zakresie wymaganym przez przepisy prawa lub dla zabezpieczenia ewentualnych roszczeń.
        </li>
                        <li>
                            Dane osobowe są zabezpieczone przed bezprawnym udostępnieniem osobom nieupoważnionym, zabraniem przez osoby nieupoważnione, zniszczeniem, utratą, uszkodzeniem lub zmianą oraz przetwarzaniem niezgodnym z przepisami prawa powszechnie obowiązującego.
        </li>
                        <li>
                            Państwa Dane osobowe nie będą udostępniane innym podmiotom ani nie będą przekazywane do państwa trzeciego.
        </li>
                        <li>
                            Usługodawca zobowiązuje się do ochrony Państwa danych osobowych zgodnie z obowiązującymi przepisami, w szczególności do ich nieujawniania osobom trzecim oraz do ich przetwarzania wyłącznie w celu określonym powyżej.
        </li>
                    </ol>

                    <h3>COOKIES</h3>
                    Ciasteczka (ang. cookies) to małe pliki, zapisywane i przechowywane na Państwa komputerze, tablecie lub smartphonie podczas przeglądania Internetu. Ciasteczko zazwyczaj zawiera nazwę strony internetowej, z której pochodzi, „długość życia” ciasteczka (to znaczy czas jego istnienia), oraz przypadkowo wygenerowany unikalny numer służący do identyfikacji przeglądarki, z jakiej następuje połączenie ze stroną internetową. Ciasteczka zawierają informacje o preferencjach Użytkownika, które Użytkownik może dowolnie usunąć lub zablokować ich tworzenie korzystając z właściwych mechanizmów wbudowanych w każdą przeglądarkę internetową. Pliki cookies są przez nas wykorzystywane do tego, aby Użytkownik mógł spersonalizować wygląd serwisu WWW oraz aby udoskonalać użyteczność Serwisu. Cookies nie są połączone z żadnymi danymi osobowymi.
    <ol>
                        <li>Serwis www.ipomiar.pl, w celu przechowywania, korzysta z plików cookies, obejmujących: IP komputera w oparciu o analizę logów dostępowych (np. rodzaj przeglądarki, rodzaj systemu operacyjnego, data i czas odwiedzin, przeglądane treści, liczba połączeń, liczba otwieranych podstron Serwisu) oraz unikalny identyfikator urządzenia zapisanego w  pliku. Pliki te nie są przechowywane na serwerach Administratora, a dane z nich są odczytywane jedynie podczas wizyty na stronie <a href="https://ipomiar.pl">https://ipomiar.pl</a>.</li>
                        <li>Podstawą prawną dla zbierania danych odczytywanych z plików „cookies” jest art. 6 ust. 1 pkt f) RODO</li>
                        <li>Informacje zbierane automatycznie wykorzystywane są do: zarządzania Serwisem, dostosowania zawartości Serwisu o preferencji Użytkownika, utrzymania sesji Użytkownika Serwisu (po zalogowaniu), stwierdzania ewentualnych zagrożeń bezpieczeństwa, badania ruchu Użytkowników w ramach Serwisu.</li>
                        <li>Użytkownik może zrezygnować ze zbierania plików „cookies” wybierając odpowiednie ustawienia przeglądarki, z której korzysta, jednak należy pamiętać, że w takim przypadku korzystanie z wszystkich funkcji witryny może okazać się niemożliwe.</li>
                        <li>
                            Pliki „cookies” przetwarzane przez właściciela obejmują:
            <ol type="a">
                                <li> cookies niezbędne dla prawidłowego funkcjonowania pewnych elementów strony – np. dokonania opłat,</li>
                                <li> cookies pamiętające ustawienia strony – np. tworzące możliwość autouzupełniania niektórych pól przy rejestracji,</li>
                                <li> cookies wykorzystywane do analizy ruchu na stronie. Analiza ta dokonywana jest za pomocą urządzenia Google Analytics, usługi analizy oglądalności stron internetowych udostępnianej przez Google, Inc. (“Google”). Dane te nie będą obejmowały danych osobowych.</li>
                            </ol>
                        </li>
                        <li>Administrator nie będzie łączył danych uzyskanych z „cookies” użytkowników strony z   żadnymi innymi danymi, w posiadaniu których znajduje się bądź może się znaleźć ani też nie będzie tworzył ich kopii na własnych serwerach. Dane z plików „cookies” nie będą przekazywane do innych podmiotów, ani do państwa trzeciego.</li>
                        <li>Mogą Państwo uzyskać dostęp do swoich danych w formie zbieranej poprzez analizę zawartości plików „cookies”, w treści w jakiej znajdują się na dysku komputera, które są następnie wykorzystywane przez witrynę oraz w każdej chwili je usunąć.</li>
                        <li>Przysługuje Państwu prawo ich usunięcia, wniesienia sprzeciwu wobec ich przetwarzania, jak również prawo wniesienia skargi do GIODO.</li>
                    </ol>

                    <h3>KONTAKT</h3>
                    Wszelkie pytania związane z przetwarzaniem i ochroną danych osobowych Użytkowników Serwisu oraz wykorzystywaniem plików cookies, w tym dotyczące niniejszej "Polityki prywatności i plików cookies" prosimy kierować na adres: Jakuba Szczerba prowadzącego działalność gospodarczą pod firmą Termipol, ul. Powstańców Śl. 64, 42-700 Lubliniec lub adres email:<a href="https://ipomiar.pl/kontakt">https://ipomiar.pl/kontakt</a>.
                </div>
            </ScrollArea>
        </div>
    );

export default TosPrivacy;