import {Button, Modal, Table} from "react-bootstrap";
import * as React from "react";
import UserContext, {IUserContext} from "../UserContext";
import {FormattedMessage} from "react-intl";
import {ISmsNotification} from "../../store/SmsNotificationStore";
import {connect} from "react-redux";
import {IAppStore} from "../../store/configureStore";
import {bindActionCreators} from "redux";
import {SmsNotificationActionCreators} from "../../actions/SmsNotificationActionCreators";
import {useEffect} from "react";
import Spinner from "../Spinner";
import PhoneNumberRecord from "./PhoneNumberRecord";
import {IRegisteredPhoneNumberForm} from "../../constants/SmsNotificationConstants";
import PhoneRegistrationDialog from "./PhoneRegistrationDialog";
import PhoneConfirmDialog from "./PhoneConfirmDialog";

interface IPhoneNumberListProps extends ISmsNotification {
    showPhoneDialog: () => void;
    closePhonesListDialog: () => void;
    loadPhoneNumbers: () => void;
    showConfirmDialog: (phoneNumber:string) => void;
    savePhoneNumbersActivity: (model: IRegisteredPhoneNumberForm[]) => void;
    activePhoneNumber: (phoneNumber: string) => void;
    deletePhoneNumber: (phoneNumber: string) => void;
    disabled: boolean;
}

const PhoneNumberList = (props: IPhoneNumberListProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const savePhoneNumbers = () => props.savePhoneNumbersActivity(
        props.phoneNumberList.map(
            p => {
                return {
                    phoneNumber: p.phoneNumber,
                    isActive: p.isActive,
                    toDelete: p.toDelete
                }
            }
        )
    );

    useEffect(() => {
        props.loadPhoneNumbers();
    }, []);

    return (
        <Modal centered show={true} onHide={props.closePhonesListDialog} className={"notification-list " + userContext.style}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="notifications.phone.list"/>
                </Modal.Title>
            </Modal.Header>
            {props.loading
                ? <Spinner/>
                : <Modal.Body>
                    <div className="row">
                        {props.phoneNumberList != null && props.phoneNumberList.length > 0 ?
                            <div className="col-sm-12">
                                <Table responsive borderless={true}>
                                    <thead>
                                    <tr>
                                        <th className="phone-number"><FormattedMessage id="notifications.phone.list.number"/></th>
                                        <th><FormattedMessage id="notifications.phone.list.active"/></th>
                                        <th><FormattedMessage id="notifications.phone.list.delete"/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.phoneNumberList.map(p => (
                                        <PhoneNumberRecord
                                            phoneNumber={p.phoneNumber}
                                            isActive={p.isActive}
                                            isConfirm={p.isConfirmed}
                                            toDelete={p.toDelete}
                                            deleteNumber={props.deletePhoneNumber}
                                            changeActivityStatus={props.activePhoneNumber}
                                            showConfirmDialog={props.showConfirmDialog}
                                            key={p.phoneNumber}/>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            : null
                        }

                        <div className="col-sm-12" style={{padding: "10px"}}>
                            <Button
                                style={{marginRight: "10px"}}
                                disabled={props.disabled}
                                onClick={props.showPhoneDialog}>
                                <i className="material-icons">add</i>
                                <FormattedMessage id="notifications.phone.show.add"/>
                            </Button>
                            <Button
                                disabled={props.disabled || props.isSaved}
                                onClick={savePhoneNumbers}>
                                <i className="material-icons">save</i>
                                <FormattedMessage id="notifications.save"/>
                            </Button>
                        </div>
                    </div>
                    {props.showPhoneRegistrationDialog ?
                        <PhoneRegistrationDialog />
                        : null
                    }
                    {props.showPhoneConfirmDialog ?
                        <PhoneConfirmDialog />
                        : null
                    }
                </Modal.Body>
            }
        </Modal>
    );
}

export default connect(
    (state: IAppStore) => state.smsNotification,
    (dispatch: any) => bindActionCreators(SmsNotificationActionCreators, dispatch)
)(PhoneNumberList);
