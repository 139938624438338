import promiseDispatch from "./promiseDispatch";
import DeviceSmogAPI from "../api/DeviceSmogAPI";
import { SmogActions, IDeviceSmogRecord } from "../constants/SmogConstants";

export const SmogActionCreators = {
    fetchData: (deviceId: number, from: Date, to: Date) => async (dispatch: any, getState: any) => {
        promiseDispatch<IDeviceSmogRecord>(
            dispatch,
            () => DeviceSmogAPI.fetchData(deviceId, from, to),
            {
                request: SmogActions.FETCH_DATA,
                success: SmogActions.FETCH_DATA_SUCCESS,
                failure: SmogActions.FETCH_DATA_FAILED
            });
    }
};