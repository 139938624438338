import * as React from "react";
import { useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RegisterDeviceActionCreators } from "actions/RegisterDeviceActionCreators";
import { IRegisterDeviceForm } from 'constants/Constants';
import '../../less/RegisterDevice.css';
import { IAppStore } from "store/configureStore";
import UserContext, { IUserContext } from '../UserContext';
import { Navigate, useNavigate } from "react-router-dom";
import SubmitButton from "../SubmitButton";

interface IRegisterDeviceProps {
    submit: (model: IRegisterDeviceForm, successAction: () => void) => void,
    validationErrorField: (fieldName: string, value: string) => void;
    editField: (fieldName: string, value: string) => void,
    resetFields: () => void,
    deviceKey: string,
    deviceKeyValidationError: string,
    deviceName: string
    deviceNameValidationError: string,
    isSubmitted: boolean
}

const RegisterDevice = (props: IRegisterDeviceProps) => {
    const navigate = useNavigate();
    const userContext = React.useContext<IUserContext>(UserContext);
    if (!userContext.isAuthenticated)
        return (<Navigate to="/portal" />);

    useEffect(() => {
        props.resetFields();
        return () => {
            props.resetFields();
        }

    }, []);

    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.value;
        const name = target.name;

        props.editField(name, value);

        if (name === "key" && props.deviceKeyValidationError) {
            if (isValidDeviceKey(value))
                props.validationErrorField("key", null);
        }

        if (name === "name" && props.deviceNameValidationError) {
            if (isValidDeviceName(value))
                props.validationErrorField("name", null);
        }
    }

    const closeDialog = () => {
        props.resetFields();
        navigate(-1);
    }

    const isValidDeviceKey = (value: string) => {
        const key = (value || "");
        return !(key.length > 8 || key.length < 8);

    }

    const isValidDeviceName = (value: string) => {
        const key = (value || "");
        return !(key.length === 0 || key.length > 128);

    }

    const submit = () => {
        const isValidKey = isValidDeviceKey(props.deviceKey);
        const isValidName = isValidDeviceName(props.deviceName);

        if (isValidKey && isValidName) {
            props.submit({
                key: props.deviceKey,
                name: props.deviceName
            }, null);
        } else {
            if (!isValidKey)
                props.validationErrorField("key", "page.registration.register.device.invalidKey");
            if (!isValidName)
                props.validationErrorField("name", "page.registration.register.device.invalidName");
        }
    }

    if (props.isSubmitted && !props.deviceNameValidationError && !props.deviceKeyValidationError)
        return (<Navigate to='/main' />);

    return (
        <div className="register-device">
            <div className="modal" role="dialog" style={{ display: "block" }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                <FormattedMessage id="page.registration.register.device" />
                            </h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={closeDialog}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="control-label">
                                    <FormattedMessage id="page.registration.device.register.key" />
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="key"
                                    value={props.deviceKey}
                                    onChange={handleInputChange} />
                                {props.deviceKeyValidationError ?
                                    <span className="help-block"><FormattedMessage id={props.deviceKeyValidationError} />!</span>
                                    : null
                                }
                            </div>
                            <div className="form-group">
                                <label className="control-label">
                                    <FormattedMessage id="page.registration.device.register.name" />
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={props.deviceName}
                                    onChange={handleInputChange} />
                                {props.deviceNameValidationError ?
                                    <span className="help-block"><FormattedMessage id={props.deviceNameValidationError} />!</span>
                                    : null
                                }
                            </div>
                            <div className="form-group">
                                <SubmitButton variant="success" className="register-button" onClick={submit}>
                                    <i className="material-icons">add_circle</i>
                                    <FormattedMessage id="page.registration.register.device" />
                                </SubmitButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default connect(
    (state: IAppStore) => state.registerDevice,
    (dispatch: any) => bindActionCreators(RegisterDeviceActionCreators, dispatch)
)(RegisterDevice);