import {IRegisteredPhoneNumber, SmsNotificationActions} from "../constants/SmsNotificationConstants";

export interface ISmsNotification{
    showPhoneRegistrationDialog: boolean;
    showPhoneConfirmDialog: boolean;
    showRegulation: boolean;
    isSaved: boolean;
    message?: string;
    smsEnabled: boolean;
    loading: boolean;
    editingPhoneNumber: string;
    editingPhoneCode: string;
    phoneNumberList: IRegisteredPhoneNumber[];
}

const initialState: ISmsNotification = {
    message: null,
    loading: false,
    showPhoneRegistrationDialog: false,
    showPhoneConfirmDialog: false,
    showRegulation: false,
    smsEnabled: false,
    isSaved: true,
    editingPhoneNumber: null,
    editingPhoneCode: null,
    phoneNumberList: []
};

export const reducer = (state: ISmsNotification, action: any): ISmsNotification => {
    state = state || initialState;
    switch (action.type) {
        case SmsNotificationActions.DISPLAY_REGULATION:
            return { ...state, showRegulation: action.payload };
        case SmsNotificationActions.SHOW_PHONE_DIALOG:
            return { ...state, showPhoneRegistrationDialog: true };
        case SmsNotificationActions.SHOW_CONFIRM_DIALOG:
            return { ...state, showPhoneConfirmDialog: true, editingPhoneNumber: action.payload };
        case SmsNotificationActions.CLOSE_PHONE_DIALOG:
            return {
                ...state,
                showPhoneRegistrationDialog: false,
                showPhoneConfirmDialog: false,
                message: null
            };
        case SmsNotificationActions.SET_PHONE_NUMBER:
            return {
                ...state,
              editingPhoneNumber: action.payload
            };
        case SmsNotificationActions.SET_PHONE_NUMBER_CODE:
            return {
                ...state,
                editingPhoneCode: action.payload,
            };
        case SmsNotificationActions.ACTIVE_PHONE_NUMBER:
            return {
                ...state,
                isSaved: false,
                phoneNumberList: 
                    state.phoneNumberList.map(p => {
                        if (p.phoneNumber === action.payload)
                            return {phoneNumber: p.phoneNumber, isActive: !p.isActive, isConfirmed: p.isConfirmed, toDelete: false}
                        return p;
                    })
            };
        case SmsNotificationActions.DELETE_PHONE_NUMBER:
            return {
                ...state,
                isSaved: false,
                phoneNumberList:
                    state.phoneNumberList.map(p => {
                        if (p.phoneNumber === action.payload)
                            return {phoneNumber: p.phoneNumber, isActive: false, isConfirmed: p.isConfirmed, toDelete: true}
                        return p;
                })
            };
        case SmsNotificationActions.SAVE_PHONE_NUMBERS_ACTIVITY:
            return {
                ...state,
                isSaved: false,
                loading: true
            };
        case SmsNotificationActions.SAVE_PHONE_NUMBERS_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                isSaved: true,
                phoneNumberList: state.phoneNumberList.filter(obj => obj.toDelete != true)
            };
        case SmsNotificationActions.SAVE_PHONE_SUCCESS:
            return {
                ...state,
                smsEnabled: false,
                showPhoneRegistrationDialog: false,
                phoneNumberList:
                    [... state.phoneNumberList, {phoneNumber: state.editingPhoneNumber, toDelete: false, isActive: false, isConfirmed: false }],
                editingPhoneNumber: null
            };
        case SmsNotificationActions.SAVE_PHONE_FAILED:
            return {
                ...state,
                message: "notifications.phone.cant.send.confirmation.sms"
            }
        case SmsNotificationActions.SAVE_PHONE_CODE_SUCCESS:
            return {
                ...state,
                showPhoneRegistrationDialog: false,
                showPhoneConfirmDialog: false,
                phoneNumberList:
                    state.phoneNumberList.map(p => {
                        if (p.phoneNumber === state.editingPhoneNumber)
                            return {phoneNumber: p.phoneNumber, isActive: true, isConfirmed: true, toDelete: p.toDelete}
                        return p;
                    }),
                editingPhoneNumber:null,
                editingPhoneCode:null,
            };
        case SmsNotificationActions.SAVE_PHONE_CODE_FAILED:
            return {
                ...state,
                showPhoneConfirmDialog: true,
                message: "notifications.phone.incorrect.code"
            };
        case SmsNotificationActions.GET_REGISTERED_PHONE_NUMBERS:
            return {
                ...state,
                loading: true,
            };
        case SmsNotificationActions.GET_REGISTERED_PHONE_NUMBERS_FAILED:
            return {
                ...state,
                loading: false,
                message: "ERROR"
            };
        case SmsNotificationActions.GET_REGISTERED_PHONE_NUMBERS_SUCCESS:
            const payload = action.payload as IRegisteredPhoneNumber[];
            return {
                ...state,
                loading: false,
                phoneNumberList: payload.slice()
            };
        default:
            return state;
    }
}