import * as React from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import {GetDateTime, getParameterIcon} from 'constants/Common';
import {AlertLevel, IDeviceTile} from "constants/Constants";
import {SubscriptionType} from "constants/SubscriptionConstants";
import "../../less/DashboardListItem.css";
import OnlineOffline from "../OnlineOffline";
import BatteryIcon, {BatteryPosition} from "./BatteryIcon";
import {LinkContainer} from "react-router-bootstrap";
import {DraggableProvidedDragHandleProps} from "react-beautiful-dnd";

interface IDashboardTileProps {
    data: IDeviceTile;
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const DashboardListHeader = () => {
    return (
        <div className="list-item-header">
            <div className="sensor-list"/>
            <div className="name"><FormattedMessage id="dashboard.header.name"/></div>
            <div className="status"><FormattedMessage id="dashboard.header.status"/></div>
            <div className="lastUpdate"><FormattedMessage id="dashboard.header.lastUpdate"/></div>
            <div className="value"><FormattedMessage id="dashboard.header.value"/></div>
            <div className="battery-box"><FormattedMessage id="dashboard.header.battery"/></div>
        </div>);
}

const DraggableListItem = (props: IDashboardTileProps) => {
    return (<div style={{position: "relative"}}>
        <div className="drag-handler" {...props.dragHandleProps}>
            <span className="material-icons">drag_indicator</span>
        </div>
        <LinkContainer to={`/device/${props.data.deviceId}`} key={props.data.deviceId}>
            <div><DashboardListItem data={props.data}/></div>
        </LinkContainer>
    </div>);
}

const DashboardListItem = (props: IDashboardTileProps) => {
    const tile = props.data;
    const intl = useIntl();
    return (<div key={tile.deviceId} className="list-item">
        <div className="sensor-list">
            {(tile.deviceParameterNames || []).map(dp => <span
                className={getParameterIcon(dp.parameterId)}
                title={intl.formatMessage({id: `device.chart.parameter_${dp.parameterId}`})}
                key={`sensor_${dp.parameterId}`}
            />)}
        </div>
        <div className="name"> {tile.name} {tile.subscriptionTypeId === SubscriptionType.Premium ?
            <span title="PREMIUM" className="icon-ipomiar-premium"/> : null} </div>
        <OnlineOffline isOnline={tile.isOnline}/>
        <div className="lastUpdate"> {GetDateTime(tile.lastUpdate)} </div>
        <div className={"value" + ((tile.values || []).length > 1 ? "-list" : "")}>
            {(tile.values || []).length > 0 ?
                tile.values.map((val) => {
                    const parameter = (tile.deviceParameterNames || []).filter(dpn => dpn.parameterId === val.parameterId)[0];
                    const parameterName = parameter ? parameter.parameterName : null;
                    const parameterUnit = parameter ? parameter.unit : null;
                    return (<span className={(val.alert === AlertLevel.Above ? "above" : val.alert == AlertLevel.Below ? "below" : "normal")}
                                  key={`values_${val.parameterId}`}
                                  title={parameterName ? parameterName : intl.formatMessage({id: `device.chart.parameter_${val.parameterId}`})}>
                        {val.value.toFixed(1)}
                        {parameterUnit ? <span> {parameterUnit} </span> : <FormattedMessage id={`device.chart.parameter.unit_${val.parameterId}`}/>}
                    </span>);
                })
                : <i className="material-icons">remove</i>}
        </div>
        <div className="battery-column">
            <BatteryIcon
                batteryLevel={tile.batteryLevel}
                title={intl.formatMessage({id: "device.edit.battery"})}
                position={BatteryPosition.Vertical}/>
        </div>
    </div>);
}
export default DraggableListItem;