import { DashboardActions, IDeviceTile, DashboardMode } from "../constants/Constants";
import promiseDispatch from "./promiseDispatch";
import DeviceDataAPI from "../api/DeviceDataAPI";

export const DashboardActionCreators = {
    fetchDashboard: () => async (dispatch: any, getState: any) => {
        promiseDispatch<IDeviceTile[]>(
            dispatch,
            DeviceDataAPI.fetchDashboard,
            {
                request: DashboardActions.FETCH_DASHBOARD,
                success: DashboardActions.FETCH_DASHBOARD_SUCCESS,
                failure: DashboardActions.FETCH_DASHBOARD_FAILED
            });
    },

    storeDashboardMode: (mode: DashboardMode) => async (dispatch: any, getState: any) => {
        dispatch({ type: DashboardActions.CHANGE_DASHBOARD_MODE, payload: mode });
        promiseDispatch<string>(
            dispatch,
            () => DeviceDataAPI.storeDashboardMode(mode),
            {
                request: DashboardActions.STORE_DASHBOARD_MODE,
                success: DashboardActions.STORE_DASHBOARD_MODE_SUCCESS,
                failure: DashboardActions.STORE_DASHBOARD_MODE_FAILED
            });
    },

    storeDashboardDeviceOrder: (deviceIds: number[], successFunction?: () => void) => async (dispatch: any, getState: any) => {
        dispatch({ type: DashboardActions.STORE_DEVICES_ORDER, payload: deviceIds });
        promiseDispatch<string>(
            dispatch,
            () => DeviceDataAPI.storeDashboardDevicesOrder(deviceIds),
            {
                request: DashboardActions.STORE_DEVICES_ORDER,
                success: DashboardActions.STORE_DEVICES_ORDER_SUCCESS,
                failure: DashboardActions.STORE_DEVICES_ORDER_FAILED
            },
            successFunction);
    },
};