import * as React from "react";
import { Form } from "react-bootstrap";
import {FormEvent} from "react";

export interface ISubmitForm {
    children?: React.ReactNode;
}

const handleSubmit = (e : FormEvent) => {
    e.preventDefault();
}

const SubmitForm = (props: ISubmitForm) => {
    return (
        <Form onSubmit={handleSubmit}>
            {props.children}
        </Form>
    );
}
export default SubmitForm;