import * as React from "react";
import { Button, Col, FormControl, FormGroup, Row } from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import "../../../less/DeviceDetailsEdit.css";

import { FormattedMessage, useIntl } from "react-intl";
import { IParameterSettings, MeasurableParameter } from "constants/Constants";
import { IDeviceDetails, IDeviceParameter, IEditedDevice } from "constants/DeviceConstants";
import Switch from "../../../controles/Switch";
import DeviceDetailsFormControl from "./DeviceDetailsFormControl";
import DeviceDetailsLocation, { ValidLocation } from "./DeviceDetailsLocation";
import DeviceDetailsOperatingMode from "./DeviceDetailsOperatingMode";
import DeviceDetailsSamplingRadio from "./DeviceDetailsSamplingRadio";
import DeviceParametersSettings, { ValidateDeviceParametersSettings } from "../DeviceParameters/DeviceParametersSettings";
import DeviceActionsContainer from "./DeviceActions";
import {IsSmogDevice} from "constants/Common";
import SubmitButton from "../../SubmitButton";


const ValidateName = (name: any): boolean => (name && typeof name === "string" && name.length < 128);

const ValidateNumber = (num: any): boolean => (num !== null && !isNaN(Number(num)));

const ValidateSampling = (sampling: any): boolean => sampling;

const ValidateThreshold = (threshold: any): boolean => !threshold || ValidateNumber(threshold);

const ValidateThresholds = (editForm: IEditedDevice): boolean => {
    let result: boolean = true;

    (editForm.parameterSettings || []).forEach((settings: IParameterSettings) => {
        const thresholdMax = settings.thresholdMax;
        const thresholdMin = settings.thresholdMin;

        if (!(ValidateThreshold(thresholdMax) && ValidateThreshold(thresholdMin))) {
            result = false;
            return;
        }

        if (Number(thresholdMax) <= Number(thresholdMin) && !(thresholdMax === null) && !(thresholdMin === null)) {
            result = false;
            return;
        }
    });
    
    return result;
}

const IsFormValid = (editForm: IEditedDevice): boolean => {
    if (editForm === null) { return null; }
    return ValidateName(editForm.name)
        && ValidateSampling(editForm.sampling)
        && ValidateThresholds(editForm)
        && ValidLocation(editForm)
        && ValidateDeviceParametersSettings(editForm);
}

const DeviceDetailsEdit = (
    props: {
        device: IDeviceDetails,
        editForm: IEditedDevice,
        editDeviceForm: (editedForm: IEditedDevice) => void,
        saveDeviceDetails: (form: IEditedDevice) => void,
        cancelEditing: () => void
    }
) => {
    
    const intl = useIntl();
    const editForm = props.editForm;
    const left = 6;
    const right = 6;

    const saveChanges = (e: any) => {
        e.preventDefault();
        if (IsFormValid(props.editForm))
            props.saveDeviceDetails(props.editForm);
    };

    const cancelEditing = (e: any) => {
        props.cancelEditing();
    }

    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const form = { ...props.editForm };
        (form as any)[name] = value;
        props.editDeviceForm(form);
    }

    const handleLocationChange = (latitude: string, longitude: string) => {
        props.editDeviceForm({ ...props.editForm, latitude, longitude });
    }

    const batteryToggleChange = (parameter: IDeviceParameter) => {
        const changedThresholds = props.editForm.parameterSettings.filter(t => t.parameterId === parameter.parameterId)[0];
        changedThresholds.thresholdMin = changedThresholds.thresholdMin > 0 ? 0 : 10;
        changedThresholds.thresholdMax = null;
        props.editDeviceForm({ ...props.editForm, parameterSettings: [...props.editForm.parameterSettings] });
    }

    const disconnectedAlertChange = () => {
        const disconnectedAlertChangeState = !props.editForm.disconnectedAlert;
        props.editDeviceForm({ ...props.editForm, disconnectedAlert: disconnectedAlertChangeState });
    }

    const publishDeviceData = () => {
        const publicData = !props.editForm.publicData;
        props.editDeviceForm({ ...props.editForm, publicData });
    }

    const isOperatingModeVisible = (props.device.parameters || []).filter(p => p != null && p.parameterId === MeasurableParameter.Level).length > 0;
    const isSmogDevice = IsSmogDevice(props.device.parameters);
    return (
        <div className="device-details">
            <Row>
                <Col md={6}>
                    <FormGroup className="form-buttons">
                        <Col xs={12}>
                            <SubmitButton disabled={!IsFormValid(editForm)} onClick={saveChanges}>
                                <i className="material-icons">save</i> <FormattedMessage id="device.edit.save" />
                            </SubmitButton> {' '}
                            <Button className="btn btn-default" type="button" onClick={cancelEditing}>
                                <i className="material-icons">remove_circle</i> <FormattedMessage id="device.edit.cancel" />
                            </Button>
                            <DeviceParametersSettings editForm={props.editForm} editFormEvent={props.editDeviceForm}/>
                        </Col>
                    </FormGroup>

                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <DeviceDetailsFormControl labelId="device.edit.key" left={12}>
                                <div className="form-key-value">{props.device.key}</div>
                            </DeviceDetailsFormControl>
                        </Col>
                        <Col md={6}>
                            <DeviceDetailsFormControl
                                className="device-publish"
                                labelId="device.edit.publish.data"
                                titleId={isSmogDevice ? "device.smog.edit.publish.data.desc" : "device.edit.publish.data.desc"}
                                left={12}>
                                <Switch
                                    onChange={publishDeviceData}
                                    checked={props.editForm.publicData}
                                />
                            </DeviceDetailsFormControl>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <DeviceDetailsFormControl
                        labelId="device.edit.name"
                        left={left} right={right}
                        validated={!ValidateName(editForm.name) ? false : null}>
                        <FormControl type="text"
                            className="device-name-text-box"
                            placeholder={
                                intl.formatMessage({
                                    id: "device.edit.name"
                                })
                            }
                            name="name"
                            defaultValue={editForm.name}
                            onChange={handleInputChange} />
                    </DeviceDetailsFormControl>
                </Col>
                <Col md={6}>
                    <DeviceDetailsSamplingRadio
                        editForm={props.editForm}
                        device={props.device}
                        handleInputChange={handleInputChange} />
                </Col>

                <Col md={6}>
                    <DeviceDetailsFormControl
                        labelId="device.edit.disconnected-alert"
                        left={left} right={right}>
                        <Switch
                            onChange={disconnectedAlertChange}
                            checked={props.editForm.disconnectedAlert}
                        />
                    </DeviceDetailsFormControl>
                </Col>
                {(isOperatingModeVisible ?
                    <Col md={6}>
                        <DeviceDetailsOperatingMode
                            device={props.device}
                            editForm={props.editForm}
                            handleInputChange={handleInputChange}
                        />
                    </Col> : null)}
                <Col md={6}>
                    <DeviceDetailsLocation
                        editForm={props.editForm}
                        left={left}
                        right={4}
                        handleLocationChange={handleLocationChange} />
                </Col>
                {(props.device.parameters || [])
                    .filter(p => p != null && p.parameterId === MeasurableParameter.Battery)
                    .map((parameter: IDeviceParameter) => {
                        const settings = props.editForm.parameterSettings.filter(pt => pt.parameterId === parameter.parameterId)[0];
                        return (
                            <Col md={6} key={`key_${parameter.parameterId}`}>
                                <DeviceDetailsFormControl
                                    labelId="device.edit.threshold.battery"
                                    left={left} right={right}>
                                    <Switch
                                        onChange={batteryToggleChange.bind(this, parameter)}
                                        checked={settings.thresholdMin > 0}
                                    />
                                </DeviceDetailsFormControl>
                            </Col>
                        );
                    })}
            </Row>
            <DeviceActionsContainer />
        </div>
    );
}

export default DeviceDetailsEdit;