import * as React from "react";
import { useState, useEffect } from 'react';
import "../../less/Dashboard.css"
import "../../less/DashboardListItem.css";
import "../../less/Supervisor.css";
import { FormattedMessage } from 'react-intl';
import {ISupervisedDevice} from "constants/SupervisorConstants";
import OnlineOffline from "components/OnlineOffline";
import {GetDateTime} from "constants/Common";
import ScrollArea from "react-scrollbar";

const Supervisor = () => {
    const [data, setData] = useState<ISupervisedDevice[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch("/api/supervised-devices");
            const result = await response.json() as ISupervisedDevice[];
            setData(result);
        };
        fetchData();
    }, []);

    return (
        <div className="supervisor">
            <h3><i className="material-icons">visibility</i>{" "}<FormattedMessage id="supervisor" defaultMessage="Supervisor" /></h3>
            <div className="main-dashboard-container">
                <div className="list-item-header">
                    <div className="key"><FormattedMessage id="dashboard.header.key" /></div>
                    <div className="name"><FormattedMessage id="dashboard.header.name" /></div>
                    <div className="status"><FormattedMessage id="dashboard.header.status" /></div>
                    <div className="lastUpdate"><FormattedMessage id="dashboard.header.registrationDate" /></div>
                    <div className="lastUpdate"><FormattedMessage id="dashboard.header.lastUpdate" /></div>
                </div>
                <ScrollArea
                    speed={0.8}
                    className="scroll-area"
                    horizontal={false}
                >
                {(data || []).map(d=> (
                    <div key={d.deviceKey} className="list-item">
                        <div className="key"> {d.deviceKey} </div>
                        <div className="name"> {d.name} </div>
                        <OnlineOffline isOnline={d.isOnline} />
                        <div className="lastUpdate"> {GetDateTime(d.registrationDate)} </div>
                        <div className="lastUpdate"> {GetDateTime(d.lastMeasurementDate)} </div>
                    </div>))}
                </ScrollArea>
            </div>
        </div>);
}
export default Supervisor;