import { IDevice, DeviceActions } from '../constants/DeviceConstants';
const initialState: IDevices = { records: [], message: null };

export interface IDevices {
    records: IDevice[];
    message: string;
}

export const reducer = (state: IDevices, action: any): IDevices => {
    state = state || initialState;
    switch (action.type) {
        case DeviceActions.FETCH_DEVICES:
            return { ...state, records: [], message: "Loading" };
        case DeviceActions.FETCH_DEVICES_SUCCESS:
            return { ...state, records: action.payload, message: null };
        case DeviceActions.FETCH_DEVICES_FAILED:
            return { ...state, records: [], message: action.payload };
        case DeviceActions.DELETED:
            return { ...state, records: [...state.records.filter((r: IDevice) => r.deviceId !== action.payload.deviceId)], message: null }
        default:
            return state;
    }
};