import * as React from "react";
import { useIntl } from "react-intl";
import UserContext, {IUserContext} from "components/UserContext";

const ChangeStateSign = (
    props: {
        hide: boolean
    }
): JSX.Element => {
    const intl = useIntl();
    const userContext = React.useContext<IUserContext>(UserContext);
    return !props.hide
        ? (<i title={intl.formatMessage({ id: "device.edit.sampling.change" })} className={"change " + userContext.style +" material-icons"}> info </i>)
        : null;
};

export default ChangeStateSign;