import {FormattedMessage, useIntl} from "react-intl";
import * as React from "react";
import {Button, Modal} from "react-bootstrap";
import UserContext, {IUserContext} from "components/UserContext";
import {IParameterSettings, MeasurableParameter} from "constants/Constants";

import "../../../less/DeviceParameterConverter.css";
import ParameterLimits from "./ParameterLimits";
import ConverterForm from "./ConverterForm";
import TankModal from "./TankForm";

const ParameterConverterModal = (
    props: {
        parameter: IParameterSettings,
        handleSensorChange: (parameter: IParameterSettings) => void,
    }
) => {
    const intl = useIntl();
    const [dialog, showDialog] = React.useState(false);
    const [advancedMode, showAdvancedMode] = React.useState(false);
    const [modalValidate, checkModalValidation] = React.useState(true);

    const handleClose = () => {
        if(!modalValidate)
            return;
        showAdvancedMode(false);
        showDialog(false);
    }
    const handleShow = () => showDialog(true);
    const userContext = React.useContext<IUserContext>(UserContext);

    const standardModal = (
        <Modal centered show={dialog} onHide={handleClose} className={"device-parameter-converter " + userContext.style} dialogClassName="scaling-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.parameter.parameterId !== MeasurableParameter.Distance 
                        ? <FormattedMessage id="device.edit.converter" />
                        : <FormattedMessage id="device.edit.converter.tank-settings" />}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {props.parameter.parameterId !== MeasurableParameter.Distance || advancedMode === true
                    ? (<>
                        <ConverterForm 
                            parameter={props.parameter}
                            handleSensorChange={props.handleSensorChange}
                            handleValidationCheck={checkModalValidation} />
                        <hr/>
                        <ParameterLimits {...props }/></>) 
                    : (<>
                            <TankModal
                                parameter={props.parameter}
                                handleSensorChange={props.handleSensorChange}
                                handleValidationCheck={checkModalValidation}/>
                            <hr/>
                            <div onClick={() => showAdvancedMode(true)} className="advanced-button"> Advanced &raquo; </div>
                        </>
                    )
                    
                }
                <Button onClick={handleClose} disabled={!modalValidate}>
                    <FormattedMessage id="device.edit.close" />
                </Button>
            </Modal.Body>
        </Modal>
    );

    return (
        <span className={"device-parameter-converter " + userContext.style}>
             <button className="btn scaling-button" onClick={handleShow} title={intl.formatMessage({id: "device.edit.converter"})}>
             <span className="material-icons">auto_graph</span>
            </button>
            {dialog ? standardModal : null}
        </span>
    );
}

export default ParameterConverterModal;