import {Button, Modal, Table} from "react-bootstrap";
import * as React from "react";
import UserContext, {IUserContext} from "../UserContext";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {IAppStore} from "../../store/configureStore";
import {bindActionCreators} from "redux";
import {useEffect} from "react";
import Spinner from "../Spinner";
import {IRegisteredEmail, IRegisteredEmailForm} from "../../constants/EmailNotificationConstants";
import EmailRecord from "./EmailRecord";
import {EmailNotificationActionCreators} from "../../actions/EmailNotificationActionCreators";
import {IEmailNotification} from "../../store/EmailNotificationStore";
import EmailRegistrationDialog from "./EmailRegistrationDialog";

interface IEmailListProps extends IEmailNotification {
    closeEmailListDialog: () => void;
    showEmailRegistrationDialog: () => void;
    loadEmails: () => void;
    saveEmailActivity: (model: IRegisteredEmailForm[]) => void;
    activeEmail: (email: string, isActive: boolean, isReportActive: boolean) => void;
    deleteEmail: (toDelete:boolean, email: string) => void;
    disabled: boolean;
    emailList: IRegisteredEmail[];
}

const EmailList = (props: IEmailListProps) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    const saveEmailAddress = () => {
        props.saveEmailActivity(
            props.emailList.map(
                p => {
                    return {
                    email: p.email,
                    isActive: p.isActive,
                    isReportActive: p.isReportActive,
                    toDelete: p.toDelete
                }
            })
        );
        props.closeEmailListDialog();
    }

    useEffect(() => {
        props.loadEmails();
    }, []);

    return (
            <Modal centered show={true} onHide={props.closeEmailListDialog} className={"notification-list " + userContext.style}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="notifications.email.list"/>
                </Modal.Title>
            </Modal.Header>
            {props.loading
                ? <Spinner/>
                : <Modal.Body>
                    <div className="row">
                        {props.emailList != null && props.emailList.length > 0 ?
                            <div className="col-sm-12">
                                <Table responsive borderless={true}>
                                    <thead>
                                    <tr>
                                        <th className="phone-number"><FormattedMessage
                                            id="notifications.email.list.address"/></th>
                                        <th><FormattedMessage id="notifications.email.list.active"/></th>
                                        <th><FormattedMessage id="notifications.email.list.report.active"/></th>
                                        <th><FormattedMessage id="notifications.email.list.delete"/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.emailList.map(p => (
                                        <EmailRecord
                                            email={p.email}
                                            isLocked={p.email === userContext.userName}
                                            isActive={p.isActive}
                                            isReportActive={p.isReportActive}
                                            isConfirm={p.isConfirmed}
                                            toDelete={p.toDelete}
                                            deleteEmail={props.deleteEmail}
                                            changeActivityStatus={props.activeEmail}
                                            key={p.email}/>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            : null
                        }
                        
                        <div className="col-sm-12" style={{padding: "10px"}}>
                            <Button
                                style={{marginRight: "10px"}}
                                disabled={props.disabled}
                                onClick={props.showEmailRegistrationDialog}>
                                <i className="material-icons">add</i>
                                <FormattedMessage id="notifications.email.show.add"/>
                            </Button>
                            <Button
                                disabled={props.disabled || props.isSaved}
                                onClick={saveEmailAddress}>
                                <i className="material-icons">save</i>
                                <FormattedMessage id="notifications.save"/>
                            </Button>
                        </div>
                    </div>
                    {props.showEmailRegistration 
                        ? <EmailRegistrationDialog />
                        : null
                    }
                </Modal.Body>
            }
        </Modal>
    );
}

export default connect(
    (state: IAppStore) => state.emailNotification,
    (dispatch: any) => bindActionCreators(EmailNotificationActionCreators, dispatch)
)(EmailList);
