import {FormattedMessage} from "react-intl";
import Switch from "../../controles/Switch";
import {Button} from "react-bootstrap";
import * as React from "react";
import UserContext, {IUserContext} from "../UserContext";

const MobileAppSettings = (props: {
    unRegisterMobileDevices: () => void;
    isDisabled: boolean,
    lastRegisteredDevice: string;
}) => {
    const userContext = React.useContext<IUserContext>(UserContext);
    return (<>
            <div className="row content">
                <div className="col-md-12"><h4><FormattedMessage id="notifications.mobile.title"/></h4></div>
            </div>
            <div className="row content">
                <div className="col-sm-4 flex-box">
                    <div className="input-group center-element">
                        <Switch
                            checked={props.lastRegisteredDevice != null}
                            disabled={props.isDisabled}
                        />
                        {props.lastRegisteredDevice
                            ? <span>
                                        <FormattedMessage
                                            id="notifications.mobile.lastregistered"/> : <strong>{props.lastRegisteredDevice}</strong>
                                      </span>
                            : <span><FormattedMessage id="notifications.mobile.nodevices"/></span>}
                    </div>
                </div>
                <div className="col-sm-6">
                    <FormattedMessage id="notifications.mobile.description"
                                      values={{
                                          appName: <a href={userContext.androidLink}
                                                      target="_blank">{userContext.applicationName}</a>
                                      }}/>
                </div>
                <div className="col-sm-2">
                    <Button
                        className="pull-right"
                        disabled={props.lastRegisteredDevice == null || props.isDisabled}
                        onClick={props.unRegisterMobileDevices}>
                        <i className="material-icons">close</i> <FormattedMessage id="notifications.mobile.turnoff"/>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default MobileAppSettings;