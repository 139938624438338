import * as React from "react";
import { FormattedMessage } from "react-intl";
import ScrollArea from "react-scrollbar";
import { UserContextConsumer } from "../UserContext";
import "../../less/About.css";

const About = () => {
    return (
        <UserContextConsumer>
            {userContext => userContext && (
                <div className="about">
                    <ScrollArea
                        speed={0.8}
                        className="scroll-area"
                        horizontal={false}
                    >
                        <div className="content">
                            <h3><i className="material-icons">info</i>{" "}<FormattedMessage id="about" /></h3>
                            <h4><FormattedMessage id="about.text1" values={{ applicationName: userContext.fullApplicationName }} /></h4>
                            <div className="schema">
                                <img src={require(`../../images/about-${userContext.applicationName}.jpg`)} alt="Diagram how it works" />
                                {(userContext.style === "standard") ?(
                                    <>
                                        <div style={{top: "380px", left: "10px"}}>  <FormattedMessage id="about.sensor.temp-hum" /> </div>
                                        <div style={{top: "200px", left: "50px"}}>  <FormattedMessage id="about.sensor.temp" /> </div>
                                        <div style={{top: "150px", left: "400px"}}>  <FormattedMessage id="about.sensor.smog" /> </div>
                                        <div style={{top: "200px", left: "560px"}}>  <FormattedMessage id="about.sensor.electrical" /> </div>
                                        <div style={{top: "380px", left: "630px"}}>  <FormattedMessage id="about.sensor.level" /> </div>
                                    </>
                                    ) : null}
                            </div>
                            <br />
                            <p className="about-description">
                                <FormattedMessage id="about.text2" values={{ applicationName: userContext.fullApplicationName }} />
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            )}
        </UserContextConsumer>
    );
}

export default About;