import { DashboardActions, IDeviceTile, DashboardMode } from '../constants/Constants';
const initialState: IDashboard = { tiles: [], message: null, mode: null };

export interface IDashboard {
    tiles: IDeviceTile[];
    message: string;
    mode: DashboardMode;
}

export const reducer = (state: IDashboard, action: any): IDashboard => {
    state = state || initialState;
    switch (action.type) {
        case DashboardActions.FETCH_DASHBOARD:
            return { ...state, tiles: state.tiles, message: "Loading", mode: state.mode };
        case DashboardActions.FETCH_DASHBOARD_SUCCESS:
            return { ...state, tiles: action.payload.data, message: null, mode: action.payload.mode };
        case DashboardActions.FETCH_DASHBOARD_FAILED:
            return { ...state, tiles: [], message: action.payload, mode: state.mode };
        case DashboardActions.CHANGE_DASHBOARD_MODE:
            return { ...state, mode: action.payload as DashboardMode }
        default:
            return state;
    }
}