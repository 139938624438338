export class SmsNotificationActions {
    public static readonly SAVE_PHONE = "SAVE_PHONE_NOTIFICATION";
    public static readonly SAVE_PHONE_SUCCESS = "SAVE_PHONE_SUCCESS_NOTIFICATION";
    public static readonly SAVE_PHONE_FAILED = "SAVE_PHONE_FAILED_NOTIFICATION";

    public static readonly SAVE_PHONE_NUMBERS_ACTIVITY = "SAVE_PHONE_NUMBERS_ACTIVITY";
    public static readonly SAVE_PHONE_NUMBERS_ACTIVITY_SUCCESS = "SAVE_PHONE_NUMBERS_ACTIVITY_SUCCESS";
    public static readonly SAVE_PHONE_NUMBERS_ACTIVITY_FAILED = "SAVE_PHONE_NUMBERS_ACTIVITY_FAILED";
    
    public static readonly SAVE_PHONE_CODE = "SAVE_PHONE_CODE_NOTIFICATION";
    public static readonly SAVE_PHONE_CODE_SUCCESS = "SAVE_PHONE_CODE_SUCCESS_NOTIFICATION";
    public static readonly SAVE_PHONE_CODE_FAILED = "SAVE_PHONE_CODE_FAILED_NOTIFICATION";

    public static readonly SET_PHONE_NUMBER = "SET_PHONE_NUMBER_NOTIFICATION";
    public static readonly ACTIVE_PHONE_NUMBER = "ACTIVE_PHONE_NUMBER";
    public static readonly DELETE_PHONE_NUMBER = "DELETE_PHONE_NUMBER";
    
    public static readonly SET_PHONE_NUMBER_CODE = "SET_PHONE_NUMBER_CODE_NOTIFICATION";
    public static readonly DISPLAY_REGULATION = "DISPLAY_REGULATION";

    public static readonly SHOW_PHONE_DIALOG = "SHOW_PHONE_DIALOG_NOTIFICATION";
    public static readonly CLOSE_PHONE_DIALOG = "CLOSE_PHONE_DIALOG_NOTIFICATION";
    public static readonly SHOW_CONFIRM_DIALOG = "SHOW_CONFIRM_DIALOG_NOTIFICATION";
    
    public static readonly GET_REGISTERED_PHONE_NUMBERS = "GET_REGISTERED_PHONE_NUMBERS";
    public static readonly GET_REGISTERED_PHONE_NUMBERS_SUCCESS = "GET_REGISTERED_PHONE_NUMBERS_SUCCESS";
    public static readonly GET_REGISTERED_PHONE_NUMBERS_FAILED = "GET_REGISTERED_PHONE_NUMBERS_FAILED";
}

export interface IRegisteredPhoneNumberForm {
    phoneNumber: string;
    isActive: boolean;
    toDelete: boolean;
}

export interface IRegisteredPhoneNumber extends IRegisteredPhoneNumberForm {
    isConfirmed: boolean;
}

export interface INotificationPhoneNumber {
    phoneNumber: string;
}

export interface INotificationPhoneConfirmation extends INotificationPhoneNumber {
    code: string;
}