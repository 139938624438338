import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { FormattedMessage } from "react-intl";
import { DateFormat } from "constants/Constants";
import '../less/NotificationToasts.css';
import { IUserNotification, UserContextConsumer } from './UserContext';
import * as moment from 'moment';
import { useNavigate } from 'react-router-dom';

const NotificationToasts = () => {
    let navigate = useNavigate();
    const [suppressed, setSuppressed] = React.useState(new Array<string>());
    const handleClose = (id: string) => setSuppressed(s => [...s, id]);
    const handleClick = (n: IUserNotification) => {
        setSuppressed(s => [...s, n.id]);
        navigate(n.link);
    }

    return (
        <UserContextConsumer>
            {userContext => userContext && (
                <div className="notification-toasts">
                    {(userContext.notifications || []).filter((v: IUserNotification) => !suppressed.includes(v.id)).slice(0, 4).map((n: IUserNotification) => (
                        <Toast show={true} key={`toast_${n.id}`} onClose={handleClose.bind(this, n.id)}>
                            <Toast.Header>
                                <strong className="mr-auto">{n.title}</strong>
                                <small> {moment.utc(n.date).local().format(DateFormat)}</small>
                            </Toast.Header>
                            <Toast.Body>
                                <Row>
                                    <Col xs={2}>
                                        {n.type === "BuyPremium"
                                            ? (<span title="PREMIUM" className="icon-ipomiar-premium" style={{ fontSize: '30px' }} />)
                                            : (<i className="material-icons" style={{ fontSize: '30px' }}>notification_important</i>)
                                        }
                                        </Col>
                                    <Col xs={10}><FormattedMessage id={n.body} /></Col>
                                </Row>

                                <Button variant="outline-success" size="sm" className="float-right" onClick={handleClick.bind(this, n)}>
                                    <FormattedMessage id="notifications.popup.details" />
                                </Button>
                            </Toast.Body>
                        </Toast>))
                    }
                </div>
            )}
        </UserContextConsumer >);
};

export default NotificationToasts;