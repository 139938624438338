import * as React from "react";
import { Col, Form, FormControl, Modal, Row, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IEditedDevice } from "constants/DeviceConstants";
import "../../../less/DeviceDetailsLocation.css";
import MapDialog from "./MapDialog";
import {IsNullOrWhiteSpace, ValidateNumber} from "../../../constants/Common";

const ValidateLatitude = (form: IEditedDevice): boolean => {
    if (IsNullOrWhiteSpace(form.latitude) && IsNullOrWhiteSpace(form.longitude))
        return true;

    if (ValidateNumber(form.latitude)) {
        if (Number(form.latitude) >= -90 && Number(form.latitude) <= 90)
            return true;
    }
    return false;
}

const ValidateLongitude = (form: IEditedDevice): boolean => {
    if (IsNullOrWhiteSpace(form.longitude) && IsNullOrWhiteSpace(form.latitude))
        return true;

    if (ValidateNumber(form.longitude)) {
        if (Number(form.longitude) >= -180 && Number(form.longitude) <= 180)
            return true;
    }
    return false;
}

export const ValidLocation = (editForm: IEditedDevice): boolean => {
    return ValidateLatitude(editForm)
        && ValidateLongitude(editForm)
        && IsNullOrWhiteSpace(editForm.latitude) === IsNullOrWhiteSpace(editForm.longitude);
}

const LAT_NAME: string = "latitude";
const LNG_NAME: string = "longitude";

const DeviceDetailsLocation = (
    props: {
        editForm: IEditedDevice,
        left: number,
        right: number,
        handleLocationChange: (latitude: string, longitude: string) => void,
    }
) => {
    const intl = useIntl();
    const [mapDialog, showMapDialog] = React.useState(false);

    const handleClose = () => showMapDialog(false);
    const handleShow = () => showMapDialog(true)

    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === LAT_NAME)
            props.handleLocationChange(value, props.editForm.longitude);
        else if (name === LNG_NAME)
            props.handleLocationChange(props.editForm.latitude, value);
    }

    return (
        <Row className="device-location">
            <Col>
                <label className="label">
                    <FormattedMessage id="device.edit.location" />
                    <span className="map-button" onClick={handleShow}> <i className="material-icons">my_location</i></span>
                </label>
                <Form noValidate
                    validated={(!(ValidateLatitude(props.editForm) && ValidateLongitude(props.editForm)) ? false : null)}
                    className="">
                    <Row>
                        <Col xs={12} sm={12} md={12} className="content">
                            <Row>
                                <Col>
                                    <FormControl type="text"
                                        placeholder={intl.formatMessage({ id: "device.edit.latitude.placeholder" })}
                                        name={LAT_NAME}
                                        value={props.editForm.latitude}
                                        onChange={handleInputChange}
                                        style={{
                                            display: "inline-block",
                                            width: "calc(100% - 25px)"
                                        }}
                                    /> <span>{"\u00B0"}N</span>
                                </Col>
                                <Col>
                                    <FormControl type="text"
                                        placeholder={intl.formatMessage({ id: "device.edit.longitude.placeholder" })}
                                        name={LNG_NAME}
                                        value={props.editForm.longitude}
                                        onChange={handleInputChange}
                                        style={{
                                            display: "inline-block",
                                            width: "calc(100% - 25px)"
                                        }}
                                    /> <span>{"\u00B0"}E</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
            {mapDialog
                ? <MapDialog
                    latitude={props.editForm.latitude}
                    longitude={props.editForm.longitude}
                    show={mapDialog}
                    handleClose={handleClose}
                    handleChange={(lat: number, lng: number) => { props.handleLocationChange(lat.toFixed(7), lng.toFixed(7)); }} />
                : null}
        </Row>
    );
};


export default DeviceDetailsLocation;