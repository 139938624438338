import {FormattedMessage, useIntl} from "react-intl";
import Switch from "../../controles/Switch";
import Radio from "../../controles/Radio";
import {ReportPeriodTime} from "../../constants/NotificationConstants";
import * as React from "react";

const ReportPeriodSelect = (props: {
    reportPeriodChange: (value: number) => void;
    reportToggleChange: () => void;
    isDisabled: boolean,
    summaryReportEnabled: boolean,
    userEmail: string,
    summaryReportPeriod: ReportPeriodTime
}) => {
    const intl = useIntl();

    const onSummaryReportPeriodChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = Number(target.value);
        props.reportPeriodChange(value);
    }
    
    return (
        <div className="row content">
            <div className="col-sm-4 flex-box">
                <div className="input-group center-element">
                    <Switch
                        onChange={props.reportToggleChange}
                        checked={props.summaryReportEnabled}
                        disabled={props.isDisabled}
                    />
                    <Radio name="summary-report-period" value={ReportPeriodTime.Daily}
                           checked={props.summaryReportPeriod === ReportPeriodTime.Daily}
                           onChange={onSummaryReportPeriodChange}>
                        <FormattedMessage
                            id={`notifications.summary.report.period.${ReportPeriodTime[ReportPeriodTime.Daily].toLowerCase()}`}/>
                    </Radio>
                    <Radio name="summary-report-period" value={ReportPeriodTime.Weekly}
                           checked={props.summaryReportPeriod === ReportPeriodTime.Weekly}
                           onChange={onSummaryReportPeriodChange}>
                        <FormattedMessage
                            id={`notifications.summary.report.period.${ReportPeriodTime[ReportPeriodTime.Weekly].toLowerCase()}`}/>
                    </Radio>
                    <Radio name="summary-report-period" value={ReportPeriodTime.Monthly}
                           checked={props.summaryReportPeriod === ReportPeriodTime.Monthly}
                           onChange={onSummaryReportPeriodChange}>
                        <FormattedMessage
                            id={`notifications.summary.report.period.${ReportPeriodTime[ReportPeriodTime.Monthly].toLowerCase()}`}/>
                    </Radio>
                </div>
            </div>

            <div className="col-sm-8">
                <FormattedMessage id="notifications.summary.report.description"
                                  values={{email: <strong>{props.userEmail}</strong>}}/>
            </div>
        </div>

    );
}

export default ReportPeriodSelect;