import Switch from "../../controles/Switch";
import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface IEmailRecordProps {
    email: string;
    isActive: boolean;
    isReportActive: boolean;
    isConfirm: boolean;
    toDelete: boolean;
    isLocked: boolean;
    deleteEmail: (toDelete: boolean, number: string) => void;
    changeActivityStatus: (email: string, isActive: boolean, isReportActive: boolean) => void;
}

const EmailRecord = (props: IEmailRecordProps) => {
    const intl = useIntl();
    const handleStatusNotificationChange = () => props.changeActivityStatus(props.email, !props.isActive, props.isReportActive);
    const handleStatusReportChange = () => props.changeActivityStatus(props.email, props.isActive, !props.isReportActive);
    const handleDeleteEvent = () => {
        if (!props.isLocked)
            props.deleteEmail(!props.toDelete, props.email);
    }
    const activeTrash = {cursor: "pointer"};
    const disabledTrash = {cursor: "default", color: "gray"};
    return (<tr>
        <td>{props.email}</td>
        <td className="action">
            {props.toDelete
                ? <i style={{color: "gray"}}><FormattedMessage id="notifications.phone.delete"/></i>
                : (props.isConfirm
                        ? <Switch checked={props.isActive} onChange={handleStatusNotificationChange}/>
                        : <FormattedMessage id="notifications.email.show.confirm"/>
                )
            }
        </td>
        <td className="action">
            {props.toDelete
                ? <i style={{color: "gray"}}><FormattedMessage id="notifications.phone.delete"/></i>
                : (props.isConfirm
                        ? <Switch checked={props.isReportActive} onChange={handleStatusReportChange}/>
                        : <FormattedMessage id="notifications.email.show.confirm"/>
                )
            }
        </td>
        <td>
            <div className="remove" onClick={handleDeleteEvent}>
                {props.toDelete
                    ? <i className="material-icons"
                         style={activeTrash}
                         title={intl.formatMessage({id: "notifications.email.registration.undo"})}>
                        restore_from_trash </i>
                    : <i className="material-icons"
                         style={props.isLocked ? disabledTrash : activeTrash}
                         title={intl.formatMessage({id: "notifications.email.registration.delete"})}>
                        delete_forever </i>
                }
            </div>
        </td>
    </tr>);
}

export default EmailRecord