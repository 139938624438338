import * as React from "react";
import '../less/Switch.css';

interface ISwitchProps {
    checked?: boolean;
    onChange?: () => void;
    disabled?: boolean;
}

const Switch = (props: ISwitchProps) => {

    const onChangeEvent = () => {
        if (props.onChange)
            props.onChange();
    }

    return (
        <label className="switch">
            <input type="checkbox" checked={props.checked} onChange={onChangeEvent} disabled={props.disabled} readOnly={props.disabled === true} />
            <span className="slider round" />
        </label>
    );
}

export default Switch;