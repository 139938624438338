import {IParameterSettings} from "../../../constants/Constants";
import {DeviceTankCommon} from "../../../constants/DeviceTankCommon";
import CSS from "csstype";
import * as React from "react";
import {GetValueFromControl, ValidateNumber} from "../../../constants/Common";
import {Col, Container, FormControl, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {useEffect} from "react";

const TankModal = (
    props: {
        parameter: IParameterSettings,
        handleSensorChange: (parameter: IParameterSettings) => void,
        handleValidationCheck: (validate: boolean) => void
    }
) => {
    const [minValueInValidation, checkMinValueInValidation] = React.useState(false);
    useEffect(() => {
        props.handleValidationCheck(!minValueInValidation);
    }, [minValueInValidation])

    function getTankLevel(value: number) {
        return Math.abs(value ?? props.parameter.minValue);
    }

    const maxSensorLimit = Math.abs(props.parameter.maxValue - props.parameter.minValue);
    const tankLevel = getTankLevel(props.parameter.customMinValue);

    DeviceTankCommon.TankHeight = 160;
    const m = DeviceTankCommon.TankHeight / maxSensorLimit;
    const level = m * (tankLevel > maxSensorLimit ? maxSensorLimit : tankLevel);

    const tankLimit: CSS.Properties = {
        ...DeviceTankCommon.ThresholdStyle,
        top: `${DeviceTankCommon.TankHeight - level}px`,
        textAlign: "center",
        color: "black",
    };
    const maxSizeStyle: CSS.Properties = {
        position: "absolute",
        top: "-2px",
        left: "5px",
        fontSize: "14px",
        color: "gray"
    };

    const handleMinValueChange = (event: React.FormEvent<any>) => {
        const value = GetValueFromControl(event);
        const isValid = value === null || ValidateNumber(value);

        if (!(value === null || ValidateNumber(value))) {
            checkMinValueInValidation(!isValid);
            return;
        }
        const customMinValue = value !== null ? Number(value) : null;
        if (customMinValue !== null && (getTankLevel(customMinValue) > maxSensorLimit || customMinValue < 0)) {
            checkMinValueInValidation(true);
            return;
        }
        checkMinValueInValidation(false);
        const converter = -1;
        const shifter = customMinValue ?? maxSensorLimit;
        props.handleSensorChange({
            ...props.parameter,
            thresholdMax: null,
            thresholdMin: null,
            maxValue: customMinValue == null ? 0 : props.parameter.maxValue,
            minValue: customMinValue == null ? shifter : props.parameter.minValue,
            customMinValue: customMinValue,
            customMaxValue: customMinValue != null ? 0 : null,
            converter: converter,
            shifter: shifter
        });
    }

    return (
        <Container>
            <Row>
                <Col xs={6}>
                    <div style={{height: "100%", paddingTop: "10%"}}>
                        <FormattedMessage id="device.edit.converter.tank-depth"/>
                        <FormControl type="text"
                                     placeholder={`${maxSensorLimit}`}
                                     key={`tank_${props.parameter.parameterId}`}
                                     name={`tank_${props.parameter.parameterId}`}
                                     defaultValue={props.parameter.customMinValue}
                                     onChange={handleMinValueChange}
                                     isInvalid={minValueInValidation}/>
                    </div>
                </Col>
                <Col xs={6}>
                    <div style={DeviceTankCommon.TankStyle}>
                        <div style={tankLimit}> {tankLevel}{props.parameter.unit}  </div>
                        <div style={maxSizeStyle}> {maxSensorLimit}{props.parameter.unit} </div>
                    </div>
                </Col>
            </Row>
        </Container>);
}

export default TankModal;