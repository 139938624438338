import { fetch } from 'whatwg-fetch';
import { jsonHeader } from "../constants/Constants";
import { INotificationSettings} from "../constants/NotificationConstants";
import { INotificationPhoneConfirmation, INotificationPhoneNumber } from "../constants/SmsNotificationConstants";
import {IRegisteredPhoneNumberForm} from "../constants/SmsNotificationConstants";
import {INotificationEmailAddress, IRegisteredEmailForm} from "../constants/EmailNotificationConstants";

export class NotificationAPI {

    public saveNotificationSettings(settings: INotificationSettings): Promise<Response> {
        return fetch(`/api/Notification/Settings`,{
            method: "PUT",
            body: JSON.stringify(settings),
            headers: jsonHeader,
        });
    }

    public savePhoneNumber(model: INotificationPhoneNumber): Promise<Response> {
        return fetch(`/api/Notification/PhoneNumber`,{
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader,
        });
    }

    public saveEmailAddress(model: INotificationEmailAddress): Promise<Response> {
        return fetch(`/api/Notification/EmailAddress`,{
            method: "POST",
            body: JSON.stringify(model),
            headers: jsonHeader,
        });
    }

    public savePhoneNumberCode(model: INotificationPhoneConfirmation): Promise<Response> {
        return fetch(`/api/Notification/ConfirmPhoneNumber`,{
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader
        });
    }

    public unRegisterMobileDevices(): Promise<Response> {
        return fetch(`/api/Notification/UnRegisterAll`,{
            method: "POST",
            headers: jsonHeader
        });
    }

    public getNotificationSettings(): Promise<Response> {
        return fetch(`/api/Notification/Settings`);
    }

    public getRegisteredPhoneNumbers(): Promise<Response> {
        return fetch(`/api/Notification/RegisteredPhoneNumbers`);
    }
    
    public getRegisteredEmails(): Promise<Response> {
        return fetch(`/api/Notification/RegisteredEmails`);
    }
    
    public savePhoneNumbersActivity(model: IRegisteredPhoneNumberForm[]) : Promise<Response> {
        return fetch(`/api/Notification/RegisteredPhoneNumbers`,{
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader,
        });
    }
    public saveEmailActivity(model: IRegisteredEmailForm[]) : Promise<Response> {
        return fetch(`/api/Notification/RegisteredEmails`,{
            method: "PUT",
            body: JSON.stringify(model),
            headers: jsonHeader,
        });
    }
}

export default new NotificationAPI;