import * as React from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IResult } from "../../constants/Constants";
import "../../less/Login.css";
import InfoMenu from '../InfoMenu';
import Spinner from "../Spinner";
import UserContext, { IUserContext } from "../UserContext";
import { useSearchParams, Link } from "react-router-dom";
import FooterLinks from "./FooterLinks";

const ConfirmEmail = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let [code, setCode] = React.useState(
        searchParams.get("code")
    );
    let [userId, setUserId] = React.useState(
        searchParams.get("userId")
    );
    const userContext = React.useContext<IUserContext>(UserContext);
    const [model, setModel] = React.useState({ isSuccessed: false, message: null } as IResult);
    React.useEffect(() => {
        setModel({ ...model, message: "loading" });
        UserAccountAPI.confirmEmail({ code, userId })
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.json())
            .then((confirmEmailResult: boolean) => {
                setModel({ ...model, isSuccessed: confirmEmailResult, message: confirmEmailResult ? "email.confirmed.text" : "email.confirmed.error" });
            })
            .catch((r: Response) => r.json())
            .then((r: string) => { if (r) {
                setModel({ ...model, isSuccessed: false, message: "email.confirmed.error" }); 
            } });

    }, []);

    return (
        <div className={"confirm-email " + userContext.style}>
            <InfoMenu />
            <div className="wrapper fadeInDown">
                <div className={"logo-box " + userContext.applicationName} />
                <div className="formContent">
                    {(model.message === "loading"
                        ? <Spinner />
                        : <Form>
                            <Form.Control.Feedback type={model.isSuccessed ? "valid" : "invalid"} className="login-message" hidden={model.message == null}>
                                {(model.message !== null ? <FormattedMessage id={model.message} /> : null)}
                            </Form.Control.Feedback>
                            <Link to="/login">
                                <div className="btn btn-success">
                                    <FormattedMessage id="email.confirmed.login" />
                                </div>
                            </Link>
                        </Form>
                    )}
                </div>
                <FooterLinks />
            </div>
        </div>
    );
};

export default ConfirmEmail;

