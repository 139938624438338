import { enGB, pl } from 'date-fns/locale';
import * as React from "react";
import { useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { IntlProvider } from "react-intl";
import * as enMessages from '../locales/translations.en.json';
import * as plMessages from '../locales/translations.pl.json';
import Site from "./Site";
import { IUserContext, UserContextProvider } from "./UserContext";
import * as moment from 'moment';
import { IAppStore } from 'store/configureStore';
import { bindActionCreators } from 'redux';
import { UserActionCreators } from 'actions/UserActionCreators';
import { connect } from 'react-redux';
import { IUserContextStore } from 'store/UserInfoStore';
import Spinner from './Spinner';

registerLocale('en', enGB);
registerLocale('pl', pl);

const UserContextSite = (props: IUserContextStore) => {
    useEffect(() => {
        props.fetchUserInfo();
    }, []);

    if (props.message === "Loading" || props.message === "Initial")
        return (<Spinner />);

    if (window.location.pathname === "/"
        && (window.location.hash === "" || window.location.hash === "#/")
        && !props.isAuthenticated && props.redirectUrl) {
            window.location.href = props.redirectUrl;
        return (<Spinner />)
    }

    const messages = (props.language === "en" ? enMessages : plMessages);
    setDefaultLocale(props.language);
    moment.locale(props.language);

    return (<IntlProvider locale={"en"} key={props.language} defaultLocale={props.language} messages={messages}>
        <UserContextProvider value={{...props} as IUserContext}>
            <Site />
        </UserContextProvider>
    </IntlProvider>)
};

export default connect(
    (state: IAppStore) => state.userInfo,
    (dispatch: any) => bindActionCreators(UserActionCreators, dispatch)
)(UserContextSite);
