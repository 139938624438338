import {IParameterValue} from "./Constants";

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

export class PlannerDeviceLimits {
    public static readonly FloorNumberLimit: number = 5;
    public static readonly BackgroundImageSizeLimit: number = 350;
} 

export enum PlannerDeviceStatus {
    Undefined,
    Normal,
    High ,
    Low
}

export interface IPlannerDeviceCoordinates {
    deviceId: number,
    x: number;
    y: number;
}

export interface IPlannerDevice extends IPlannerDeviceCoordinates {
    name: string;
    status: PlannerDeviceStatus;
    values: IParameterValue[];
}

export enum TooltipMode {
    Hidden,
    Up,
    Down,
    Left,
    Right,
}

export interface IPlannerTab {
    planId: string,
    name: string
}

export interface IBackgroundImage {
    content: string,
    width: number,
    height: number,
}

export interface ISpacePlannerViewModel {
    planId: string,
    name: string,
    backgroundImage: IBackgroundImage;
    devices: IPlannerDevice[];
}

export interface ISpacePlannerData {
    planId: string,
    name: string,
    backgroundImage: IBackgroundImage;
    devices: IPlannerDeviceCoordinates[];
}