import * as React from "react";
import {FormEvent, useState} from "react";
import { Alert, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IChangePasswordViewModel } from "../../constants/UserManage";
import "../../less/Manage.css";
import { UserContextConsumer } from "../UserContext";
import SubmitButton from "../SubmitButton";
import SubmitForm from "../SubmitForm";


const ChangePasswordForm = () => {
    const intl = useIntl();
    const initModel = { oldPassword: "", newPassword: "", confirmPassword: "" } as IChangePasswordViewModel;
    const [model, setModel] = useState(initModel);
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordChanged, setPasswordChanged] = useState(false);

    const submitForm = () => {
        setPasswordChanged(false);
        UserAccountAPI.changePassword(model)
            .then((response: Response) => {
                if (!response.ok)
                    return Promise.reject(response);
                return response;
            })
            .then((response: Response) => response.json())
            .then((changePasswordResult: boolean) => {
                if (changePasswordResult) {
                    setModel({ ...initModel });
                    setErrorMessage("");
                    setPasswordChanged(true);
                } else {
                    const message = intl.formatMessage({
                        id: "page.manage.changePasswordError"
                    });
                    setErrorMessage(message);
                }
            })
            .catch((r: Response) => r.json())
            .then((r: string) => { if (r) { setErrorMessage(r); } });
    };
    
    return (
        <UserContextConsumer>
            {userContext =>
                userContext && (
                    <SubmitForm>
                        <h5><FormattedMessage id="page.manage.changePassword" /></h5>
                        {userContext.isExternalAccount ?
                            <Form.Label><FormattedMessage id="page.mange.externalAccount.changePassword" /></Form.Label>
                            : (
                                <>
                                    {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
                                    {passwordChanged ? <Alert variant="success"><FormattedMessage id="page.manage.changePasswordSuccessed" /></Alert> : null}
                                    <Form.Group controlId="formOldPassword">
                                        <Form.Label><FormattedMessage id="page.manage.oldPassword" /></Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={model.oldPassword}
                                            onChange={(event: React.FormEvent<any>) => setModel({ ...model, oldPassword: (event.target as any).value })} />
                                    </Form.Group>
                                    <Form.Group controlId="formNewPassword">
                                        <Form.Label><FormattedMessage id="page.manage.newPassword" /></Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={model.newPassword}
                                            onChange={(event: React.FormEvent<any>) => setModel({ ...model, newPassword: (event.target as any).value })} />
                                    </Form.Group>
                                    <Form.Group controlId="formConfirmPassword">
                                        <Form.Label><FormattedMessage id="page.manage.confirmNewPassword" /></Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={model.confirmPassword}
                                            onChange={(event: React.FormEvent<any>) => setModel({ ...model, confirmPassword: (event.target as any).value })} />
                                    </Form.Group>
                                    <SubmitButton  disabled={!(model.oldPassword && model.newPassword && model.confirmPassword)} onClick={submitForm}>
                                        <FormattedMessage id="page.manage.changePassword" />
                                    </SubmitButton>
                                </>
                            )}
                    </SubmitForm>
                )}
        </UserContextConsumer>
    );
}

export default ChangePasswordForm;