import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Navigate} from "react-router-dom";
import { UserActionCreators } from "../../actions/UserActionCreators";
import UserAccountAPI from "../../api/UserAccountAPI";
import { IResult } from "../../constants/Constants";
import "../../less/Login.css";
import { IAppStore } from "../../store/configureStore";
import { IUserContextStore } from "../../store/UserInfoStore";
import Spinner from "../Spinner";

interface IUserContextSiteProps extends IUserContextStore {
    fetchUserInfo: () => void;
}

const DemoLogin = (props: IUserContextSiteProps) => {
    React.useEffect(() => {
        if(!props.isAuthenticated)
            loginAction();
    }, []);

    const loginAction = () => {
        UserAccountAPI.loginDemo()
            .then((response: Response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                }
                return response;
            })
            .then((response: Response) => response.json())
            .then((loginResult: IResult) => {
                if (loginResult.isSuccessed)
                    props.fetchUserInfo();
            });
    };

    if (props.isAuthenticated && props.isEmailConfirmed)
        return (<Navigate to="/main" />);

    return (
        <Spinner />
    );
};

export default connect(
    (state: IAppStore) => state.userInfo,
    (dispatch: any) => bindActionCreators(UserActionCreators, dispatch)
)(DemoLogin);

