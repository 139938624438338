import * as React from "react";
import { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import ScrollArea from "react-scrollbar";
import { bindActionCreators } from 'redux';
import { SiteActionCreators } from "actions/SiteActionCreators";
import { IDevice } from "constants/DeviceConstants";
import '../less/App.css';
import { IAppStore } from "store/configureStore";

interface ISiteLeftPanelProps {
    fetchDevices?: () => void,
    records?: IDevice[]
}

const SiteLeftPanel = (props: ISiteLeftPanelProps) => {
    useEffect(() => {
        props.fetchDevices();
    }, []);

    return (
        <React.Fragment>
            <ScrollArea
                speed={0.8}
                className="scrollarea-area"
                contentClassName="scrollarea-content"
                horizontal={false}
            >
                <Nav>
                    <LinkContainer to="/main" key="dashboard">
                        <Nav.Item>
                            <i className="material-icons">dashboard</i> <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
                        </Nav.Item>
                    </LinkContainer>
                    {(props.records || []).map((d: IDevice) =>
                        <LinkContainer to={`/device/${d.deviceId}`} key={d.deviceId}>
                            <Nav.Item>
                                <i className="material-icons">developer_board</i> {d.name}
                            </Nav.Item>
                        </LinkContainer>
                    )}
                </Nav>
            </ScrollArea>
            <LinkContainer to="/register-device" key="register-device">
                <div className="add-device-button btn btn-success" title="Add Device" >
                    <i className="material-icons">add_circle</i> <FormattedMessage id="register.device" defaultMessage="Register Device" />
                </div>
            </LinkContainer>
        </React.Fragment>
    );
}

export default connect(
    (state: IAppStore) => state.devicesList,
    (dispatch: any) => bindActionCreators(SiteActionCreators, dispatch)
)(SiteLeftPanel);