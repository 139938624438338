import * as React from "react";
import { Badge } from 'react-bootstrap';
import { FormattedMessage, useIntl } from "react-intl";
import { GetDateTime } from 'constants/Common';
import { IDeviceTile, MeasurableParameter } from "constants/Constants";
import { getParameterIcon } from "constants/Common";
import { SubscriptionType } from "constants/SubscriptionConstants";
import "../../less/DashboardTile.css";
import OnlineOffline from "../OnlineOffline";
import QualityIndex from '../Smog/QualityIndex';

interface IDashboardTileProps {
    data: IDeviceTile;
}

const DashboardSmogTile = (props: IDashboardTileProps) => {
    const tile = props.data
    const intl = useIntl();

    const smogLabel = (tile.deviceParameters || [])
        .filter(dp => dp === MeasurableParameter.Pm25 || dp === MeasurableParameter.Pm10)
        .map(dp => intl.formatMessage({ id: `device.chart.parameter_${dp}` })).join(", ");

    return (<div className="tile smog normal">
        <div className="sensor-list">
            {(tile.deviceParameters || [])
                .filter(dp => dp !== MeasurableParameter.Pm25 && dp !== MeasurableParameter.Pm10)
                .map(dp => <span
                    className={getParameterIcon(dp)}
                    title={intl.formatMessage({ id: `device.chart.parameter_${dp}` })}
                    key={`sensor_${dp}`}
                />)}
            <span
                className={getParameterIcon(MeasurableParameter.Pm10)}
                title={smogLabel}
                key="sensor_smog"
            />
        </div>
        <div className="online">
            <span className="lastUpdate"> {GetDateTime(tile.lastUpdate)} </span>
            <OnlineOffline isOnline={tile.isOnline} />
        </div>
        <div className="name"> {tile.name} </div>
        <div className="content">
            <QualityIndex value={tile.qualityIndex}/>
            <div className="values">
                {
                    (tile.values || [])
                        .filter(v => v.parameterId === MeasurableParameter.Pm25 || v.parameterId === MeasurableParameter.Pm10)
                        .map(v =>
                            <div key={v.parameterId} className="row">
                                <Badge className="value-row" bg="light">
                                    <span className="name"><FormattedMessage id={`device.chart.parameter_${v.parameterId}`} /></span>
                                    <div className="value">{`${v.value.toFixed(0)} ${intl.formatMessage({ id: `device.chart.parameter.unit_${v.parameterId}` })}`}</div>
                                </Badge>
                            </div>
                        )
                }
            </div>
        </div>
    </div>)
}

export default DashboardSmogTile;