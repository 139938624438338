import DeviceDataAPI from "../api/DeviceDataAPI";
import { IRegisterDeviceForm, IRegisterDeviceResponse, RegisterDeviceAction } from "constants/Constants";
import promiseDispatch from "./promiseDispatch";
import { SiteActionCreators } from "actions/SiteActionCreators";

export const RegisterDeviceActionCreators = {
    resetFields: () => ({ type: RegisterDeviceAction.CLEAR }),

    editField: (fieldName: string, value: string) => {
        switch (fieldName) {
            case "name":
                return { type: RegisterDeviceAction.EDIT_NAME, payload: value };
            case "key":
                return { type: RegisterDeviceAction.EDIT_KEY, payload: value };
            default:
                return null;
        }
    },

    validationErrorField: (fieldName: string, value: string) => {
        switch (fieldName) {
            case "name":
                return { type: RegisterDeviceAction.INVALID_NAME, payload: value };
            case "key":
                return { type: RegisterDeviceAction.INVALID_KEY, payload: value };
            default:
                return null;
        }
    },
    submit: (model: IRegisterDeviceForm, successAction: ()=>void) => async (dispatch: any, getState: any) => {
        dispatch({ type: RegisterDeviceAction.SUBMIT, payload: model });
        promiseDispatch<IRegisterDeviceResponse>(
            dispatch,
            () => DeviceDataAPI.saveRegisterDevice(model),
            {
                request: null,
                success: RegisterDeviceAction.SUBMIT_SUCCESS,
                failure: RegisterDeviceAction.SUBMIT_FAILED
            }, () => {
                SiteActionCreators.fetchDevices()(dispatch, getState);
                if (successAction)
                    successAction();
            });
    },
}