import * as React from "react";

interface IRadioProps {
    checked?: boolean;
    onChange?: (event: React.FormEvent<any>) => void;
    disabled?: boolean;
    name: string;
    value: string | number;
    children?: React.ReactNode | React.ReactNode[]
}

const Radio = (props: IRadioProps) => {
    return (
        <div className="custom-control custom-radio">
            <input type="radio"
                className="custom-control-input"
                onChange={props.onChange}
                id={`custom-radio_${props.name}_${props.value}`}
                name={props.name}
                checked={props.checked}
                value={props.value}
                disabled={props.disabled} />
            <label
                className="custom-control-label"
                htmlFor={`custom-radio_${props.name}_${props.value}`}>
                {props.children}
            </label>
        </div>
    );
}

export default Radio;