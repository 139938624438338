import UserAccountAPI from "../api/UserAccountAPI";
import { IUserContext, UserInfoActions } from "../components/UserContext";
import promiseDispatch from "./promiseDispatch";

export const UserActionCreators = {
    fetchUserInfo: () => async (dispatch: any, getState: any) => {
        promiseDispatch<IUserContext>(dispatch,
            UserAccountAPI.fetchUserInfo,
            {
                request: UserInfoActions.FETCH_USER,
                success: UserInfoActions.FETCH_USER_SUCCESS,
                failure: UserInfoActions.FETCH_USER_FAILED
            });
    },

    setLeftPanelState: (isExpand: boolean) => async (dispatch: any, getState: any) => {
        dispatch({ type: UserInfoActions.CHANGE_LEFT_PANEL_STATE, payload: isExpand });
        promiseDispatch<IUserContext>(dispatch,
            () => UserAccountAPI.setLeftPanelState(isExpand),
            {
                request: UserInfoActions.SET_LEFT_PANEL_STATE_USER,
                success: UserInfoActions.SET_LEFT_PANEL_STATE_USER_SUCCESS,
                failure: UserInfoActions.SET_LEFT_PANEL_STATE_USER_FAILED
            });
    }
};