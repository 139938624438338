import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import DeviceGaugeChart from "./DeviceGaugeChart";
import {MeasurableParameter} from "constants/Constants";
import {IGaugeParameter} from "constants/DeviceConstants";
import DeviceTankGauge from "./DeviceTankGauge";

const DeviceParameterGauges = (props: { parameters: IGaugeParameter[] }) => {
    const maxCol = 100;
    const rows = Math.floor( props.parameters.length / maxCol) + 1;
    
    return (<div>
        {([...Array(rows).keys()]).map((i: number) =>
            <Row key={i}>
                {(props.parameters
                    .filter(p => p.showInDashboardTile)
                    .slice(i*maxCol, (i+1)*maxCol) || [])
                    .map((p: IGaugeParameter) =>
                    <Col key={`key_${p.parameterId}`}>
                        <LinkContainer to={`/device/${p.deviceId}/${MeasurableParameter[p.parameterId]}`}>
                              <div>
                                <DeviceGaugeMap {... p} />
                              </div>
                        </LinkContainer>
                    </Col>)}
            </Row>
        )}
    </div>);
}

const DeviceGaugeMap = (props: IGaugeParameter) => {
    return props.parameterId === MeasurableParameter.Distance
        ? <DeviceTankGauge
            value={props.latestValue}
            parameterId={props.parameterId}
            parameterName={props.customName}
            min={props.customMinValue ?? props.minValue}
            max={props.customMaxValue ?? props.maxValue}
            thresholdMax={props.thresholdMax}
            thresholdMin={props.thresholdMin}
            unit={props.unit}
            color={getColor(props)}
            size="Expand" />
        : <DeviceGaugeChart
            value={props.latestValue}
            parameterId={props.parameterId}
            parameterName={props.customName}
            min={props.customMinValue ?? props.minValue}
            max={props.customMaxValue ?? props.maxValue}
            unit={props.unit}
            color={getColor(props)}
            size="Expand" />
}

function getRange(param: IGaugeParameter) : number[] {
    const rangeSize = 4;
    const step = Math.abs(param.maxValue - param.minValue)/4;
    const result: number[] = [];

    for(let s= param.minValue + step ; s< param.maxValue; s+=step)
        result.push(s);
    return result;
}
function getColor(param: IGaugeParameter): string {

    if(param.thresholdMax !== null && param.thresholdMax !== param.maxValue && param.thresholdMin !== null && param.thresholdMin !== param.minValue)
        return param.latestValue > param.thresholdMax ? '#ff5454' : param.latestValue < param.thresholdMin ? '#67c2ef' : '#60B044';

    const color = ['#60B044','#F6C600','#F97600','#FF0000'];
    const values = getRange(param);
    for (let i=0; i < values.length; i++)
        if (param.latestValue < values[i])
            return color[i];
    return '#FF0000';
}

export default DeviceParameterGauges;