import * as React from "react";
import {createContext, createRef, useEffect, useState} from "react";
import {DeviceCircle} from "./DeviceCircle";
import "../../less/SpacePlanner.css";
import {IBackgroundImage, IPlannerDevice} from "../../constants/PlannerDeviceConstants";

interface IPlannerCanvasProps {
    devices: IPlannerDevice[];
    backgroundImage: IBackgroundImage;
    removeDevice: (device: IPlannerDevice) => void;
    moveDevice: (deviceId: number, x: number, y: number) => void;
}

export const SvgContext = createContext<[
    React.RefObject<any>,
    number,
    number
]>([null, null, null]);

interface IPlannerCanvasState {
    activeDeviceId?: number
}

const PlannerHeight = 800;
const PlannerWeight = 1024;
export const PlannerCanvas = (props: IPlannerCanvasProps) => {
    const [state, setState] = useState<IPlannerCanvasState>({activeDeviceId: null});
    const svgRef = createRef<any>();
    const setActiveDevice = (deviceId: number) => {
        setState({...state, activeDeviceId: deviceId});
    }
    return (
        <div className="planner-canvas-scroll">
            <svg ref={svgRef}
                 style={props.backgroundImage != null
                     ? {backgroundImage: `url(${props.backgroundImage.content})`}
                     : null}
                 className="planner-canvas"
                 width={props.backgroundImage?.width ?? PlannerWeight}
                 height={props.backgroundImage?.height ?? PlannerHeight}>

                <SvgContext.Provider value={[svgRef,
                    props.backgroundImage?.width ?? PlannerWeight,
                    props.backgroundImage?.height ?? PlannerHeight]}>
                    {props.devices?.sort((d1, d2) => d1.deviceId == state.activeDeviceId ? 1 : -1)
                        .map(d =>
                        <DeviceCircle device={d}
                                      activeDeviceAction={setActiveDevice}
                                      tooltipEnabled={state.activeDeviceId == null || d.deviceId == state.activeDeviceId}
                                      removeDeviceAction={props.removeDevice}
                                      moveDeviceAction={props.moveDevice}
                                      key={`circle_${d.deviceId}`}/>)}
                </SvgContext.Provider>
                <use xlinkHref="#tooltip"/>
            </svg>
        </div>
    );
}