import * as React from "react";
import { Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../less/Portal.css";
import InfoMenu from "./InfoMenu";
import { UserContextConsumer } from "./UserContext";

const Portal = () => {
    return (
        <UserContextConsumer>
            {userContext =>
                userContext && (
                    <div className={"portal " + userContext.style}>
                        <InfoMenu />
                        <div className="container-box">
                            <div className={"enter-box " + userContext.applicationName}>
                                <div className="actions">
                                    <Nav.Link href="/registration">
                                        <Nav.Item className="btn btn-success">
                                            <FormattedMessage
                                                id="landingPage.Register"
                                                defaultMessage="Register"
                                            />
                                        </Nav.Item>
                                    </Nav.Link>
                                    <Nav.Link href="/login">
                                        <Nav.Item className="btn btn-primary">
                                            <FormattedMessage
                                                id="landingPage.Login"
                                                defaultMessage="Login"
                                            />
                                        </Nav.Item>
                                    </Nav.Link>
                                    {(userContext.demoUserEnabled ?
                                    <Nav.Link  href="/login-demo" >
                                        <Nav.Item className="btn btn-secondary">
                                            <FormattedMessage
                                                id="landingPage.Demo"
                                                defaultMessage="Demo"
                                            />
                                        </Nav.Item>
                                    </Nav.Link>
                                    : null)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </UserContextConsumer>
    );
};

export default Portal;
